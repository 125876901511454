import { API_PATH, ROOT_PUBLIC_URL, ROOT_URL } from '@constants/api';
import {
  checkDiscountTicket,
  getPublicTicketGroupsByEvent,
  assignTicketTo as localAssignTicketTo,
  getPublicEvents as localGetPublicEvents,
  getPublicPaymentMethods as localGetPublicPaymentMethods,
  getPublicTicketDetails as localGetPublicTicketDetails,
  getPublicTickets as localGetPublicTickets,
  postPublicBookings,
  postPublicBookingsCheck,
} from '@hubs101/booking-api-skd-client';
import {
  type AssigneeInput,
  type PublicBookingsCheckInput,
  type PublicEvent,
  type PublicTicket,
  type ResponsePaginationType,
  type TicketItem,
} from '@hubs101/booking-api-skd-client/lib/types';
import { useMutation, useQueries, useQuery } from '@tanstack/react-query';

interface OptionInput {
  option?: string;
}

export interface AssignedType {
  first_name: string;
  last_name: string;
  company_name: string;
  email: string;
}

export interface TicketOptionsInput {
  ticket: string;
  options?: OptionInput[];
  index?: number;
  isAssignedToMe: boolean;
  assigned?: AssignedType;
}

export const getPublicTickets = async ({
  page = 0,
  ticketCode,
  eventSlug,
}: PublicTicket) => {
  const response = await fetch(
    `${API_PATH.PUBLIC_TICKETS}?limit=2000&page=${page}${
      ticketCode ? '&code=' + ticketCode : ''
    }${eventSlug ? '&slug=' + eventSlug : ''}`
  );
  const data = await response.json();
  const result: ResponsePaginationType<unknown> = data.result;
  return {
    tickets: result,
  };
};

export const usePublicTickets = ({
  page,
  ticketCode,
  eventSlug,
  language,
}: PublicTicket & { language: string }) =>
  useQuery({
    queryKey: ['tickets', page, ticketCode, eventSlug, language, ROOT_URL],
    queryFn: async () =>
      await localGetPublicTickets({
        baseUrl: ROOT_PUBLIC_URL ?? '',
        page,
        ticketCode: ticketCode ?? '',
        eventSlug,
        language,
      }),
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });

export const getTicketByCode = async ({ code }: PublicTicket) => {
  if (!code) return;
  const response = await fetch(`${API_PATH.PUBLIC_TICKETS}?code=${code}`);
  const data = await response.json();
  const result: ResponsePaginationType<unknown> = data.result;
  return {
    ticket: result.data?.[0],
  };
};
export const useCodeTicket = ({ code }: PublicTicket) =>
  useQuery({
    queryKey: ['ticket', code],
    queryFn: async () => await getTicketByCode({ code }),
    staleTime: 180000,
  });

export const getTicketById = async ({ id }: PublicTicket) => {
  if (!id) return;
  const response = await fetch(`${API_PATH.PUBLIC_TICKETS}?id=${id}`);
  const data = await response.json();
  const result: ResponsePaginationType<unknown> = data.result;
  return {
    ticket: result.data?.[0],
  };
};

export const useIdTicket = ({ id }: PublicTicket) =>
  useQuery({
    queryKey: ['ticket', id],
    queryFn: async () => await getTicketByCode({ id }),
    staleTime: 180000,
  });

export const getParamsTicket = async (params: string) => {
  if (!params) return;

  const response = await fetch(`${API_PATH.PUBLIC_TICKETS}?${params}`);
  const data = await response.json();
  const result: ResponsePaginationType<unknown> = data.result;
  return {
    ticket: result.data?.[0],
  };
};

export const useParamsTicket = (params: Record<string, string>) => {
  const searchParams = new URLSearchParams(params).toString();
  return useQuery({
    queryKey: ['ticket', searchParams],
    queryFn: async () => await getParamsTicket(searchParams),
    staleTime: 180000,
  });
};

export const useCheckDiscountTicket = (
  ticketsIds: string[],
  discountCode: string,
  language: string,
  eventId: string,
  enabled: boolean
) =>
  useQuery({
    queryKey: [
      'discount',
      ticketsIds,
      discountCode,
      language,
      eventId,
      ROOT_PUBLIC_URL,
    ],
    queryFn: async () =>
      await checkDiscountTicket({
        baseUrl: ROOT_PUBLIC_URL ?? '',
        ticketsIds,
        discountCode,
        language,
        eventId,
      }),
    enabled,
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });

export const useMapCheckDiscountTicket = (
  ticketDiscountPairs: TicketItem[],
  eventId: string,
  language: string
) =>
  useQueries({
    queries: ticketDiscountPairs.map(ticket => {
      return {
        queryKey: [
          'discount',
          ticket.id,
          ticket?.discountCode,
          language,
          ROOT_PUBLIC_URL,
          eventId,
        ],
        queryFn: async () =>
          await checkDiscountTicket({
            baseUrl: ROOT_PUBLIC_URL ?? '',
            ticketsIds: [ticket.id!],
            discountCode: ticket?.discountCode ?? '',
            language,
            eventId,
          }),
        staleTime: 180000,
      };
    }),
  });

export const usePublicEvents = ({
  page,
  slug,
  language = 'en',
}: {
  page?: number;
  slug?: string;
  language?: string;
}) =>
  useQuery({
    queryKey: ['events', page, slug, ROOT_PUBLIC_URL, language],
    queryFn: async () =>
      await localGetPublicEvents({
        baseUrl: ROOT_PUBLIC_URL ?? '',
        page,
        slug: slug ?? '',
        language,
      }),
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });

export const getPublicPaymentMethods = async ({
  id,
}: Pick<PublicEvent, 'id'>) => {
  let response;
  if (id) {
    response = await fetch(`${API_PATH.EVENTS}/${id}/payment-methods`);
  }
  const data = await response?.json();
  const result: ResponsePaginationType<unknown> = data?.result;
  return {
    paymentMethods: result,
  };
};

export const usePublicPaymentMethods = ({
  id,
  language,
}: Pick<PublicEvent, 'id' | 'language'>) =>
  useQuery({
    queryKey: ['paymentMethods', id, language, ROOT_PUBLIC_URL],
    queryFn: async () =>
      await localGetPublicPaymentMethods({
        baseUrl: ROOT_PUBLIC_URL ?? '',
        id: id ?? '',
        language,
      }),
    staleTime: 180000,
    enabled: Boolean(id),
    retry: false,
    networkMode: 'offlineFirst',
  });

export const usePublicBookingsCheck = (
  bookingCheck: PublicBookingsCheckInput,
  language: string,
  onError: (error: Error) => void
) =>
  useMutation({
    mutationFn: async () =>
      await postPublicBookingsCheck({
        baseUrl: ROOT_URL ?? '',
        bookingCheck,
        language,
      }),
    onError,
  });

const postPublicBookingsMutation = async ({
  bookingCheck,
  language,
}: {
  bookingCheck: PublicBookingsCheckInput;
  language: string;
}) => {
  return await postPublicBookings({
    baseUrl: ROOT_URL ?? '',
    bookingCheck,
    language,
  });
};

export const usePublicBookings = (
  bookingCheck: PublicBookingsCheckInput,
  language: string,
  onError: (error: Error) => void
) =>
  useMutation({
    mutationFn: async ({
      bookingCheck,
      language,
    }: {
      bookingCheck: PublicBookingsCheckInput;
      language: string;
    }) => await postPublicBookingsMutation({ bookingCheck, language }),
    onError,
    networkMode: 'offlineFirst',
  });

export const usePublicTicketDetails = ({
  id,
  access,
}: {
  id: string;
  access: string;
}) =>
  useQuery({
    queryKey: ['publicTicketDetails', id, access],
    queryFn: async () => await getPublicTicketDetails({ id, access }),
    staleTime: 180000,
  });

export const getPublicTicketDetails = async ({
  id,
  access,
}: {
  id: string;
  access: string;
}) => {
  let result;
  const encodedAccess = access
    ? Buffer.from(access).toString('base64')
    : access;

  if (id && ROOT_URL) {
    result = await localGetPublicTicketDetails({
      baseUrl: ROOT_URL,
      id,
      access: encodedAccess,
    });
  }
  return result;
};

export const assignTicket = async ({
  id,
  access,
  reference,
  assignee,
}: {
  id: string;
  access: string;
  reference: string;
  assignee: AssigneeInput;
}) => {
  let result;
  const encodedAccess = access
    ? Buffer.from(access).toString('base64')
    : access;
  if (id && ROOT_URL) {
    result = await localAssignTicketTo({
      baseUrl: ROOT_URL,
      id,
      access: encodedAccess,
      reference,
      assignee,
    });
  }
  return result;
};

export const usePublicTicketGroups = ({
  eventId,
  language,
}: {
  eventId: string;
  language: string;
}) =>
  useQuery({
    queryKey: ['ticketGroups', eventId, language, ROOT_PUBLIC_URL],
    queryFn: async () =>
      await getPublicTicketGroupsByEvent({
        baseUrl: ROOT_PUBLIC_URL ?? '',
        eventId,
        language,
      }),
    staleTime: 180000,
    enabled: Boolean(eventId),
    retry: false,
  });
