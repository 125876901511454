import Button, { ButtonSizes, ButtonStyle } from '@components/Button';
import IconRounded, {
  IconRoundedSizes,
  IconRoundedStyles,
} from '@components/IconRounded';
import {
  type DiscountType,
  type EventType,
  type QueryResponseType,
} from '@hubs101/booking-api-skd-client/lib/types';
import {
  type TicketOptionsInput,
  useCheckDiscountTicket,
} from '@utils/apiHooks';
import {
  checkIfDiscountAppliesMinMax,
  checkIfDiscountAppliesToSelectedTickets,
} from '@utils/checkIfDiscountAppliesMinMax';
import { extractSearchParams, formatDiscountCode } from '@utils/fn';
import {
  type ChangeEvent,
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CodeReferenceInput,
  DiscountSectionRoot,
  InputWrapper,
  StretchItem,
} from './styles';

import Input from '@components/Input';
import { RowAlignedItems } from '@components/LinearLayout';
import { H6 } from '@components/Typography';
import { useHookParams } from '@utils/hooks';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

interface DiscountSectionProps {
  discount: DiscountType | undefined;
  eventDetails: QueryResponseType<EventType>;
  setDiscount: Dispatch<SetStateAction<DiscountType | undefined>>;
  setError: Dispatch<SetStateAction<string>>;
  selectedTicketsWithOptions: TicketOptionsInput[];
}
const DiscountSection = ({
  discount,
  eventDetails,
  setDiscount,
  setError,
  selectedTicketsWithOptions,
}: DiscountSectionProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const [language, ticketCode, discountCodeParam] = useHookParams([
    'la',
    'tc',
    'di',
  ]);
  const [showSuccessDiscount, setShowSuccessDiscount] = useState<boolean>(
    Boolean(discount)
  );

  const [discountCode, setDiscountCode] = useState(
    formatDiscountCode(discountCodeParam)
  );
  const selectedTicketsIds = useMemo(
    () => [...new Set(selectedTicketsWithOptions?.map(e => e.ticket))],
    [selectedTicketsWithOptions]
  );

  const shouldApplyDiscountForParamTicket = useMemo(
    () => !!discountCodeParam && !!ticketCode && selectedTicketsIds?.length > 0,
    [discountCodeParam, selectedTicketsIds?.length, ticketCode]
  );
  const [isDiscountApplied, setIsDiscountApplied] = useState<boolean>(
    shouldApplyDiscountForParamTicket
  );

  const isSelectedTicketsDiscountBetweenMinMax = checkIfDiscountAppliesMinMax(
    selectedTicketsWithOptions,
    discount
  );
  const isSelectedTicketsDiscounted = checkIfDiscountAppliesToSelectedTickets(
    selectedTicketsWithOptions,
    discount
  );

  const {
    data,
    isFetched,
    error: discountError,
  } = useCheckDiscountTicket(
    selectedTicketsIds,
    discountCode,
    language,
    eventDetails?.data?.id,
    isDiscountApplied && Boolean(eventDetails?.data?.id?.length)
  );

  const discountData = data?.discount?.result?.data;

  const handleApplyDiscount = useCallback(() => {
    if (discountCode?.length > 5) {
      setDiscountCode(formatDiscountCode(discountCode));
      setIsDiscountApplied(true);
    }
  }, [discountCode]);

  const removeDiscountCode = useCallback(() => {
    setIsDiscountApplied(false);
    setDiscountCode('');
    setShowSuccessDiscount(false);
    setDiscount(undefined);
  }, [setDiscount]);

  const handleDiscountCodeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = formatDiscountCode(event.target.value);
      setDiscountCode(newValue);
      setIsDiscountApplied(false);
      setShowSuccessDiscount(false);
      setDiscount(undefined);
    },
    [setDiscount]
  );

  useEffect(() => {
    if (discountError) {
      setError(discountError?.message);
    } else {
      setError('');
    }
  }, [discountError, setError]);

  useEffect(() => {
    if (discountCodeParam && ticketCode && selectedTicketsIds.length > 0) {
      handleApplyDiscount();
    }
  }, [discountCodeParam, ticketCode, selectedTicketsIds, handleApplyDiscount]);

  useEffect(() => {
    if (selectedTicketsIds.length === 0) {
      setIsDiscountApplied(false);
      setShowSuccessDiscount(false);
      setDiscount(undefined);
    }
  }, [
    setDiscount,
    selectedTicketsIds,
    setIsDiscountApplied,
    setShowSuccessDiscount,
  ]);

  useEffect(() => {
    if (isFetched && data?.discount?.status === 200 && isDiscountApplied) {
      if (
        discountData?.expire_at != null &&
        new Date(discountData?.expire_at).getTime() < new Date().getTime()
      ) {
        setIsDiscountApplied(false);
        setError(t('discount-expired') ?? '');
      } else if (
        discountData?.valid_from != null &&
        new Date(discountData?.valid_from).getTime() > new Date().getTime()
      ) {
        setIsDiscountApplied(false);
        setError(t('discount-not-valid') ?? '');
      } else {
        setDiscount(discountData);
        setShowSuccessDiscount(true);
        if (!discountCodeParam && discountData) {
          setSearchParams({
            ...extractSearchParams(searchParams),
            di: discountCode,
          });
        }
      }
    } else if (!navigator.onLine) {
      setIsDiscountApplied(false);
    }
  }, [
    isFetched,
    data,
    isDiscountApplied,
    setIsDiscountApplied,
    discountData,
    discountCode,
    searchParams,
    setSearchParams,
    setDiscount,
    setError,
    t,
    discountCodeParam,
  ]);

  useEffect(() => {
    if (selectedTicketsWithOptions?.length > 0 && showSuccessDiscount) {
      if (!isSelectedTicketsDiscounted) {
        setError(t('discount-unavailable-selected-tickets') ?? '');
      } else if (
        isSelectedTicketsDiscounted &&
        !isSelectedTicketsDiscountBetweenMinMax
      ) {
        setError(
          t('discount-invalid-min-max', {
            min: discount?.min_ticket,
            max: discount?.max_ticket,
          }) ?? ''
        );
      } else {
        setError('');
      }
    } else {
      setError('');
    }
  }, [
    discount?.max_ticket,
    discount?.min_ticket,
    isSelectedTicketsDiscountBetweenMinMax,
    isSelectedTicketsDiscounted,
    selectedTicketsWithOptions?.length,
    setError,
    showSuccessDiscount,
    t,
  ]);

  return (
    <DiscountSectionRoot>
      <StretchItem data-testid="discount:discount-text">
        {showSuccessDiscount ? (
          isSelectedTicketsDiscounted &&
          isSelectedTicketsDiscountBetweenMinMax && (
            <H6>{t('discount-added')}</H6>
          )
        ) : (
          <H6>{t('you-have-a-discount-code')}</H6>
        )}
      </StretchItem>
      <StretchItem>
        <CodeReferenceInput data-testid="discount:reference">
          <InputWrapper data-testid="discount:code">
            <Input
              background={
                showSuccessDiscount ? theme.color.gradientGreen : undefined
              }
              backgroundColor={
                !showSuccessDiscount ? theme.color.lightLush : undefined
              }
              iconName={showSuccessDiscount ? 'check' : undefined}
              value={discountCode?.trim().toUpperCase()}
              placeholder={String(t('enter-discount-code'))}
              borderRadius="5rem"
              border={
                showSuccessDiscount
                  ? `0.1rem solid ${theme.color.success2}`
                  : `0.1rem solid ${theme.color.grey1}`
              }
              color={theme.color.greyMediumDark}
              textAlign="center"
              padding="0 1.5rem"
              onChange={handleDiscountCodeChange}
            />
          </InputWrapper>
          {showSuccessDiscount ? (
            <RowAlignedItems data-testid="discount:remove" gap="1rem">
              <IconRounded
                iconStyle={IconRoundedStyles.default}
                iconSize={IconRoundedSizes.small}
                iconName={'close'}
                overrideBackgroundColor={theme.color.error}
                onClick={removeDiscountCode}
              />
            </RowAlignedItems>
          ) : (
            <Button
              data-testid="discount:apply"
              buttonSize={ButtonSizes.medium}
              buttonStyle={ButtonStyle.outline}
              border={`0.1rem solid ${theme.color.grey1}`}
              boxShadow="0 0.2rem 0 rgba(0, 0, 0, 0.1)"
              color={theme.color.greyDark}
              disabled={
                discountCode?.length < 6 ||
                selectedTicketsWithOptions.length === 0
              }
              content={t('apply')}
              onClick={handleApplyDiscount}
            />
          )}
        </CodeReferenceInput>
      </StretchItem>
    </DiscountSectionRoot>
  );
};

export default DiscountSection;
