import styled, { keyframes } from 'styled-components';

const loadSpin = keyframes`
 to {
    transform: rotate(1turn);
  }
`;

const Root = styled.div<{ size?: string }>`
  width: ${props => props.size};
  height: ${props => props.size};
  border-radius: ${props => props.theme.borderRadius.radius50p};
  background: ${props =>
    `conic-gradient(#0000 5%, ${props.theme.color.greyMedium})`};
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(100% - 0.3rem),
    #000 0
  );
  animation: ${loadSpin} 1.2s infinite linear;
`;

interface SpinnerProps {
  size?: string;
}

const Spinner = ({ size = '4rem' }: SpinnerProps) => {
  return <Root size={size} />;
};

export default Spinner;
