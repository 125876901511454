import { RowAlignedItems } from '@components/LinearLayout';
import styled from 'styled-components';

export const ButtonsWrapper = styled(RowAlignedItems)<{
  isFirstStep?: boolean;
}>`
  justify-content: space-between;
  gap: 2rem;
  padding-right: 1rem;
  ${props => props.isFirstStep && `justify-content:unset;`}
  ${props => props.isFirstStep && `margin-left:auto;`}
  @media only screen and (max-width: ${({ theme }) =>
    theme.breakpoint.width480}) {
    ${props => props.isFirstStep && `margin:auto;`}
  }
`;
