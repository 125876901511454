import { forwardRef, useMemo } from 'react';
/* eslint-disable @typescript-eslint/no-explicit-any */
import Select, {
  type GroupBase,
  type MenuPlacement,
  type OptionsOrGroups,
} from 'react-select';
import styled, { useTheme } from 'styled-components';

import Icon from '@components/Icon';
import baseTheme from '@theme/base';

export const IconContainer = styled.div`
  padding: 1rem 1.6rem;
  color: ${props => props.theme.color.grey1};
`;

export interface InputSelectProps {
  className?: string;
  value?: unknown;
  onChange: (value: unknown) => void;
  options: OptionsOrGroups<any, GroupBase<any>> | undefined;
  placeholder?: string;
  disabled?: boolean;
  borderRadius?: string;
  backgroundColor?: string;
  color?: string;
  menuPlacement?: MenuPlacement;
  defaultValue?: string;
  minWidth?: string;
  height?: string;
  selectedValueColor?: string;
  fontSize?: string;
  maxWidth?: string;
  border?: string;
  isSearch?: boolean;
}

interface getStylesProps {
  borderRadius?: string;
  backgroundColor?: string;
  color?: string;
  minWidth?: string;
  maxWidth?: string;
  disabled?: boolean;
  selectedValueColor?: string;
  height?: string;
  fontSize?: string;
  border?: string;
}
const getStyles = ({
  borderRadius,
  backgroundColor,
  color,
  minWidth,
  maxWidth,
  disabled,
  selectedValueColor,
  height,
  fontSize,
  border,
}: getStylesProps) => ({
  menu: (provided: any) => ({
    ...provided,
    width: '90%',
    marginTop: '0',
    left: '5%',
    backgroundColor: baseTheme.color.lightest,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color:
      (disabled && baseTheme.color.grey2) ??
      selectedValueColor ??
      (color && baseTheme.color.grey1),
    marginLeft: '0.7rem',
    fontSize: fontSize ?? baseTheme.fontSize.size18,
    fontWeight: color && baseTheme.fontWeight.weight500,
  }),
  container: (provided: any) => ({
    ...provided,
    ...(maxWidth && { maxWidth, width: '100%' }),
    ...(height && { height }),
  }),
  input: (provided: any) => ({
    ...provided,
    color: baseTheme.color.grey1,
    fontFamily: baseTheme.font.body,
    fontSize: fontSize ?? baseTheme.fontSize.size18,
    fontWeight: baseTheme.fontWeight.weight400,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    marginLeft: '1.4rem',
    color: baseTheme.color.grey1,
    fontSize: fontSize ?? baseTheme.fontSize.size18,
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    ':hover': {
      color: selectedValueColor ?? baseTheme.color.primary,
    },
    color:
      selectedValueColor ??
      (state.isFocused
        ? baseTheme.color.primary
        : (color ?? baseTheme.color.textActive)),
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor:
      (state.isFocused && !backgroundColor && baseTheme.color.white) ??
      (disabled && baseTheme.color.inputGrey) ??
      backgroundColor ??
      baseTheme.color.white,
    color: color ?? baseTheme.color.grey1,
    borderRadius: borderRadius ?? baseTheme.borderRadius.radius10,
    border:
      border ?? `0.1rem solid ${backgroundColor ?? baseTheme.color.grey1}`,
    minWidth,
    minHeight: height ? 'unset' : '4rem',
    ...(height && { height }),
    boxShadow: 'none',
    ':hover': {
      borderColor: backgroundColor ?? baseTheme.color.grey1,
    },
    cursor: disabled ? 'not-allowed' : 'pointer',
  }),
  option: (provided: any) => ({
    ...provided,
    padding: '0.8rem 1rem',
    color: baseTheme.color.textActive,
    backgroundColor: baseTheme.color.transparent,
    fontSize: fontSize ?? baseTheme.fontSize.size15,
    fontWeight: baseTheme.fontWeight.weight700,
    minHeight: '3.7rem',
    borderBottom: `0.1rem solid hsl(0deg 0% 0% / 10%)`,
    ':last-child': {
      borderBottom: 'none',
    },
    cursor: disabled ? 'not-allowed' : 'pointer',
  }),
  disabled: (provided: any) => ({
    ...provided,
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    ...(height && { height }),
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    ...(height && { height }),
  }),
});

const InputSelect = forwardRef(
  (
    {
      onChange,
      value,
      disabled = false,
      options,
      borderRadius,
      backgroundColor,
      color,
      menuPlacement,
      defaultValue,
      minWidth,
      maxWidth,
      height,
      selectedValueColor,
      fontSize,
      border,
      isSearch,
      ...restProps
    }: InputSelectProps,
    ref
  ) => {
    const theme = useTheme();
    const handleOnChange = ({ value }: any) => {
      if (onChange) {
        onChange(value);
      }
    };
    const selectedValue = options?.find(
      option => option.key === value || option.value === value
    );

    const DropdownIndicator = () => {
      return (
        <IconContainer>
          {isSearch ? (
            <Icon name="search" />
          ) : (
            <Icon name="caret-arrow" color={theme.color.greyDark} />
          )}
        </IconContainer>
      );
    };

    const styles = useMemo(
      () =>
        getStyles({
          borderRadius,
          backgroundColor,
          color,
          minWidth,
          maxWidth,
          disabled,
          selectedValueColor,
          height,
          fontSize,
          border,
        }),
      [
        borderRadius,
        backgroundColor,
        color,
        minWidth,
        maxWidth,
        disabled,
        selectedValueColor,
        height,
        fontSize,
        border,
      ]
    );
    const defaultOption = options?.find(
      option => option.key === defaultValue || option.value === defaultValue
    );

    return (
      <Select
        isDisabled={disabled}
        styles={styles}
        onChange={handleOnChange}
        options={options}
        value={selectedValue || ''}
        menuPlacement={menuPlacement}
        defaultValue={defaultOption}
        components={{ DropdownIndicator }}
        {...restProps}
      />
    );
  }
);
InputSelect.displayName = 'InputSelect';
export default InputSelect;
