import { Column } from '@components/LinearLayout';
import { PSmallBold } from '@components/Typography';
import styled from 'styled-components';

export const FormItemRoot = styled(Column)<{
  width: string;
  isLocked: boolean;
  spacingLabel: string;
}>`
  position: relative;
  width: ${props => props.width};
  opacity: ${props => (props.isLocked ? 0.5 : 1)};
  point-event: ${props => (props.isLocked ? 'none' : 'inherit')};
  pointer: ${props => (props.isLocked ? 'none' : 'inherit')};
  gap: ${props => props.spacingLabel};
`;

export const FormItemLabel = styled(PSmallBold)<{
  required: boolean;
  isCentered: boolean;
  paddingLabel: string;
}>`
  display: block;
  padding: ${props => props.paddingLabel};
  margin: ${props => props.isCentered && 'auto'};
  &::after {
    content: '${props => (props.required ? '*' : '')}';
  }
`;

export const FormItemChildren = styled.div<{
  isLocked?: boolean;
}>`
  cursor: ${props => props.isLocked && 'not-allowed'};
`;

export const MetaInformationWrapper = styled.div`
  position: absolute;
`;
