import { Column } from '@components/LinearLayout';
import Spinner from '@components/Spinner';
import { H4 } from '@components/Typography';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const RootLoadingFallback = styled.div`
  height: 70vh;
  display: grid;
  place-items: center;
`;

const SpinnerWrapper = styled(Column)`
  align-items: center;
`;

const LoadingFallBack = () => {
  const { t } = useTranslation();
  return (
    <RootLoadingFallback>
      <SpinnerWrapper gap="2rem">
        <H4>{t('loading')} ...</H4>
        <Spinner size="5rem" />
      </SpinnerWrapper>
    </RootLoadingFallback>
  );
};

export default LoadingFallBack;
