import { type MouseEvent, type RefObject, memo } from 'react';

import styled from 'styled-components';
import { type BaseThemeType } from '../../theme/base';

export interface IconProps {
  name: string;
  forwardRef?:
    | ((instance: HTMLElement | null) => void)
    | RefObject<HTMLElement>
    | null
    | undefined;
  rotation?: number;
  onClick?: () => void;
  onMouseOver?: (ev: MouseEvent<HTMLInputElement>) => void;
  onMouseLeave?: (ev: MouseEvent<HTMLInputElement>) => void;
  clickable?: boolean;
  margin?: string;
  scaleX?: string;
  disabled?: boolean;
  bold?: boolean;
  color?: string;
  size?: string;
}
type IconStyledProps = Pick<
  IconProps,
  | 'clickable'
  | 'rotation'
  | 'margin'
  | 'scaleX'
  | 'disabled'
  | 'bold'
  | 'color'
  | 'size'
> & {
  theme: BaseThemeType;
};
const Ic = styled.i<IconStyledProps>`
  ${(props: IconStyledProps) =>
    (props.clickable ?? false) && 'cursor: pointer;'}
  ${(props: IconStyledProps) =>
    (props.disabled ?? false) && 'pointer-events: none;'}
  ${(props: IconStyledProps) => (props.bold ?? false) && 'font-weight: bold;'}
  &:active {
    opacity: 0.4;
  }
  line-height: normal;
  justify-content: center;
  align-items: center;
  display: flex;
  ${(props: IconStyledProps) =>
    props.rotation != null && `transform:  rotate(${props.rotation}deg);`}
  ${(props: IconStyledProps) =>
    props.margin != null && `margin: ${props.margin}`};
  ${(props: IconStyledProps) =>
    props.scaleX != null && `transform: scaleX(${props.scaleX})`};
  ${(props: IconStyledProps) => props.color != null && `color: ${props.color};`}
  ${(props: IconStyledProps) =>
    props.size != null && `font-size: ${props.size}`}
`;

function Icon({
  name,
  rotation,
  onClick,
  onMouseOver,
  onMouseLeave,
  clickable,
  margin,
  scaleX,
  disabled,
  bold,
  color,
  size,
  forwardRef,
}: IconProps) {
  return (
    <Ic
      ref={forwardRef}
      data-testid="components-icon"
      className={`icon-${name}`}
      onClick={onClick}
      clickable={clickable ?? !(onClick == null)}
      onMouseOver={(ev: MouseEvent<HTMLInputElement>) => onMouseOver?.(ev)}
      onMouseLeave={(ev: MouseEvent<HTMLInputElement>) => onMouseLeave?.(ev)}
      rotation={rotation}
      margin={margin}
      scaleX={scaleX}
      disabled={disabled}
      bold={bold}
      color={color}
      size={size}
    />
  );
}

export default memo(Icon);
