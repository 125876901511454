import { Column, RowAlignedItems } from '@components/LinearLayout';
import { H5 } from '@components/Typography';
import styled from 'styled-components';

export const CentralContent = styled(Column)`
  padding: 0 1rem;
`;

export const TicketConfigHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    display: flex;
    flex-direction: column;
  }
`;

export const RightSideContent = styled.div`
  flex-flow: row wrap;
  width: 100%;
`;

export const LeftSideContent = styled.div`
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    margin: auto;
  }
`;

export const TicketCardItemWrapper = styled(Column)`
  margin-top: 3rem;
  gap: 2rem;
`;

export const CurrencySwitcher = styled(RowAlignedItems)`
  gap: 2rem;
`;

export const CenteredText = styled(H5)`
  text-align: center;
`;
