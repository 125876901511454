import { Column } from '@components/LinearLayout';
import { PBold, PSmall } from '@components/Typography';
import { type CURRENCY } from '@constants/generic';
import { type OrderedTicket } from '@hubs101/booking-api-skd-client/lib/types';
import {
  DiscountPrice,
  StandardElement,
} from '@pages/Purchase/components/AttendeeDetailsStep/styles';
import { formatPrice } from '@utils/fn';

const PublicBookingDetailsItem = ({
  ticket,
  currency,
  testId,
}: {
  ticket: OrderedTicket;
  currency: string;
  testId?: string;
}) => {
  const symbol = currency as keyof typeof CURRENCY;

  return (
    <Column gap="0.3rem" key={ticket.reference}>
      <StandardElement>
        <PBold data-testid={`${testId ?? ''}-name`}>{ticket?.name}</PBold>
        <PBold data-testid={`${testId ?? ''}-price`}>
          {formatPrice(ticket?.basePrice, symbol)}
        </PBold>
      </StandardElement>
      {ticket?.discountAvailable && (
        <StandardElement>
          <DiscountPrice data-testid={`${testId ?? ''}-discount`}>
            Discount
          </DiscountPrice>
          <DiscountPrice>{`- ${formatPrice(
            ticket?.discountValue || ticket?.discount,
            symbol
          )}`}</DiscountPrice>
        </StandardElement>
      )}

      {ticket?.extraOptions?.map(o => (
        <StandardElement key={o?.id}>
          <PSmall
            data-testid={`${testId ?? ''}-${o?.id ?? ''}-extraOptions-amount`}
          >{`${o?.name}, ${o?.amount}`}</PSmall>
          <PSmall
            data-testid={`${testId ?? ''}-${o?.id ?? ''}-extraOptions-price`}
          >
            {formatPrice(o?.price, symbol)}
          </PSmall>
        </StandardElement>
      ))}
    </Column>
  );
};

export default PublicBookingDetailsItem;
