import { Column } from '@components/LinearLayout';
import styled from 'styled-components';

export const FormWrapper = styled(Column)`
  padding: 2rem 0;
  gap: 3rem;
`;

export const FormItemsWrapper = styled.div`
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(29.6rem, 1fr));

  @media only screen and (max-width: ${({ theme }) =>
    theme.breakpoint.width480}) {
    grid-gap: 3.5rem;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
 
}
`;
