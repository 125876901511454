import { type DiscountType } from '@hubs101/booking-api-skd-client/lib/types';

export const findDiscountedPriceOfSelectedTicket = (
  ticketId: string,
  ticketPrice: number,
  discount: DiscountType | undefined
) => {
  if (!ticketPrice || ticketPrice === 0) {
    return 0;
  }
  const hasDiscount = discount?.tickets.find(item => item === ticketId);
  if (hasDiscount && discount?.value) {
    return ticketPrice - (discount.value / 100) * ticketPrice;
  } else {
    return undefined;
  }
};
