import styled from 'styled-components';

export const ModalContent = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;

  position: absolute;
  left: 50%;
  top: 50%;
  max-height: 80%;
  width: 60%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    height: 94%;
    width: 100%;
    top: 53%;
  }

  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.grey1};

  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgb(25, 34, 50, 0.7);

  flex: none;
  order: 5;
  flex-grow: 0;
  z-index: 5;
`;

export const ModalSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

export const ModalCenterSection = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 0.25rem;
`;
