import Button, { ButtonSizes, ButtonStyle } from '@components/Button';
import {
  type Booking,
  type BookingCheckDetails,
  type BookingsFormInput,
  CURRENCY,
  type DiscountType,
  type EventType,
  type PaymentMethodType,
  type PublicBookingsCheckInput,
  type QueryResponseType,
  type Ticket,
} from '@hubs101/booking-api-skd-client/lib/types';
import {
  type TicketOptionsInput,
  usePublicBookingsCheck,
  usePublicEvents,
  usePublicPaymentMethods,
  usePublicTickets,
} from '@utils/apiHooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Column } from '@components/LinearLayout';
import NotFound from '@pages/NotFound';
import { extractSearchParams } from '@utils/fn';
import { useHookParams } from '@utils/hooks';
import { isEqual } from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import AttendeeDetailsStep from '../AttendeeDetailsStep';
import PaymentDetailsStep from '../PaymentDetailsStep';
import SummaryDetailsStep from '../SummaryDetailsStep';
import TicketDetailsStep from '../TicketDetailsStep';
import { ButtonsWrapper } from './styles';

interface BookingFormProps {
  eventSlug: string | undefined;
}
type CheckedBooking = PublicBookingsCheckInput & { language: string };
const BookingForm = ({ eventSlug }: BookingFormProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const [language, code, currencyParam] = useHookParams(['la', 'tc', 'cu']);
  const [selectedCurrency, setSelectedCurrency] = useState<string>();
  const [discount, setDiscount] = useState<DiscountType | undefined>(undefined);
  const [acceptanceDeclaration, setAcceptanceDeclaration] =
    useState<boolean>(false);

  const [bookingData, setBookingData] = useState<Booking | null>(null);
  const [errorBookings, setErrorBookings] = useState<string>('');
  const [checkedBooking, setCheckedBooking] = useState<CheckedBooking>();
  const [step, setStep] = useState<number>(0);
  const [selectedTicketsWithOptions, setSelectedTicketsWithOptions] = useState<
    TicketOptionsInput[]
  >([]);

  const {
    data: eventData,
    isLoading: isEventLoading,
    error: eventError,
  } = usePublicEvents({
    page: 0,
    slug: eventSlug,
    language,
  });
  const {
    data,
    error: ticketsError,
    isLoading: isTicketsLoading,
    refetch: refetchTickets,
  } = usePublicTickets({
    page: 0,
    ticketCode: code,
    eventSlug,
    language,
  });

  const event = eventData?.events?.result?.data?.[0] as EventType;

  const publicTickets = data?.tickets?.result?.data.sort(
    (a: Ticket, b: Ticket) => a.priority - b.priority
  ) as Ticket[];

  const { data: paymentMethods, error: paymentMethodsError } =
    usePublicPaymentMethods({ id: event?.id, language });

  const availablePaymentMethods = useMemo(
    () =>
      (
        (paymentMethods?.paymentMethods?.result?.data as PaymentMethodType[]) ??
        []
      ).filter(
        (payment: PaymentMethodType) =>
          payment?.currency.toUpperCase() === selectedCurrency
      ),
    [paymentMethods?.paymentMethods?.result?.data, selectedCurrency]
  );
  const [selectedPayment, setSelectedPayment] = useState<
    PaymentMethodType | undefined
  >(availablePaymentMethods?.[0] ?? undefined);

  useEffect(() => {
    if (availablePaymentMethods?.length > 0 && !selectedPayment) {
      setSelectedPayment(availablePaymentMethods[0]);
    }
  }, [availablePaymentMethods, selectedPayment]);

  const currencies: string[] = useMemo(() => {
    const currencies: string[] =
      paymentMethods?.paymentMethods?.result?.data?.map(
        (paymentMethod: PaymentMethodType) => paymentMethod.currency
      );
    return [...new Set(currencies)];
  }, [paymentMethods?.paymentMethods?.result?.data]);

  const defaultValues: BookingsFormInput = useMemo(
    () => ({
      salutation: '',
      first_name: '',
      last_name: '',
      job_title: '',
      company_name: '',
      job_level: '',
      job_responsibility: '',
      company_size: '',
      company_industry: '',
      address_country: 'germany',
      contact_email: '',
      type_of_business: '',
      vat_id: '',
      po_number: '',
      invoice_full_name: '',
      address_street: '',
      additional_details: '',
      address_city: '',
      address_postal_code: '',
      contact_phone: '',
      card_number: '',
      card_holder: '',
      expiry_date: '',
      cvc: '',
    }),
    []
  );

  const {
    reset,
    control,
    watch,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const watchCountry = watch('address_country');
  const invoiceValues = getValues();

  const bookingCheckInput: PublicBookingsCheckInput = useMemo(
    () => ({
      event: event?.id,
      discount: discount?.id,
      payment_account: selectedPayment?.id ?? '',
      tickets: selectedTicketsWithOptions,
      invoice: invoiceValues,
    }),
    [
      discount?.id,
      event?.id,
      invoiceValues,
      selectedPayment?.id,
      selectedTicketsWithOptions,
    ]
  );

  const bookingCheckInputCountryInvoice: PublicBookingsCheckInput = useMemo(
    () => ({
      ...bookingCheckInput,
      invoice: { address_country: watchCountry },
    }),
    [bookingCheckInput, watchCountry]
  );

  const handleErrorsBookings = useCallback(
    (error: Error) => {
      setErrorBookings(error.message);
      // todo: this should be changed when BE implements errors with translated labels or ids
      // for now it is a predefined error message
      if (error.message.includes('The ticket is not active')) {
        void refetchTickets();
        setSelectedTicketsWithOptions([]);
      }
    },
    [refetchTickets]
  );

  const {
    data: calculatedData,
    status,
    mutate: mutateBookingsCheck,
    error: bookingsCheckError,
  } = usePublicBookingsCheck(
    bookingCheckInputCountryInvoice,
    language,
    handleErrorsBookings
  );
  const isBookingsCheckLoading = status === 'pending';

  const bookingsCheck = calculatedData!;

  const eventDetails: QueryResponseType<EventType> = {
    data: event,
    loading: isEventLoading,
    error: eventError?.message,
  };

  const ticketsDetails: QueryResponseType<Ticket[]> = {
    data: publicTickets,
    loading: isTicketsLoading,
    error: ticketsError?.message,
  };

  const paymentMethodsDetails: QueryResponseType<PaymentMethodType[]> = {
    data: paymentMethods?.paymentMethods?.result?.data as PaymentMethodType[],
    error: paymentMethodsError?.message,
  };

  const bookingsCheckDetails: QueryResponseType<BookingCheckDetails> = useMemo(
    () => ({
      data: bookingsCheck,
      loading: isBookingsCheckLoading,
      mutate: mutateBookingsCheck,
      error: bookingsCheckError?.message ?? '',
      isSuccess: status === 'success',
    }),
    [
      bookingsCheck,
      bookingsCheckError?.message,
      isBookingsCheckLoading,
      mutateBookingsCheck,
      status,
    ]
  );

  useEffect(() => {
    if (
      !isEqual(checkedBooking, {
        ...bookingCheckInputCountryInvoice,
        language,
      }) &&
      Boolean(selectedTicketsWithOptions?.length) &&
      Boolean(event?.id?.length) &&
      !isBookingsCheckLoading
    ) {
      setCheckedBooking({ ...bookingCheckInputCountryInvoice, language });
      mutateBookingsCheck();
    }
  }, [
    bookingCheckInputCountryInvoice,
    checkedBooking,
    event?.id?.length,
    isBookingsCheckLoading,
    language,
    mutateBookingsCheck,
    selectedTicketsWithOptions?.length,
  ]);

  useEffect(() => {
    const firstCurrency = currencies?.[0]?.toUpperCase();
    if (!selectedCurrency && !currencyParam && firstCurrency) {
      setSelectedCurrency(firstCurrency);
      setSearchParams({
        ...extractSearchParams(searchParams),
        cu: firstCurrency,
      });
    } else if (
      !selectedCurrency &&
      !currencyParam &&
      currencies?.length === 0
    ) {
      setSelectedCurrency(CURRENCY.EUR);
      setSearchParams({
        ...extractSearchParams(searchParams),
        cu: CURRENCY.EUR,
      });
    } else if (currencyParam) {
      if (currencies.includes(currencyParam.toLowerCase())) {
        setSelectedCurrency(currencyParam);
      } else {
        setSelectedCurrency(CURRENCY.EUR);
      }
    }
  }, [
    currencies,
    selectedCurrency,
    searchParams,
    setSearchParams,
    currencyParam,
  ]);

  useEffect(() => {
    return () => {
      reset(defaultValues);
    };
  }, [defaultValues, reset]);

  const bookingSteps = [
    {
      title: t('ticket-details'),
      render: TicketDetailsStep({
        eventDetails,
        ticketsDetails,
        paymentMethodsDetails,
        currencies,
        discount,
        setDiscount,
        bookingsCheckDetails,
        selectedTicketsWithOptions,
        setSelectedTicketsWithOptions,
      }),
    },
    {
      title: t('attendee-details'),
      render: AttendeeDetailsStep({
        step,
        setStep,
        control,
        errors,
        trigger,
        handleSubmit,
        getValues,
        setValue,
        tickets: publicTickets,
        paymentMethods: availablePaymentMethods,
        event,
        discount,
        selectedPayment,
        setSelectedPayment,
        errorBookings,
        setErrorBookings,
        handleErrorsBookings,
        bookingsCheckDetails,
        bookingCheckInput,
        setBookingData,
        selectedTicketsWithOptions,
        setSelectedTicketsWithOptions,
      }),
    },
    {
      title: t('invoice-and-payment'),
      render: PaymentDetailsStep({
        step,
        setStep,
        control,
        errors,
        bookingData,
      }),
    },
    {
      title: t('summary'),
      render: SummaryDetailsStep({
        step,
        setStep,
        watch,
        acceptanceDeclaration,
        setAcceptanceDeclaration,
      }),
    },
  ];

  const isLastStep = step === bookingSteps.length - 1;

  const isNextButtonActive = useMemo(() => {
    if (isLastStep) return acceptanceDeclaration;
    return true;
  }, [acceptanceDeclaration, isLastStep]);

  const renderButton = useMemo(
    () => (
      <ButtonsWrapper data-testid="stepper:container" isFirstStep={step === 0}>
        {step === 1 && (
          <Button
            buttonSize={ButtonSizes.medium}
            buttonStyle={ButtonStyle.outline}
            content={t('back')}
            onClick={() => {
              if (navigator.onLine) {
                setStep(step - 1);
                window.scrollTo(0, 0);
              }
            }}
          />
        )}

        {step === 0 && publicTickets?.length > 0 && (
          <Button
            buttonSize={ButtonSizes.medium}
            buttonStyle={ButtonStyle.default}
            backgroundColor={theme.color.green}
            content={t('proceed-to-checkout')}
            disabled={
              !isNextButtonActive ||
              selectedTicketsWithOptions?.length === 0 ||
              !navigator.onLine
            }
            onClick={() => {
              if (navigator.onLine) {
                if (!isLastStep) setStep(step + 1);
                window.scrollTo(0, 0);
              }
            }}
          />
        )}
      </ButtonsWrapper>
    ),
    [
      isLastStep,
      isNextButtonActive,
      publicTickets?.length,
      selectedTicketsWithOptions?.length,
      step,
      t,
      theme.color.green,
    ]
  );

  if (!event && !isEventLoading) {
    return <NotFound />;
  }

  return (
    <>
      <Column gap="3rem">
        {/* <BookingStepper bookingSteps={bookingSteps} step={step} /> */}
        {bookingSteps[step].render}

        {renderButton}
      </Column>
    </>
  );
};

export default BookingForm;
