import { Column, RowAlignedItems } from '@components/LinearLayout';
import { P, PBold, PSmall, PSmallBold } from '@components/Typography';
import { HEADER_HEIGHT } from '@constants/generic';
import styled from 'styled-components';

export const FirstLineForm = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  gap: 2rem;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    display: flex;
    flex-direction: column;
  }
`;

export const SalutationWrapper = styled.div`
  width: 8rem;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    width: 100%;
  }
`;

export const FormRow = styled.div<{ columnsNb?: number }>`
  grid-template-columns: ${props => {
    if (props.columnsNb === 3) return '1fr 1fr 1fr';
    else if (props.columnsNb === 2) return '1fr 1fr';
    return '1fr';
  }};
  display: grid;
  height: 100%;
  grid-gap: 2rem;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    display: flex;
    flex-direction: column;
  }
`;

export const AttendeeDetailsRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  width: 100%;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    display: flex;
    flex-direction: column;
  }
`;

export const LeftSideWrapper = styled(Column)`
  gap: 2rem;
`;

export const RightSideWrapper = styled.div<{ isIframe: boolean }>`
  width: 30rem;
  height: fit-content;
  position: sticky;
  top: ${props => (props.isIframe ? 0 : HEADER_HEIGHT)};
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    width: 100%;
  }
`;

export const HeaderRightWrapper = styled(Column)`
  padding: 2rem;
  border-bottom: 0.1rem solid ${props => props.theme.color.grey1};
  border-top-right-radius: ${props => props.theme.borderRadius.radius10};
  border-top-left-radius: ${props => props.theme.borderRadius.radius10};
  gap: 0.5rem;
`;

export const RightBody = styled(Column)`
  padding: 0 2rem;
  background-color: ${props => props.theme.color.lightest};
  border-bottom-right-radius: ${props => props.theme.borderRadius.radius10};
  border-bottom-left-radius: ${props => props.theme.borderRadius.radius10};
  grid-template-rows: 1fr auto;
  gap: 1rem;
`;

export const EventTitle = styled(PBold)``;
export const EventAddress = styled(P)``;
export const EventDates = styled(PSmall)``;

export const CenterItem = styled.div`
  width: 100%;
  text-align: center;
`;

export const Summary = styled(PSmallBold)`
  border-bottom: 0.1rem solid ${props => props.theme.color.grey1};
  padding: 1rem 0;
`;

export const PricesWrapper = styled(Column)`
  border-top: 0.1rem solid ${props => props.theme.color.grey1};
  border-bottom: 0.2rem solid ${props => props.theme.color.greyDark};
  padding: 1rem 0;
  gap: 1rem;
  margin: 0.5rem 0;
`;

export const StandardElement = styled(RowAlignedItems)`
  justify-content: space-between;
`;

export const PriceBody = styled(Column)`
  margin-bottom: 0.8rem;
`;

export const TicketListWrapper = styled(Column)`
  margin: 1rem 0 0 0;
`;

export const DiscountPrice = styled(PSmall)`
  color: ${props => props.theme.color.green};
`;
