import { memo, type ReactNode } from 'react';
import styled from 'styled-components';

export interface TicketContainerProps {
  padding?: string;
  borderRight?: string;
  borderTop?: string;
  borderBottom?: string;
  borderLeft?: string;
  borderTopRightRadius?: string;
  borderTopLeftRadius?: string;
  borderBottomRightRadius?: string;
  borderBottomLeftRadius?: string;
  boxShadow?: string;
  id?: string;
  children: ReactNode | string;
}

const StyledTicketContainer = styled.div<{
  padding?: string;
  borderRight?: string;
  borderTop?: string;
  borderBottom?: string;
  borderLeft?: string;
  borderTopRightRadius?: string;
  borderTopLeftRadius?: string;
  borderBottomRightRadius?: string;
  borderBottomLeftRadius?: string;
  boxShadow?: string;
  key?: string;
}>`
  padding: ${props => props.padding};
  border-right: ${props => props.borderRight};
  border-top: ${props => props.borderTop};
  border-bottom: ${props => props.borderBottom};
  border-left: ${props => props.borderLeft};
  border-top-right-radius: ${props => props.borderTopRightRadius};
  border-top-left-radius: ${props => props.borderTopLeftRadius};
  border-bottom-right-radius: ${props => props.borderBottomRightRadius};
  border-bottom-left-radius: ${props => props.borderBottomLeftRadius};
  box-shadow: ${props => props.boxShadow};
`;

export function TicketContainer({ ...props }: TicketContainerProps) {
  return (
    <StyledTicketContainer key={props?.id} {...props}>
      {props.children}
    </StyledTicketContainer>
  );
}

export default memo(TicketContainer);
