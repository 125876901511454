export const REGEX = {
  EMAIL: /^[.+\-\w]{2,50}@[.\-\w]{2,50}\.[-\w]{2,10}$/,
  NAME: /^[a-zA-Z \u00C0-\u00FF\-'",.]{2,30}$/,
  // eslint-disable-next-line no-control-regex
  TEXT: /^[a-zA-Z \d\u00C0-\u00FF\u0000-\-'",./@?*]{2,30}$/,
  PHONE: /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,9}$/im,
  NUMBER: /^-?\d+$/,
  TIME_FORMAT_24H: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
  POSTAL_CODE: /^([0-9]{3,7})(-[0-9]{3,7})?$/,
  HTTPS: /^https?:\/\//g,
  ALPHANUMERIC: /^[a-z0-9]+$/i,
  WEBPAGE:
    /^(http:\/\/|https:\/\/)?(www.)?([a-zA-Z0-9]+).[a-zA-Z0-9]*.[‌​a-z]{3}\.([a-z]+)?$/,
  INSTAGRAM:
    /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/gim,
  FACEBOOK:
    /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-.]*\/)*([\w\-.]*)/,
};
