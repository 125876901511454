import Icon from '@components/Icon';
import { useOutsideAlerter } from '@utils/hooks';
import { useCallback, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { Container, Title } from './styles';

function TextPopover({ text }: { text: string }) {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const iconRef = useRef(null);
  const containerRef = useRef(null);
  const theme = useTheme();

  const onClickOutside = useCallback(() => {
    setModalVisible(false);
  }, []);

  const onClickInside = useCallback(() => {
    setModalVisible(true);
  }, []);

  useOutsideAlerter([iconRef, containerRef], onClickOutside);

  return (
    <>
      <Icon
        forwardRef={iconRef}
        name="info"
        color={theme.color.greyDark}
        size="2rem"
        onClick={!modalVisible ? onClickInside : onClickOutside}
      />
      {modalVisible && (
        <Container ref={containerRef}>
          <Title>{text}</Title>
        </Container>
      )}
    </>
  );
}

export default TextPopover;
