import { H3 } from '@components/Typography';
import styled from 'styled-components';

export const BookingRoot = styled.div`
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.color.white};
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    padding: 2rem 1rem;
  }
`;

export const TitleWrapper = styled(H3)`
  text-align: center;
`;
