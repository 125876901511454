/* eslint-disable @typescript-eslint/no-explicit-any */
import 'react-phone-number-input/style.css';

import {
  ErrorIconWrapper,
  HintComponent,
} from '@components/InputMetaInformation/styles';
import { useEffect, useState } from 'react';
import { type Control, type UseFormSetValue } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';

import Icon from '@components/Icon';
import { H6 } from '@components/Typography';
import { requiredValidationRule } from '@utils/form';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';

interface PhoneInputProps {
  control: Control<any>;
  errors: Record<string, any>;
  name: string;
  title?: string;
  defaultValue?: string;
  setValue: UseFormSetValue<any>;
  placeholder?: string;
}

const PhoneInputWithCountryStyled = styled(PhoneInputWithCountry)<{
  error?: string;
  placeholder?: string;
  onChange?: (id: string) => void;
}>`
  color: ${props => props.theme.color.grey1};
  border-radius: ${props => props.theme.borderRadius.radius50};
  border: 0.1rem solid ${props => props.theme.color.grey2};

  .PhoneInputInput {
    flex: 1;
    min-width: 0;
    color: ${props => props.theme.color.primaryText};
    border-radius: ${props => props.theme.borderRadius.radius50};
    border-color: ${props => props.theme.color.transparent};
    height: 4rem;
    padding: 0 1rem 0 0;
    &::placeholder {
      color: ${props => props.theme.color.grey2};
    }
  }

  .PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: var(--PhoneInputCountrySelect-marginRight);
    margin-left: 2rem;
  }
`;

const Title = styled(H6)`
  width: 100%;
  margin: 1rem 0 0.5rem 4rem;
  font-weight: ${props => props.theme.fontWeight.weight700};
  font-size: 13px;
`;

const Line = styled.div`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 0.2rem 0 0 0;
`;

const ErrorSubtitle = styled(H6)`
  color: ${props => props.theme.color.error};
  display: flex;
  font-size: ${props => props.theme.fontSize.size12};
  line-height: 1.6rem;
`;

function PhoneNumberInput({
  control,
  errors,
  name,
  title,
  defaultValue,
  setValue,
  placeholder,
}: PhoneInputProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [errorValidation, setErrorValidation] = useState(
    errors?.[name]?.message
  );
  const invalidPhoneNumber = t('invalid-phone-number');

  useEffect(() => {
    setErrorValidation(errors?.[name] && invalidPhoneNumber);
  }, [errors, invalidPhoneNumber, name]);

  return (
    <>
      {title && (
        <Line>
          <Title>{title}</Title>
        </Line>
      )}
      <PhoneInputWithCountryStyled
        key={defaultValue ?? name}
        defaultCountry="DE"
        name={name}
        control={control}
        rules={{
          ...requiredValidationRule,
          validate: (value: string) =>
            value ? isValidPhoneNumber(value) : true,
        }}
        error={errors?.[name]?.message}
        countryCallingCodeEditable={false}
        shouldUnregister={false}
        placeholder={placeholder}
        onChange={(value: string) => {
          if (value) setValue(name, value);
          else setValue(name, '');
          setErrorValidation(
            !value
              ? undefined
              : isValidPhoneNumber(value)
                ? undefined
                : invalidPhoneNumber
          );
        }}
        data-testid="input:phone-number"
      />
      {errorValidation && (
        <HintComponent data-testid="input-error">
          <ErrorIconWrapper>
            <Icon name="attention" color={theme.color.error} size="1rem" />
          </ErrorIconWrapper>
          <ErrorSubtitle>{invalidPhoneNumber}</ErrorSubtitle>
        </HintComponent>
      )}
    </>
  );
}

export default PhoneNumberInput;
