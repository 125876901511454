export const mapPaymentTypeToTranslation = (
  type: string,
  t: (id: string) => string
): string => {
  switch (type) {
    case 'swift':
      return t('invoice');
    case 'stripe':
      return t('credit-card');
    default:
      return t(type);
  }
};
