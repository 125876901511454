import {
  ExtraContentContainer,
  LogoImg,
} from '@pages/Purchase/components/PaymentDetailsStep/styles';
import logoMastercard from '@assets/images/payment/mastercard.png';
import logoVisa from '@assets/images/payment/visa.png';
import logoPaypal from '@assets/images/payment/paypal.png';

const extraContentCreditCard = (
  <ExtraContentContainer>
    <LogoImg src={logoMastercard} alt="mastercard" />
    <LogoImg src={logoVisa} alt="visa" />
  </ExtraContentContainer>
);

const extraContentPaypal = (
  <ExtraContentContainer>
    <LogoImg src={logoPaypal} alt="paypal" />
  </ExtraContentContainer>
);

export const extraContentMapper = (type: string) => {
  switch (type) {
    case 'paypal':
      return extraContentPaypal;
    case 'stripe':
      return extraContentCreditCard;
    default:
      return undefined;
  }
};
