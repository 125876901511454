import { RowAlignedItems } from '@components/LinearLayout';
import { P } from '@components/Typography';
import styled from 'styled-components';

export const PCapitalized = styled(P)`
  text-transform: capitalize;
`;

export const BodyWrapper = styled(RowAlignedItems)`
  justify-content: space-between;
`;
