import { PBold, PSmall } from '@components/Typography';
import { type CURRENCY } from '@constants/generic';
import { type BookingDetails } from '@hubs101/booking-api-skd-client/lib/types';
import {
  PricesWrapper,
  StandardElement,
  TicketListWrapper,
} from '@pages/Purchase/components/AttendeeDetailsStep/styles';
import { formatPrice } from '@utils/fn';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import PublicBookingDetailsItem from './item';

interface BookingDetailsSectionProps {
  bookingDetails: BookingDetails;
}

const PublicBookingDetailsSection = ({
  bookingDetails,
}: BookingDetailsSectionProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const selectedCurrency =
    bookingDetails?.currency ?? searchParams.get('cu') ?? 'EUR';
  const symbol = selectedCurrency as keyof typeof CURRENCY;

  return (
    <>
      <TicketListWrapper gap="1.4rem">
        {bookingDetails?.order?.map((ticket, index) => (
          <PublicBookingDetailsItem
            key={`${ticket?.id}_${index}`}
            testId={`booking-details-${ticket?.id}`}
            ticket={ticket}
            currency={symbol}
          />
        ))}
      </TicketListWrapper>
      <PricesWrapper>
        <StandardElement>
          <PSmall>{t('subtotal')}</PSmall>
          <PSmall data-testid={`booking-details-subtotal`}>
            {formatPrice(Number(bookingDetails?.sub_total), symbol)}
          </PSmall>
        </StandardElement>
        {Number(bookingDetails?.vat_value) > 0 && (
          <StandardElement>
            <PSmall data-testid={`booking-details-vat-title`}>
              {t('vat')} {Number(bookingDetails?.vat)}%
            </PSmall>
            <PSmall data-testid={`booking-details-vat-value`}>
              {formatPrice(Number(bookingDetails?.vat_value), symbol)}
            </PSmall>
          </StandardElement>
        )}
      </PricesWrapper>
      <StandardElement>
        <PBold>{t('total')}</PBold>
        <PBold data-testid={`booking-details-total-value`}>
          {formatPrice(Number(bookingDetails?.total), symbol)}
        </PBold>
      </StandardElement>{' '}
    </>
  );
};

export default PublicBookingDetailsSection;
