import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(calendar);
dayjs.extend(duration);

dayjs.extend(utc);
dayjs.extend(timezone);

export const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatDateTimeZoned = (
  date: string,
  timezone: string | undefined
) => {
  if (date?.length === 0) {
    return null;
  }

  return dayjs.utc(date).tz(timezone).format('DD MMM YYYY, hh:mm A');
};

export const formatDateRange = (startDate: string, endDate: string) => {
  dayjs.locale(localStorage?.getItem('language') ?? 'en');
  const isGerman = dayjs().locale() === 'de';
  const isSameYear = dayjs(startDate).isSame(dayjs(endDate), 'year');
  const isSameMonth = dayjs(startDate).isSame(dayjs(endDate), 'month');
  const isSameDay = dayjs(startDate).isSame(dayjs(endDate), 'day');
  const fullFormat = `DD${isGerman ? '.' : ''} MMMM YYYY`;
  const monthFormat = `DD${isGerman ? '.' : ''} MMMM`;
  const daysFormat = `DD${isGerman ? '.' : ''}`;
  if (isSameDay && isSameMonth && isSameYear) {
    return dayjs(startDate)
      .locale(localStorage.getItem('language') ?? 'en')
      .format(fullFormat);
  } else if (isSameMonth && isSameYear) {
    return `${dayjs(startDate)
      .locale(localStorage.getItem('language') ?? 'en')
      .format(daysFormat)} - ${dayjs(endDate)
      .locale(localStorage.getItem('language') ?? 'en')
      .format(fullFormat)}`;
  } else if (isSameYear) {
    return `${dayjs(startDate)
      .locale(localStorage.getItem('language') ?? 'en')
      .format(monthFormat)} - ${dayjs(endDate)
      .locale(localStorage.getItem('language') ?? 'en')
      .format(fullFormat)}`;
  } else {
    return `${dayjs(startDate)
      .locale(localStorage.getItem('language') ?? 'en')
      .format(fullFormat)} - ${dayjs(endDate)
      .locale(localStorage.getItem('language') ?? 'en')
      .format(fullFormat)}`;
  }
};
