import { LineSkeleton, SquareSkeleton } from '@components/SkeletonsLoaders';
import {
  TicketCardItemRoot,
  TicketOptions,
} from '@composites/TicketCardItem/styles';
import { useTheme } from 'styled-components';

const TicketSkeleton = () => {
  const theme = useTheme();
  const skeletonLines = 3;

  return (
    <TicketCardItemRoot
      padding="1rem"
      borderRight={`0.1rem solid ${theme.color.greyMedium}`}
      borderTop={`0.1rem solid ${theme.color.greyMedium}`}
      borderBottom={`0.1rem solid ${theme.color.greyMedium}`}
      borderLeft={`0.3rem solid ${theme.color.greyDark}`}
      borderTopRightRadius={theme.borderRadius.radius10}
      borderBottomRightRadius={theme.borderRadius.radius10}
      boxShadow={`0 0.2rem 0 rgba(0, 0, 0, 0.1)`}
    >
      <SquareSkeleton width="12.5rem" height="100%" margin="0 3rem 0 0" />
      <TicketOptions>
        {Array(skeletonLines)
          .fill(0)
          .map((_, index) => (
            <LineSkeleton key={index} width="100%" height="2rem" />
          ))}
      </TicketOptions>
    </TicketCardItemRoot>
  );
};

export default TicketSkeleton;
