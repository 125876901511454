import { BoxDecoration } from '@components/BoxDecoration';
import CardContainerSkeleton from '@components/CardContainerWithIcon/components/CardContainerSkeleton';
import IconRounded, {
  IconRoundedSizes,
  IconRoundedStyles,
} from '@components/IconRounded';
import { CenterItem, Column } from '@components/LinearLayout';
import { H5, P } from '@components/Typography';
import {
  AlignedText,
  PurchaseSuccessfulCard,
} from '@pages/Purchase/components/PaymentDetailsStep/styles';
import DOMPurify from 'dompurify';
import { useTheme } from 'styled-components';
import { BodyWrapper, PCapitalized } from './styles';

interface CardContainerWithIconProps {
  iconName: string;
  title: string;
  subtitle1?: string;
  subtitle2?: string;
  color?: string;
  backgroundColor?: string;
  boxShadowIcon?: string;
  isLoading: boolean;
  dataTestId?: string;
  rightSideContent?: JSX.Element | null;
  centeredTitle?: boolean;
}

const CardContainerWithIcon = ({
  iconName,
  title,
  subtitle1,
  subtitle2,
  color,
  backgroundColor,
  boxShadowIcon,
  isLoading,
  dataTestId,
  rightSideContent,
  centeredTitle,
}: CardContainerWithIconProps) => {
  const theme = useTheme();

  return (
    <BoxDecoration
      padding="2rem"
      bgColor={theme.color.white}
      borderRadius={theme.borderRadius.radius10}
      width="100%"
      boxShadow="0 0.2rem 0 rgba(0, 0, 0, 0.1)"
      border={`0.1rem solid ${theme.color.grey1}`}
      data-testid={dataTestId}
    >
      {isLoading ? (
        <CardContainerSkeleton />
      ) : (
        <PurchaseSuccessfulCard>
          <CenterItem>
            <IconRounded
              iconStyle={IconRoundedStyles.default}
              iconSize={IconRoundedSizes.large}
              overrideColor={color ?? theme.color.success2}
              overrideBackgroundColor={backgroundColor ?? theme.color.greenOp10}
              iconName={iconName}
              boxShadow={boxShadowIcon}
            />
          </CenterItem>
          <BodyWrapper>
            <Column gap="1rem" margin={centeredTitle ? '2rem 0 0 0' : '0'}>
              <AlignedText>
                <H5 data-testid={`${dataTestId ?? ''}-title`}>{title}</H5>
              </AlignedText>
              <AlignedText>
                <P
                  data-testid={`${dataTestId ?? ''}-subtitle1`}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(subtitle1 ?? ''),
                  }}
                />
              </AlignedText>
              <AlignedText>
                <PCapitalized
                  data-testid={`${dataTestId ?? ''}-subtitle2`}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(subtitle2 ?? ''),
                  }}
                />
              </AlignedText>
            </Column>

            {rightSideContent}
          </BodyWrapper>
        </PurchaseSuccessfulCard>
      )}
    </BoxDecoration>
  );
};

export default CardContainerWithIcon;
