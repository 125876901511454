import styled from 'styled-components';

export const HomeContent = styled.div`
  width: 128rem;
  margin: auto;
  padding-bottom: 4rem;
  background-color: ${props => props.theme.color.white};

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1280}) {
    width: 100%;
    padding: 1rem 1rem 4rem 1rem;
  }
`;
