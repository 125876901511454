import Icon from '@components/Icon';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import {
  ErrorIconWrapper,
  HintComponent,
  HintIconWrapper,
  Message,
  Root,
} from './styles';

interface InputMetaInformationProps {
  hint?: string | JSX.Element;
  error?: string;
  margin?: string;
}

const InputMetaInformation = ({
  hint = '',
  error = '',
  margin = '',
}: InputMetaInformationProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Root margin={margin}>
      {error ? (
        <HintComponent data-testid="input-error">
          <ErrorIconWrapper>
            <Icon name="attention" color={theme.color.error} size="1rem" />
          </ErrorIconWrapper>
          <Message type="error">{t(String(error))}</Message>
        </HintComponent>
      ) : (
        hint && (
          <HintComponent data-testid="input-hint">
            <HintIconWrapper>
              <Icon name="info" color={theme.color.grey2} size="1rem" />
            </HintIconWrapper>
            <Message type="hint">{hint}</Message>
          </HintComponent>
        )
      )}
    </Root>
  );
};

export default InputMetaInformation;
