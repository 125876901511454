import React, { useContext } from 'react';
import { type ConfigContextType } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConfigContext = React.createContext<any>({});

export default ConfigContext;
export * from './ConfigProvider';
export * from './types';

export const useConfigContext = () =>
  useContext<ConfigContextType>(ConfigContext);
