import { useEffect, useMemo } from 'react';

import CardContainerWithIcon from '@components/CardContainerWithIcon';
import { Column } from '@components/LinearLayout';
import { type BookingsFormInput } from '@hubs101/booking-api-skd-client/lib/types';
import AssignTicketsToAttendees from '@pages/OrderDetails/components/AssignTicketsToAttendees';
import CustomerDetails from '@pages/OrderDetails/components/CustomerDetails';
import OrderDetailsPageTitle from '@pages/OrderDetails/components/OrderDetailsPageTitle';
import BookingDetails from '@pages/OrderDetails/components/PublicBookingDetails';
import ShareEvent from '@pages/OrderDetails/components/ShareEvent';
import { MoreTickets } from '@pages/Purchase/components/PaymentDetailsStep/styles';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

const OrderDetails = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const defaultValues: BookingsFormInput = useMemo(
    () => ({
      salutation: '',
      first_name: 'Michael',
      last_name: 'Modern',
      job_title: 'IT developer',
      company_name: '',
      job_level: '',
      job_responsibility: '',
      company_size: '',
      company_industry: '',
      address_country: '',
      contact_email: '',
      type_of_business: '',
      vat_id: '',
      po_number: '',
      invoice_full_name: 'Michael Modern',
      address_street: '',
      additional_details: '',
      address_city: '',
      address_postal_code: '',
      contact_phone: '',
      card_number: '3454234423443423',
      card_holder: 'michael.modern@we-conect.com',
      expiry_date: '',
      cvc: '',
    }),
    []
  );

  const {
    reset,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    return () => {
      reset(defaultValues);
    };
  }, [defaultValues, reset]);

  return (
    <Column gap="2.5rem">
      <OrderDetailsPageTitle />

      <CardContainerWithIcon
        iconName="shopping-cart"
        title={`${t('event')} Automotive Maintenance AI 2022`}
        subtitle1={`${t('date')} 06.10. - 08.10.2022`}
        subtitle2={`${t('venue')} The Raffles Hotel, Berlin, Germany`}
        color={theme.color.black}
        boxShadowIcon="0px 2px 0px rgba(0, 0, 0, 0.1)"
        isLoading={false}
      />
      <CardContainerWithIcon
        iconName="check" /* attention - for not paid */
        title={t('paid')}
        subtitle1={`${t('payment-method')}: Credit card`}
        boxShadowIcon="0px 2px 0px rgba(0, 0, 0, 0.1)"
        isLoading={false}
      />
      <AssignTicketsToAttendees
        control={control}
        errors={errors}
        defaultValues={defaultValues}
        reset={reset}
        getValues={getValues}
      />
      <BookingDetails />
      <CustomerDetails />

      <ShareEvent />
      <MoreTickets
        onClick={() => {
          window.location.href =
            'https://staging-booking.hubs101.com/purchase/smart-variant-con-2023?cu=EUR&la=en';
        }}
      >
        {t('buy-more-tickets')}
      </MoreTickets>
    </Column>
  );
};

export default OrderDetails;
