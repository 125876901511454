import { memo } from 'react';
import styled from 'styled-components';

import { type BaseThemeType } from '@theme/base';
import Icon from '../Icon';
import Notification, {
  NotificationPositions,
  NotificationSizes,
  NotificationStyles,
} from '../Notification';

export interface IconRoundedProps {
  iconStyle: IconRoundedStyles;
  iconSize: IconRoundedSizes;
  iconName: string;
  disabled?: boolean;
  notification?: number;
  notificationSize?: NotificationSizes;
  overrideColor?: string;
  overrideBackgroundColor?: string;
  className?: string;
  rotation?: number;
  onClick?: () => void;
  boldIcon?: boolean;
  hoverColor?: string;
  iconColor?: string;
  leftSideLabel?: JSX.Element | null;
  boxShadow?: string;
}

export enum IconRoundedSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum IconRoundedStyles {
  default = 'default',
  outline = 'outline',
  white = 'white',
}
type IconStyledProps = Pick<
  IconRoundedProps,
  | 'iconSize'
  | 'iconStyle'
  | 'overrideBackgroundColor'
  | 'overrideColor'
  | 'disabled'
  | 'hoverColor'
  | 'boxShadow'
> & {
  theme: BaseThemeType;
};

type PositionedNotificationProps = Pick<
  IconRoundedProps,
  | 'iconSize'
  | 'notificationSize'
  | 'overrideBackgroundColor'
  | 'overrideColor'
  | 'disabled'
> & {
  theme: BaseThemeType;
};
const IconRoundedContainer = styled.span`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${(props: IconStyledProps) => {
    if (props.overrideColor && props.overrideBackgroundColor) {
      if (props.iconSize === IconRoundedSizes.medium) {
        return `
      background-color: ${props.overrideBackgroundColor};
      border: none;
      color: ${props.overrideColor};
      padding: ${props.theme.spacing.size10};
      font-size: ${props.theme.fontSize.size20};
    `;
      }
      if (props.iconSize === IconRoundedSizes.small) {
        return `
background-color: ${props.overrideBackgroundColor};
border: none;
color: ${props.overrideColor};
padding: ${props.theme.spacing.size6};
font-size: ${props.theme.fontSize.size16};
`;
      }
      return `
        background-color: ${props.overrideBackgroundColor};
        border: none;
        color: ${props.overrideColor};
      `;
    }
    if (props.iconSize === IconRoundedSizes.small) {
      return `
        padding: ${props.theme.spacing.size6};
        font-size: ${props.theme.fontSize.size16};
      `;
    }
    if (props.iconSize === IconRoundedSizes.medium) {
      return `
        padding: ${props.theme.spacing.size10};
        font-size: ${props.theme.fontSize.size20};
      `;
    }
    if (props.iconSize === IconRoundedSizes.large) {
      return `
        padding: ${props.theme.spacing.size12};
        font-size: ${props.theme.fontSize.size30};
      `;
    }
  }};
  ${(props: IconStyledProps) => {
    if (props.overrideColor != null && props.overrideBackgroundColor != null) {
      return `
        background-color: ${props.overrideBackgroundColor};
        border: none;
        color: ${props.overrideColor};
      `;
    }
    if (props.iconStyle === IconRoundedStyles.default) {
      return `
        background-color: ${props.theme.color.primary};
        border: none;
        color: ${props.theme.color.white};
      `;
    }
    if (props.iconStyle === IconRoundedStyles.outline) {
      return `
        background-color: ${props.theme.color.white};
        border: ${props.theme.fontSize.size1} solid ${props.theme.color.primary};
        color: ${props.theme.color.primary};
      `;
    }
    if (props.iconStyle === IconRoundedStyles.white) {
      return `
        border: none;
        background-color: ${props.theme.color.white};
        color: ${props.theme.color.greyDark};
      `;
    }
  }}

  cursor: pointer;
  border-radius: ${(props: IconStyledProps) =>
    props.theme.borderRadius.radius50};
  ${(props: IconStyledProps) => {
    if (props.disabled ?? false) {
      return `
        pointer-events: none;
        background-color: ${props.theme.color.greyMedium};
        border: none;
        color: ${props.theme.color.greyLight};
      `;
    }
  }}
  background-color: ${props => props.overrideBackgroundColor};
  &:hover {
    background-color: ${props => props.hoverColor};
  }
  box-shadow: ${props => props.boxShadow};

  ${(props: IconStyledProps) => {
    if (props.iconSize === IconRoundedSizes.large) {
      return `
      padding: ${props.theme.spacing.size12};
      font-size: ${props.theme.fontSize.size30};

      `;
    }
  }}
`;

const PositionedNotification = styled(Notification)`
  ${(props: PositionedNotificationProps) => {
    if (
      props.iconSize === IconRoundedSizes.medium &&
      (props.notificationSize === NotificationSizes.large ||
        props.notificationSize === NotificationSizes.medium)
    ) {
      return `top: -${props.theme.spacing.size4}; left: ${props.theme.spacing.size23}`;
    }
  }}
`;

const LeftSideLabelWrapper = styled.div`
  margin: 0 0.6rem;
`;

const IconRounded = ({
  iconStyle = IconRoundedStyles.default,
  iconSize = IconRoundedSizes.medium,
  overrideColor,
  overrideBackgroundColor,
  iconName,
  notification = 0,
  notificationSize = NotificationSizes.medium,
  disabled,
  className,
  rotation,
  onClick,
  boldIcon,
  hoverColor,
  iconColor,
  leftSideLabel,
  boxShadow,
}: IconRoundedProps) => {
  return (
    <IconRoundedContainer
      data-testid="components:icon-rounded"
      iconStyle={iconStyle}
      iconSize={iconSize}
      disabled={disabled}
      overrideColor={overrideColor}
      overrideBackgroundColor={overrideBackgroundColor}
      hoverColor={hoverColor}
      className={className}
      boxShadow={boxShadow}
      onClick={onClick}
    >
      {leftSideLabel && (
        <LeftSideLabelWrapper>{leftSideLabel}</LeftSideLabelWrapper>
      )}
      {notification > 0 &&
        iconSize === IconRoundedSizes.medium &&
        notificationSize !== NotificationSizes.small && (
          <PositionedNotification
            notification={notification}
            iconSize={iconSize}
            notificationStyle={NotificationStyles.orange}
            notificationPosition={NotificationPositions.absolute}
            notificationSize={notificationSize}
          />
        )}
      <Icon
        name={iconName}
        rotation={rotation}
        disabled={disabled}
        bold={boldIcon}
      />
    </IconRoundedContainer>
  );
};

export default memo(IconRounded);
