import IconRounded, {
  IconRoundedSizes,
  IconRoundedStyles,
} from '@components/IconRounded';
import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

const ScrollToTopWrapper = styled.span`
  position: fixed;
  bottom: 7rem;
  right: 4rem;
  cursor: pointer;
`;

const ScrollToTop = () => {
  const theme = useTheme();
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 200) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
      {showTopBtn && (
        <ScrollToTopWrapper>
          <IconRounded
            iconStyle={IconRoundedStyles.default}
            iconSize={IconRoundedSizes.large}
            iconName="arrow-up"
            onClick={goToTop}
            hoverColor={theme.color.primary}
            overrideBackgroundColor={theme.color.greyMedium}
          />
        </ScrollToTopWrapper>
      )}
    </>
  );
};
export default ScrollToTop;
