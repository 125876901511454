import { lazy } from 'react';

export const ROUTES = {
  PURCHASE: 'PURCHASE',
  ORDER_DETAILS: 'ORDER_DETAILS',
  PUBLIC_ORDER_DETAILS: 'PUBLIC_ORDER_DETAILS',
} as const;

export const Home = lazy(async () => await import('@pages/Home'));
export const Bookings = lazy(async () => await import('@pages/Purchase'));
export const NotFound = lazy(async () => await import('@pages/NotFound'));
