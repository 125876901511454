import { type DefaultTheme } from 'styled-components';

import { common } from './common';

const dark: DefaultTheme = {
  ...common,
  color: {
    primary: '#148ABB',
    white: '#FFFFFF',
    black: '#000000',
    grey1: 'rgba(221, 223, 226)', // #dddfe2
    grey2: '#e5e6e8',
    grey3: '#ffdddd',
    divider: '#F5F5F7',
    success: '#00e52e',
    success2: '#7FBA7A',
    warning: '#f5d924',
    error: '#F16C4E',
    toastDefault: 'rgba(201, 221, 238, 0.8)',
    pending: '#FFCE73',
    textActive: '#4C5667',
    transparent: 'transparent',
    inputGrey: 'rgba(118, 118, 128, 0.12)',
    backgroundColor: '#121212',
    headerBackgroundColor: '#192232',
    titleColor: '#fafafa',
    lightest: '#FAFAFB',
    lightDarkest: '#364154',
    darkest: ' #192232',
    hoverScrollBar: '#939393',
    scrollBar: '#6b6b6b',
    scrollBarBackground: '#2d2d2d',
    componentBackground: '#121212',
    primaryText: '#E4E6EB',
    greyLight: 'rgb(245,245,247)', // '#F5F5F7'
    greyMedium: 'rgb(209, 213, 219)', // #D1D5DB
    greyDark: 'rgb(76,86,103)', // '#4C5667'
    redSoft: 'rgba(241, 108, 78, 0.15)',
    redHard: 'rgba(241, 108, 78, 0.5)',
    orange: '#F16C4E',
    orangeSoft: 'rgba(255, 206, 115, 0.1)',
    green: 'rgb(127,186,122)', // #7FBA7A
    greenOp10: 'rgb(127,186,122, 0.1)',
    greenLight: '#BCEEB7',
    greenSoft: 'rgba(20, 138, 187, 0.1)',
    greyMediumDark: 'rgba(178, 179, 189)', // #B2B3B
    greyMediumDarkOp20: 'rgba(178, 179, 189, 0.2)',
    blackSoft: 'rgba(0, 0, 0, 0.07)',
    charcoal: '#333333',
    lightLush: 'rgba(253, 253, 253)', // #fdfdfd
    darkOrange: 'rgba(255, 206, 115, 1)',
  },
  type: 'dark',
} as const;

export default dark;
