import { BoxDecoration } from '@components/BoxDecoration';
import { Column } from '@components/LinearLayout';
import { PBold } from '@components/Typography';
import { type ReactNode } from 'react';
import { useTheme } from 'styled-components';
import { Header } from './styles';

interface TicketSummaryProps {
  title?: string | null;
  price?: string | null;
  children?: ReactNode;
}
const TicketSummary = ({ title, price, children }: TicketSummaryProps) => {
  const theme = useTheme();
  return (
    <BoxDecoration
      padding="1rem 1.5rem"
      bgColor={theme.color.white}
      borderRadius={theme.borderRadius.radius5}
      width="100%"
      border={`0.1rem solid ${theme.color.grey1}`}
    >
      <Column gap="0.5rem">
        <Header>
          <PBold>{title}</PBold>
          <PBold>{price}</PBold>
        </Header>
        {children}
      </Column>
    </BoxDecoration>
  );
};

export default TicketSummary;
