import ConfigProvider from '@context/config/ConfigProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import ThemeProvider from './ThemeProvider';
const queryClient = new QueryClient();

interface ProvidersProps {
  children: React.ReactNode;
  theme: string;
}

function Providers(props: ProvidersProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider>
        <ThemeProvider theme={props.theme}>{props.children}</ThemeProvider>
      </ConfigProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default Providers;
