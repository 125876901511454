import BoxDecoration from '@components/BoxDecoration';
import { Column } from '@components/LinearLayout';
import { LineSkeleton, SquareSkeleton } from '@components/SkeletonsLoaders';
import { P, PSmall } from '@components/Typography';
import { type EventType } from '@hubs101/booking-api-skd-client/lib/types';
import { publicMediaUrl } from '@utils/fn';
import { formatDateTimeZoned } from '@utils/time';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import {
  DateItem,
  DatesContainer,
  DateSubtitle,
  Divider,
  EventDetailsContainer,
  LogoContainer,
  LogoImg,
  LogoTitleWrapper,
  NameContainer,
  TitleWrapper,
} from './styles';

export interface EventDetailsProps {
  event: EventType | undefined;
  loading: boolean;
  error: string | undefined | null;
}

const EventDetails = ({ event, loading, error }: EventDetailsProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const imageUrl = publicMediaUrl(event?.images?.logo);

  const location = [
    event?.location?.name,
    event?.location?.marker?.street,
    event?.location?.marker?.postalCode,
    event?.location?.marker?.city,
    event?.location?.marker?.state,
    event?.location?.marker?.country
      ? t(`countries.${event?.location?.marker?.country ?? ''}`)
      : '',
  ]
    .filter(locationEntry => locationEntry)
    .join(', ');

  return (
    <BoxDecoration
      padding="0 2rem 0 1rem"
      bgColor={theme.color.white}
      borderRadius={theme.borderRadius.radius10}
      width="100%"
      boxShadow="0 0.2rem 0 rgba(0, 0, 0, 0.1)"
      border={`0.1rem solid ${theme.color.grey1}`}
    >
      <EventDetailsContainer>
        <LogoTitleWrapper>
          <LogoContainer>
            {loading || error ? (
              <SquareSkeleton width="12rem" height="3.5rem" />
            ) : (
              <LogoImg
                data-testid="tickets:eventLogo"
                src={imageUrl}
                alt="event logo"
              />
            )}
          </LogoContainer>
          <TitleWrapper>
            {loading || error ? (
              <Column gap="0.5rem">
                <LineSkeleton width="14rem" height="1.8rem" />
                <LineSkeleton width="6rem" height="1.8rem" />
              </Column>
            ) : (
              <NameContainer>
                <P data-testid="tickets:eventTitle">{event?.name},</P>
                <P data-testid="tickets:eventLocation">{location}</P>
              </NameContainer>
            )}
          </TitleWrapper>
        </LogoTitleWrapper>
        <DatesContainer>
          {loading || error ? (
            <Column gap="0.5rem">
              <LineSkeleton width="5rem" height="1.8rem" />
              <LineSkeleton width="10rem" height="1.8rem" />
            </Column>
          ) : (
            <DateItem>
              <DateSubtitle>{t('from')}</DateSubtitle>
              <PSmall>
                {formatDateTimeZoned(event?.start_date ?? '', event?.timezone)}
              </PSmall>
            </DateItem>
          )}

          <Divider />
          {loading || error ? (
            <Column gap="0.5rem">
              <LineSkeleton width="5rem" height="1.8rem" />
              <LineSkeleton width="10rem" height="1.8rem" />
            </Column>
          ) : (
            <DateItem>
              <DateSubtitle>{t('until')}</DateSubtitle>
              <PSmall>
                {formatDateTimeZoned(event?.end_date ?? '', event?.timezone)}
              </PSmall>
            </DateItem>
          )}
        </DatesContainer>
      </EventDetailsContainer>
    </BoxDecoration>
  );
};

export default EventDetails;
