import { Column } from '@components/LinearLayout';
import NotFound from '@pages/NotFound';
import { useHookParams } from '@utils/hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import BookingForm from './components/BookingForm';
import { BookingRoot, TitleWrapper } from './styles';

const Purchase = () => {
  const { t } = useTranslation();
  const { eventSlug } = useParams();
  const [type] = useHookParams(['ty']);
  const isIframe = useMemo(() => type === 'iframe', [type]);

  if (!eventSlug) {
    return <NotFound />;
  }

  return (
    <BookingRoot>
      <Column gap="2rem">
        {!isIframe && <TitleWrapper>{t('tickets')}</TitleWrapper>}
        <BookingForm eventSlug={eventSlug} />
      </Column>
    </BookingRoot>
  );
};

export default Purchase;
