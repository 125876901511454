import { LineSkeleton } from '@components/SkeletonsLoaders';
import { randomNumberBetweenMinMax } from '@utils/randomNumberBetweenMinMax';

const CustomerDetailsSkeleton = () => {
  return (
    <>
      <LineSkeleton
        width={`${randomNumberBetweenMinMax(20, 50)}%`}
        height="2rem"
        margin="0 0 1rem"
      />
      <LineSkeleton
        width={`${randomNumberBetweenMinMax(20, 50)}%`}
        height="2rem"
        margin="0 0 2rem"
      />

      <LineSkeleton
        width={`${randomNumberBetweenMinMax(20, 50)}%`}
        height="2rem"
        margin="0 0 1rem"
      />
      <LineSkeleton
        width={`${randomNumberBetweenMinMax(20, 50)}%`}
        height="2rem"
        margin="0 0 2rem"
      />

      <LineSkeleton
        width={`${randomNumberBetweenMinMax(20, 50)}%`}
        height="2rem"
        margin="0 0 1rem"
      />
      <LineSkeleton
        width={`${randomNumberBetweenMinMax(20, 50)}%`}
        height="2rem"
      />
    </>
  );
};

export default CustomerDetailsSkeleton;
