import styled from 'styled-components';

export const Badge = styled.div`
  font-size: ${props => props.theme.fontSize.size12};
  color: ${props => props.theme.color.white};
  background-color: ${props => props.theme.color.error};
  width: 1.9rem;
  height: 1.9rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.1rem;
  margin-left: 0.5rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  border-radius: ${props => props.theme.borderRadius.radius20};
  background-color: ${props => props.theme.color.white};
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    display: flex;
    flex-direction: column;
  }
`;

export const Button = styled.button<{
  isSelected?: boolean;
}>`
  display: flex;
  align-items: center;
  background-color: ${props =>
    props.isSelected ? props.theme.color.primary : props.theme.color.white};
  color: ${props =>
    props.isSelected ? props.theme.color.white : props.theme.color.primary};
  border-radius: ${props => props.theme.borderRadius.radius20};
  font-size: ${props => props.theme.fontSize.size14};
  font-weight: ${props => props.theme.fontWeight.weight500};
  font-family: ${props => props.theme.font.body};
  line-height: ${props => props.theme.lineHeight.height125};
  height: 3rem;
  padding: 0 2rem;
  transition: all ease-in-out 0.2s;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-color: transparent;
`;
