import { useTheme } from 'styled-components';
import { BoxDecoration } from '@components/BoxDecoration';
import Icon from '@components/Icon';
import { RowAlignedItems } from '@components/LinearLayout';
import { DescriptionText } from '@components/ErrorSection/styles';
import { useEffect, useState } from 'react';
import { scrollToElement } from '@utils/scrollToElement';
import { useTranslation } from 'react-i18next';

interface ErrorSectionProps {
  description?: string;
}

const ErrorSection = ({ description: descriptionProp }: ErrorSectionProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [description, setDescription] = useState<string>(descriptionProp ?? '');
  const offlineDescription = t('you-are-offline');

  useEffect(() => {
    setDescription(descriptionProp ?? '');
  }, [descriptionProp]);

  useEffect(() => {
    if (description && document) {
      const element = document.getElementById('error-section');
      scrollToElement(element);
    }
  }, [description]);

  const handleCloseErrorSection = () => {
    setDescription('');
  };

  if (!description) {
    return null;
  }

  return (
    <div id="error-section" data-testid="error-section:container">
      <BoxDecoration
        padding="1.25rem 1.25rem 1.25rem 2.5rem"
        bgColor={theme.color.redSoft}
        borderRadius={theme.borderRadius.radius10}
        width="100%"
        display="flex"
        minHeight="6.5rem"
        alignItems="center"
        border={`0.1rem solid ${theme.color.redHard}`}
      >
        <RowAlignedItems gap="2.5rem">
          <Icon
            size="2.25rem"
            name={'close'}
            color={theme.color.error}
            onClick={handleCloseErrorSection}
          />
          <DescriptionText
            color={theme.color.error}
            data-testid="error-section:text"
          >
            {navigator.onLine ? description : offlineDescription}
          </DescriptionText>
        </RowAlignedItems>
      </BoxDecoration>
    </div>
  );
};

export default ErrorSection;
