import Icon from '@components/Icon';
import React, { type ForwardedRef, forwardRef } from 'react';
import styled, { useTheme } from 'styled-components';

export interface InputProps {
  value?: string;
  placeholder?: string;
  iconName?: string;
  iconAlign?: 'left' | 'right';
  backgroundColor?: string;
  background?: string;
  color?: string;
  padding?: string;
  borderRadius?: string;
  border?: string;
  textAlign?: string;
  disabled?: boolean;
  height?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (() => void) | undefined;
  dataTestId?: string;
}

const InputComponents = styled.div<
  Pick<
    InputProps,
    | 'backgroundColor'
    | 'background'
    | 'padding'
    | 'iconAlign'
    | 'borderRadius'
    | 'border'
    | 'height'
    | 'disabled'
  >
>`
  width: 100%;
  height: ${props => props.height ?? '100%'};
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-direction: ${props => {
    if (props.iconAlign === 'right') return 'row-reverse';
    return 'row';
  }};
  border-radius: ${props => props.borderRadius};
  overflow: hidden;
  padding: ${props => props.padding};
  border: ${props => props.border};
  background: ${props => props.background};
  background-color: ${props => {
    if (props.disabled) return props.theme.color.inputGrey;
    return props.backgroundColor;
  }};
`;

const InputComponent = styled.input<
  Pick<InputProps, 'disabled' | 'textAlign' | 'placeholder'>
>`
  width: 100%;
  align-self: center;
  border-color: ${props => props.theme.color.transparent};
  color: ${props => props.theme.color.textActive};
  background-color: ${props => {
    if (props.disabled) return props.theme.color.transparent;
    return props.theme.color.transparent;
  }};
  ${({ textAlign }) => textAlign != null && `text-align: ${textAlign};`}
  &::placeholder {
    color: ${props => {
      return props.theme.color.grey2;
    }};
  }
`;

const Input = forwardRef(
  (props: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const theme = useTheme();
    return (
      <>
        <InputComponents
          background={props.background}
          backgroundColor={props.backgroundColor}
          padding={props.padding}
          iconAlign={props.iconAlign}
          borderRadius={props.borderRadius}
          border={props.border}
          height={props.height}
          disabled={props.disabled}
        >
          <InputComponent
            data-testid={
              props.dataTestId ? props.dataTestId : 'components:input'
            }
            ref={ref}
            onChange={props?.onChange}
            onBlur={props?.onBlur}
            onKeyDown={props?.onKeyDown}
            textAlign={props?.textAlign}
            disabled={props?.disabled}
            value={props?.value}
            placeholder={props?.placeholder}
          />
          {props.iconName != null && (
            <Icon
              name={props.iconName}
              color={theme.color.success2}
              size="2.2rem"
            />
          )}
        </InputComponents>
      </>
    );
  }
);

Input.displayName = 'Input';

export default Input;
