import { useSetState } from '@utils/hooks';
import { memo, useMemo } from 'react';
import ConfigContext from '.';

const initialState = Object.freeze({ isMenuOpen: false });

function ConfigProvider(props: { children: React.ReactNode }) {
  const [state] = useSetState(initialState);

  const value = useMemo(
    () => ({
      ...state,
    }),
    [state]
  );

  return (
    <ConfigContext.Provider value={value}>
      {props.children}
    </ConfigContext.Provider>
  );
}

export default memo(ConfigProvider);
