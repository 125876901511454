import { RowAlignedItems } from '@components/LinearLayout';
import styled from 'styled-components';

export const EditButton = styled(RowAlignedItems)`
  cursor: pointer;
  &:active {
    opacity: 0.4;
  }
`;

export const VatText = styled.div`
  text-transform: uppercase;
  margin-top: 1rem;
  gap: 0.5rem;
  display: flex;
`;

export const BoxTicketPriceContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.5rem;
`;
