import { LineSkeleton } from '@components/SkeletonsLoaders';

const CardContainerSkeleton = () => {
  return (
    <>
      <LineSkeleton width="100%" height="3rem" margin="0 0 1rem" />
      <LineSkeleton width="100%" height="2rem" margin="0 0 1rem" />
      <LineSkeleton width="100%" height="2rem" />
    </>
  );
};

export default CardContainerSkeleton;
