import {
  emailValidationRule,
  getMinLengthRule,
  getMinMaxLengthRules,
  postalCodeValidationRule,
  requiredValidationRule,
} from '@utils/form';
import { useEffect, useState } from 'react';
import {
  type Control,
  Controller,
  type FieldErrorsImpl,
  type UseFormGetValues,
  type UseFormSetValue,
  type UseFormTrigger,
} from 'react-hook-form';
import { FormItemsWrapper, FormWrapper } from './styles';

import Input from '@components/Input';
import InputSelect from '@components/InputSelect';
import PhoneNumberInput from '@components/PhoneInput';
import { PSmall } from '@components/Typography';
import FormItem from '@composites/FormItem';
import { type BookingsFormInput } from '@hubs101/booking-api-skd-client/lib/types';
import { countriesSelectOptions } from '@utils/mappings';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface CustomerDetailsFormProps {
  control: Control<BookingsFormInput>;
  errors: Partial<FieldErrorsImpl<BookingsFormInput>>;
  trigger: UseFormTrigger<BookingsFormInput>;
  mutateBookingsCheck?: () => void;
  setValue: UseFormSetValue<BookingsFormInput>;
  getValues: UseFormGetValues<BookingsFormInput>;
}

export const CustomerDetailsForm = ({
  control,
  errors,
  trigger,
  mutateBookingsCheck,
  setValue,
  getValues,
}: CustomerDetailsFormProps) => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const theme = useTheme();

  useEffect(() => {
    // Any of the following fields are filled, trigger validation on language change
    if (currentLanguage && currentLanguage !== i18n.language) {
      if (getValues('first_name')?.length > 0) void trigger('first_name');
      if (getValues('last_name')?.length > 0) void trigger('last_name');
      if (getValues('company_name')?.length > 0) void trigger('company_name');
      if (getValues('address_street')?.length > 0) {
        void trigger('address_street');
      }
      if (getValues('additional_details')?.length > 0) {
        void trigger('additional_details');
      }
      if (getValues('address_postal_code')?.length > 0) {
        void trigger('address_postal_code');
      }
      if (getValues('address_city')?.length > 0) void trigger('address_city');
      if (getValues('address_country')?.length > 0) {
        void trigger('address_country');
      }
      if (getValues('vat_id')?.length > 0) void trigger('vat_id');
      if (getValues('po_number')?.length > 0) void trigger('po_number');
      setCurrentLanguage(i18n.language);
    } else {
      setCurrentLanguage(i18n.language);
    }
  }, [currentLanguage, getValues, i18n.language, trigger]);

  return (
    <FormWrapper>
      <FormItemsWrapper>
        <FormItem
          label={t('first_name')}
          error={errors.first_name?.message}
          required
        >
          <Controller
            control={control}
            name="first_name"
            rules={{
              ...requiredValidationRule,
              ...getMinMaxLengthRules(2, 255, String(t('first_name'))),
            }}
            render={({ field }) => (
              <Input
                dataTestId={'customer-form:first-name'}
                iconAlign="left"
                {...field}
                backgroundColor={theme.color.lightLush}
                borderRadius={theme.borderRadius.radius50}
                border={`0.1rem solid ${theme.color.grey1}`}
                padding="0 1rem"
                height="4rem"
              />
            )}
          />
        </FormItem>

        <FormItem
          label={t('last_name')}
          error={errors.last_name?.message}
          required
        >
          <Controller
            control={control}
            name="last_name"
            rules={{
              ...requiredValidationRule,
              ...getMinMaxLengthRules(2, 255, String(t('last_name'))),
            }}
            render={({ field }) => (
              <Input
                dataTestId={'customer-form:last-name'}
                iconAlign="left"
                {...field}
                backgroundColor={theme.color.lightLush}
                borderRadius={theme.borderRadius.radius50}
                border={`0.1rem solid ${theme.color.grey1}`}
                padding="0 1rem"
                height="4rem"
              />
            )}
          />
        </FormItem>
        <FormItem
          label={t('email')}
          error={errors.contact_email?.message}
          required
        >
          <Controller
            control={control}
            name="contact_email"
            rules={{
              ...requiredValidationRule,
              ...emailValidationRule,
            }}
            render={({ field }) => (
              <Input
                dataTestId={'customer-form:email'}
                iconAlign="left"
                {...field}
                backgroundColor={theme.color.lightLush}
                borderRadius={theme.borderRadius.radius50}
                border={`0.1rem solid ${theme.color.grey1}`}
                padding="0 1rem"
                height="4rem"
              />
            )}
          />
        </FormItem>
        <FormItem
          label={t('company_name')}
          error={errors.company_name?.message}
          required
        >
          <Controller
            control={control}
            name="company_name"
            rules={{
              ...requiredValidationRule,
              ...getMinMaxLengthRules(3, 255, String(t('company_name'))),
            }}
            render={({ field }) => (
              <Input
                dataTestId={'customer-form:company-name'}
                iconAlign="left"
                {...field}
                backgroundColor={theme.color.lightLush}
                borderRadius={theme.borderRadius.radius50}
                border={`0.1rem solid ${theme.color.grey1}`}
                padding="0 1rem"
                height="4rem"
              />
            )}
          />
        </FormItem>

        <FormItem
          label={t('invoice_address_street')}
          error={errors.address_street?.message}
          required
        >
          <Controller
            control={control}
            name="address_street"
            rules={{
              ...requiredValidationRule,
              ...getMinMaxLengthRules(
                3,
                255,
                String(t('invoice_address_street'))
              ),
            }}
            render={({ field }) => (
              <Input
                dataTestId={'customer-form:street-address'}
                iconAlign="left"
                {...field}
                backgroundColor={theme.color.lightLush}
                borderRadius={theme.borderRadius.radius50}
                border={`0.1rem solid ${theme.color.grey1}`}
                padding="0 1rem"
                height="4rem"
              />
            )}
          />
        </FormItem>
        <FormItem
          label={t('invoice_address_additional')}
          error={errors.additional_details?.message}
        >
          <Controller
            control={control}
            name="additional_details"
            rules={{
              ...getMinMaxLengthRules(
                3,
                255,
                String(t('invoice_address_additional'))
              ),
            }}
            render={({ field }) => (
              <Input
                dataTestId={'customer-form:additional-details'}
                iconAlign="left"
                {...field}
                backgroundColor={theme.color.lightLush}
                borderRadius={theme.borderRadius.radius50}
                border={`0.1rem solid ${theme.color.grey1}`}
                padding="0 1rem"
                height="4rem"
              />
            )}
          />
        </FormItem>

        <FormItem
          label={t('invoice_address_postal_code')}
          error={errors.address_postal_code?.message}
          required
        >
          <Controller
            control={control}
            name="address_postal_code"
            rules={{
              ...requiredValidationRule,
              ...getMinMaxLengthRules(
                3,
                255,
                String(t('invoice_address_postal_code'))
              ),
              ...postalCodeValidationRule,
            }}
            render={({ field }) => (
              <Input
                dataTestId={'customer-form:postal-code'}
                iconAlign="left"
                {...field}
                backgroundColor={theme.color.lightLush}
                borderRadius={theme.borderRadius.radius50}
                border={`0.1rem solid ${theme.color.grey1}`}
                padding="0 1rem"
                height="4rem"
              />
            )}
          />
        </FormItem>
        <FormItem
          label={t('invoice_address_city')}
          error={errors.address_city?.message}
          required
        >
          <Controller
            control={control}
            name="address_city"
            rules={{
              ...requiredValidationRule,
              ...getMinMaxLengthRules(
                3,
                255,
                String(t('invoice_address_city'))
              ),
            }}
            render={({ field }) => (
              <Input
                dataTestId={'customer-form:city'}
                iconAlign="left"
                {...field}
                backgroundColor={theme.color.lightLush}
                borderRadius={theme.borderRadius.radius50}
                border={`0.1rem solid ${theme.color.grey1}`}
                padding="0 1rem"
                height="4rem"
              />
            )}
          />
        </FormItem>

        <FormItem
          dataTestId="customer-form:country"
          label={t('address_country')}
          error={errors.address_country?.message}
          required
        >
          <Controller
            control={control}
            name="address_country"
            rules={{ ...requiredValidationRule }}
            render={({ field }) => {
              return (
                <InputSelect
                  borderRadius={theme.borderRadius.radius20}
                  color={theme.color.textActive}
                  fontSize={theme.fontSize.size12}
                  options={countriesSelectOptions}
                  placeholder={String(t('address_country'))}
                  {...field}
                  onChange={e => {
                    field.onChange(e);
                    mutateBookingsCheck?.();
                  }}
                />
              );
            }}
          />
        </FormItem>

        <FormItem
          label={t('contact_phone')}
          error={errors.contact_phone?.message}
          required
        >
          <PhoneNumberInput
            name="contact_phone"
            defaultValue=""
            control={control}
            errors={errors}
            setValue={setValue}
            placeholder={t('placeholder-phone') ?? ''}
          />
        </FormItem>

        <FormItem label={t('vat_number')} error={errors.vat_id?.message}>
          <Controller
            control={control}
            name="vat_id"
            rules={{
              ...getMinLengthRule(10, String(t('vat_number'))),
            }}
            render={({ field }) => (
              <Input
                dataTestId={'customer-form:vat-number'}
                iconAlign="left"
                {...field}
                backgroundColor={theme.color.lightLush}
                borderRadius={theme.borderRadius.radius50}
                border={`0.1rem solid ${theme.color.grey1}`}
                padding="0 1rem"
                height="4rem"
              />
            )}
          />
        </FormItem>

        <FormItem label={t('po_number')} error={errors.po_number?.message}>
          <Controller
            control={control}
            name="po_number"
            rules={{
              ...getMinMaxLengthRules(3, 255, String(t('po_number'))),
            }}
            render={({ field }) => (
              <Input
                dataTestId={'customer-form:po-number'}
                iconAlign="left"
                {...field}
                backgroundColor={theme.color.lightLush}
                borderRadius={theme.borderRadius.radius50}
                border={`0.1rem solid ${theme.color.grey1}`}
                padding="0 1rem"
                height="4rem"
              />
            )}
          />
        </FormItem>
      </FormItemsWrapper>
      <PSmall>*{t('required')}</PSmall>
    </FormWrapper>
  );
};

export default CustomerDetailsForm;
