import { PBold } from '@components/Typography';
import styled from 'styled-components';

export const PBoldWithBottomMargin = styled(PBold)<{
  withBottomMargin?: boolean;
}>`
  margin: 0 0 ${props => (props.withBottomMargin ? '1rem' : '0rem')} 0;
`;

export const AlignedText = styled.div`
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    text-align: center;
  }
`;

export const AlignedRightText = styled.div`
  width: 100%;
  justify-content: flex-end;
  display: flex;
`;

export const AssignTicketText = styled.a`
  font-family: ${props => props.theme.font.body};
  font-size: ${props => props.theme.fontSize.size15};
  font-weight: ${props => props.theme.fontWeight.weight400};
  line-height: ${props => props.theme.lineHeight.height150};
  color: ${props => props.theme.color.primaryText};
  text-decoration: underline;
  cursor: pointer;
`;
