import i18n from 'i18next';

// helper functions:

const generateMappings = <T extends string>(
  keysArray: T[],
  localizationPrefix?: string,
  predefinedMapping?: { [key in T]: string }
) => {
  const keysEnum = keysArray.reduce((a, c) => ({ ...a, [c]: c }), {}) as {
    [key in T]: key;
  };

  const mapping = keysArray.reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: i18n.t(`${localizationPrefix ?? ''}${cur}`),
    }),
    {}
  ) as { [key in T]: string };

  const dropdownOptions = keysArray.map(
    key => ({ key, label: mapping[key] }) as const
  );

  const selectOptions = keysArray.map(
    key => ({ value: key, label: mapping[key] }) as const
  );

  const getLocalizedString = (key: T, fallback = ''): string => {
    if (key?.length === 0) return fallback;
    return mapping[key] ?? fallback;
  };

  return [
    keysEnum,
    keysArray,
    mapping,
    dropdownOptions,
    selectOptions,
    getLocalizedString,
  ] as const;
};

export const [
  countryKeys,
  countryKeysArray,
  countriesMapping,
  countriesDropdownOptions,
  countriesSelectOptions,
  getLocalizedCountry,
] = generateMappings(
  [
    '',
    'austria',
    'germany',
    'france',
    'switzerland',
    'united_kingdom',
    'usa',
    'afghanistan',
    'albania',
    'algeria',
    'american_samoa',
    'andorra',
    'angolla',
    'anguilla',
    'antarctica',
    'antigua',
    'argentina',
    'armenia',
    'arcticocean',
    'aruba',
    'ashmore_and_cartier_islands',
    'atlantic_ocean',
    'australia',
    'azerbaijan',
    'bahamas',
    'bahrain',
    'baker_island',
    'bangladesh',
    'barbados',
    'bassas_da_india',
    'belarus',
    'belgium',
    'belize',
    'benin',
    'bermuda',
    'bhutan',
    'bolivia',
    'bosnia',
    'botswana',
    'bouvet_island',
    'brazil',
    'british_virgin_islands',
    'brunei',
    'bulgaria',
    'burkuina_faso',
    'burundi',
    'cambodia',
    'cameroon',
    'canada',
    'cape_verde_islands',
    'cayman_islands',
    'central_african_republic',
    'chad',
    'chile',
    'china',
    'christmas_island',
    'clipperton_island',
    'cocos_islands',
    'colombia',
    'comoros',
    'cook_islands',
    'coral_sea_islands',
    'costa_rica',
    'cotedivoire',
    'croatia',
    'cuba',
    'cyprus',
    'czech_republic',
    'denmark',
    'democratic_republic_of_congo',
    'djibouti',
    'dominica',
    'dominican_republic',
    'east_timor',
    'ecuador',
    'egypt',
    'el_salvador',
    'equatorial_guinea',
    'eritrea',
    'estonia',
    'ethiopia',
    'europaisland',
    'falkland_islands',
    'faroe_islands',
    'fiji',
    'finland',
    'french_guiana',
    'french_polynesia',
    'french_southern_and_antarctic_lands',
    'gabon',
    'gambia',
    'gaza_strip',
    'georgia',
    'ghana',
    'gibraltar',
    'glorioso_islands',
    'greece',
    'greenland',
    'grenada',
    'guadeloupe',
    'guam',
    'guatemala',
    'guernsey',
    'guinea',
    'guinea_bissau',
    'guyana',
    'haiti',
    'heard_island_and_mcdonald_islands',
    'honduras',
    'hong_kong',
    'howland_island',
    'hungary',
    'iceland',
    'india',
    'indian_ocean',
    'indonesia',
    'iran',
    'iraq',
    'ireland',
    'isle_of_man',
    'israel',
    'italy',
    'jamaica',
    'jan_mayen',
    'japan',
    'jarvisiland',
    'jersey',
    'johnston_atoll',
    'jordan',
    'juan_de_nova_island',
    'kazakhstan',
    'kenya',
    'kingman_reef',
    'kiribati',
    'kerguelen_archipelago',
    'kosovo',
    'kuwait',
    'kyrgyzstan',
    'laos',
    'latvia',
    'lebanon',
    'lesotho',
    'liberia',
    'libya',
    'liechtenstein',
    'lithuania',
    'luxembourg',
    'macau',
    'macedonia',
    'madagascar',
    'malawi',
    'malaysia',
    'maldives',
    'mali',
    'malta',
    'marshall_islands',
    'martinique',
    'mauritania',
    'mauritius',
    'mayotte',
    'mexico',
    'micronesia',
    'midway_islands',
    'moldova',
    'monaco',
    'mongolia',
    'montenegro',
    'montserrat',
    'morocco',
    'mozambique',
    'myanmar',
    'namibia',
    'nauru',
    'navassa_island',
    'nepal',
    'netherlands',
    'netherlands_antilles',
    'new_caledonia',
    'new_zealand',
    'nicaragua',
    'niger',
    'nigeria',
    'niue',
    'norfolk_island',
    'north_korea',
    'north_sea',
    'northern_mariana_islands',
    'norway',
    'oman',
    'pacific_ocean',
    'pakistan',
    'palau',
    'palmyra_atoll',
    'panama',
    'papa_new_guinea',
    'paracel_islands',
    'paraguay',
    'peru',
    'philippines',
    'pitcairn_islands',
    'poland',
    'portugal',
    'puerto_rico',
    'qatar',
    'reunion',
    'republic_of_the_congo',
    'romania',
    'russia',
    'rwanda',
    'saint_helena',
    'saint_kitts_and_nevis',
    'saint_lucia',
    'saint_pierre_and_miquelon',
    'saint_vincent_and_the_grenadines',
    'samoa',
    'san_marino',
    'sao_tome_and_principe',
    'saudi_arabia',
    'senegal',
    'serbia',
    'seychelles',
    'sierra_leone',
    'singapore',
    'slovakia',
    'slovenia',
    'solomon_islands',
    'somalia',
    'south_africa',
    'south_georgia_and_the_south_sandwich_islands',
    'south_korea',
    'spain',
    'spratly_islands',
    'sri_lanka',
    'sudan',
    'suriname',
    'svalbard',
    'swaziland',
    'sweden',
    'syria',
    'taiwan',
    'tajikistan',
    'tanzania',
    'thailand',
    'togo',
    'tokelau',
    'tonga',
    'trinidad',
    'trommelin_island',
    'tunisia',
    'turkey',
    'turkmenistan',
    'turks_and_caicos_islands',
    'tuvalu',
    'uganda',
    'ukraine',
    'united_arab_emirates',
    'uruguay',
    'uzbekistan',
    'vanuatu',
    'venezuela',
    'vietnam',
    'wake_island',
    'wallis_and_futuna',
    'west_bank',
    'western_sahara',
    'yemen',
    'yugoslavia',
    'zambia',
    'zimbabwe',
  ],
  'countries.'
);

export const [
  industryKeys,
  industryKeysArray,
  industriesMapping,
  industriesDropdownOptions,
  industriesSelectOptions,
  getLocalizedIndustry,
] = generateMappings(
  [
    '',
    'academia',
    'aerospacedefense',
    'apparel',
    'associations',
    'automotive',
    'banking',
    'biotechnology',
    'chemicals',
    'communications',
    'construction',
    'consumergoods',
    'consulting',
    'ecommerce',
    'education',
    'electronics',
    'energy',
    'engineering',
    'entertainment',
    'environmental',
    'finance',
    'foodbeverages',
    'government',
    'hardwareprovider',
    'healthcare',
    'hospitality',
    'insurance',
    'investor',
    'legal',
    'logistics',
    'machinery',
    'manufacturing',
    'media',
    'ngo',
    'oilgas',
    'pharmaceuticals',
    'retail',
    'shipping',
    'softwareprovider',
    'startup',
    'technology',
    'technologyprovider',
    'telecommunications',
    'tier',
    'tourism',
    'transportation',
    'utilities',
    'other',
  ],
  'industries.'
);

export const [
  languageKeys,
  languageKeysArray,
  languagesMapping,
  languagesDropdownOptions,
  languagesSelectOptions,
  getLocalizedLanguage,
] = generateMappings(
  [
    'de',
    'en',
    'es',
    'cn',
    'hi',
    'pt',
    'ru',
    'jp',
    'fr',
    'nl',
    'tr',
    'sw',
    'it',
    'sr',
    'pl',
    'uk',
    'no',
    'mt',
    'ro',
    'mk',
    'lt',
    'lv',
    'kk',
    'ga',
    'is',
    'hu',
    'ka',
    'fi',
    'et',
    'da',
    'sk',
    'cs',
    'el',
    'hr',
    'bg',
    'az',
  ],
  'languages.'
);

export const [
  jobResponsibilityKeys,
  jobResponsibilityKeysArray,
  jobResponsibilitiesMapping,
  jobResponsibilitiesDropdownOptions,
  jobResponsibilitiesSelectOptions,
  getLocalizedJobResponsibility,
] = generateMappings(
  ['', 'influencer', 'decision_maker'],
  'job-responsibility.'
);

export const [
  typeOfBusinessKeys,
  typeOfBusinessKeysArray,
  typesOfBusinessMapping,
  typesOfBusinessDropdownOptions,
  typesOfBusinessSelectOptions,
  getLocalizedTypeOfBusiness,
] = generateMappings(['', 'enduser', 'vendor'], 'type-of-business.');

export const [
  jobLevelKeys,
  jobLevelKeysArray,
  jobLevelsMapping,
  jobLevelsDropdownOptions,
  jobLevelsSelectOptions,
  getLocalizedJobLevel,
] = generateMappings(
  [
    '',
    'owner_boardmember_partner',
    'ceo_cxo_md',
    'vp_svp_globalhead',
    'director_headof_leader',
    'seniormanager_manager_projectmanager_teamleader',
    'employee',
    'assistant',
  ],
  'job-levels.'
);

export const [
  salutationKeys,
  salutationKeysArray,
  salutationsMapping,
  salutationsDropdownOptions,
  salutationsSelectOptions,
  getLocalizedSalutation,
] = generateMappings(['mr', 'mrs', ''], 'salutation.');

export const [
  companySizeKeys,
  companySizeKeysArray,
  companySizesMapping,
  companySizesDropdownOptions,
  companySizesSelectOptions,
  getLocalizedCompanySize,
] = generateMappings(
  [
    '',
    '50',
    '100',
    '500',
    '1000',
    '5000',
    '10000',
    '30000',
    '100000',
    '1000000',
  ],
  undefined,
  {
    '': '',
    '50': '< 50',
    '100': '50 - 100',
    '500': '100 - 500',
    '1000': '500 - 1.000',
    '5000': '1.000 - 5.000',
    '10000': '5.000 - 10.000',
    '30000': '10.000 - 30.000',
    '100000': '30.000 - 100.000',
    '1000000': '> 100.000',
  }
);

export const [
  attendeeTypeKeys,
  attendeeTypeKeysArray,
  attendeeTypesMapping,
  attendeeTypesDropdownOptions,
  attendeeTypesSelectOptions,
  getLocalizedAttendeeType,
] = generateMappings(
  ['de', 'gu', 'sp', 'ex', 'cm', 'ea', 'del'],
  'attendee-types.'
);

export const [
  presenceKeys,
  presenceKeysArray,
  presenceMapping,
  presenceDropdownOptions,
  presenceSelectOptions,
  getLocalizedPresence,
] = generateMappings(['virtual', 'inperson'], 'presence.');

export const [
  attendeeAvailabilityKeys,
  attendeeAvailabilityKeysArray,
  attendeeAvailabilityMapping,
  attendeeAvailabilityDropdownOptions,
  attendeeAvailabilitySelectOptions,
  getLocalizedAttendeeAvailability,
] = generateMappings(
  ['online', 'standby', 'offline'],
  'attendee-availability.'
);

export const attendeeNameSortItems: Record<string, string> = {
  byMatchDesc: i18n.t('sort-match-desc'),
  byMatchAsc: i18n.t('sort-match-asc'),
  byNameAsc: i18n.t('sort-type-name-az'),
  byNameDesc: i18n.t('sort-type-name-za'),
  byCompanyAsc: i18n.t('sort-type-company-az'),
  byCompanyDesc: i18n.t('sort-type-company-za'),
};

export const attendeeTypeItems: Record<string, string> = {
  de: i18n.t('filter-attendee-type-1'),
  ex: i18n.t('filter-attendee-type-2'),
  sp: i18n.t('filter-attendee-type-3'),
  ea: i18n.t('filter-attendee-type-4'),
  cm: i18n.t('filter-attendee-type-5'),
  es: i18n.t('filter-attendee-type-6'),
  gu: i18n.t('filter-attendee-type-7'),
  fs: i18n.t('filter-attendee-type-8'),
  dm: i18n.t('filter-attendee-type-9'),
};

export const availabilityStatusItems: Record<string, string> = {
  online: i18n.t('filter-attendee-status-1'),
  offline: i18n.t('filter-attendee-status-3'),
};

export const speakerTypes: Record<string, string> = {
  digital: i18n.t('speaker-type-1'),
  online: i18n.t('speaker-type-2'),
};

export const chatType: Record<string, string> = {
  onsite: i18n.t('meetings-chat-type-1'),
  video: i18n.t('meetings-chat-type-2'),
  group: i18n.t('meetings-chat-type-3'),
};

export const hostType: Record<string, string> = {
  byMe: i18n.t('meetings-host-type-1'),
  byOthers: i18n.t('meetings-host-type-2'),
};

export const countriesTags: Record<string, string> = {
  dach: 'DACH',
  europe: 'Europe',
  united_states: 'USA',
  asia: 'Asia',
};

export const topics = {
  deep_machine_learning: 'Deep Machine Learning',
  online: 'Online',
  hmi_xt: 'HMI XT',
  optical_sensors: 'Optical Sensors',
  reinforce_computer_learning: 'Reinforcement + Computer Learning',
  automotive: 'Automotive',
};

export enum TimeZones {
  'Pacific/Midway' = '(GMT-11:00) Midway Island',
  'Pacific/Pago_Pago' = '(GMT-11:00) Samoa',
  'Pacific/Honolulu' = '(GMT-10:00) Hawaii',
  'America/Sitka' = '(GMT-10:00) Sitka',
  'America/Anchorage' = '(GMT-09:00) Alaska',
  'America/Los_Angeles' = '(GMT-08:00) Pacific Time (US & Canada)',
  'America/Tijuana' = '(GMT-08:00) Tijuana',
  'America/Phoenix' = '(GMT-07:00) Arizona',
  'America/Denver' = '(GMT-07:00) Mountain Time (US & Canada)',
  'America/Chihuahua' = '(GMT-07:00) Chihuahua',
  'America/Mazatlan' = '(GMT-07:00) Mazatlan',
  'America/Mexico_City' = '(GMT-06:00) Mexico City',
  'America/Monterrey' = '(GMT-06:00) Monterrey',
  'America/Regina' = '(GMT-06:00) Saskatchewan',
  'America/Winnipeg' = '(GMT-06:00) Central Time (US & Canada)',
  'America/Toronto' = '(GMT-05:00) Eastern Time (US & Canada)',
  'America/Indiana/Indianapolis' = '(GMT-05:00) Indiana (East)',
  'America/Bogota' = '(GMT-05:00) Bogota',
  'America/Lima' = '(GMT-05:00) Lima',
  'America/Caracas' = '(GMT-04:30) Caracas',
  'America/Halifax' = '(GMT-04:00) Atlantic Time (Canada)',
  'America/La_Paz' = '(GMT-04:00) La Paz',
  'America/Santiago' = '(GMT-04:00) Santiago',
  'America/St_Johns' = '(GMT-03:30) Newfoundland',
  'America/Argentina/Buenos_Aires' = '(GMT-03:00) Buenos Aires',
  'Atlantic/Stanley' = '(GMT-02:00) Stanley',
  'Atlantic/Azores' = '(GMT-01:00) Azores',
  'Atlantic/Cape_Verde' = '(GMT-01:00) Cape Verde Is.',
  'Africa/Casablanca' = '(GMT) Casablanca',
  'Europe/Dublin' = '(GMT) Dublin',
  'Europe/Lisbon' = '(GMT) Lisbon',
  'Europe/London' = '(GMT) London',
  'Africa/Monrovia' = '(GMT) Monrovia',
  'Europe/Amsterdam' = '(GMT+01:00) Amsterdam',
  'Europe/Belgrade' = '(GMT+01:00) Belgrade',
  'Europe/Berlin' = '(GMT+01:00) Berlin',
  'Europe/Bratislava' = '(GMT+01:00) Bratislava',
  'Europe/Brussels' = '(GMT+01:00) Brussels',
  'Europe/Budapest' = '(GMT+01:00) Budapest',
  'Europe/Copenhagen' = '(GMT+01:00) Copenhagen',
  'Europe/Ljubljana' = '(GMT+01:00) Ljubljana',
  'Europe/Madrid' = '(GMT+01:00) Madrid',
  'Europe/Paris' = '(GMT+01:00) Paris',
  'Europe/Prague' = '(GMT+01:00) Prague',
  'Europe/Rome' = '(GMT+01:00) Rome',
  'Europe/Sarajevo' = '(GMT+01:00) Sarajevo',
  'Europe/Skopje' = '(GMT+01:00) Skopje',
  'Europe/Stockholm' = '(GMT+01:00) Stockholm',
  'Europe/Vienna' = '(GMT+01:00) Vienna',
  'Europe/Warsaw' = '(GMT+01:00) Warsaw',
  'Europe/Zagreb' = '(GMT+01:00) Zagreb',
  'Europe/Athens' = '(GMT+02:00) Athens',
  'Europe/Bucharest' = '(GMT+02:00) Bucharest',
  'Africa/Cairo' = '(GMT+02:00) Cairo',
  'Africa/Harare' = '(GMT+02:00) Harare',
  'Europe/Helsinki' = '(GMT+02:00) Helsinki',
  'Europe/Istanbul' = '(GMT+02:00) Istanbul',
  'Asia/Jerusalem' = '(GMT+02:00) Jerusalem',
  'Europe/Kiev' = '(GMT+02:00) Kyiv',
  'Europe/Minsk' = '(GMT+02:00) Minsk',
  'Europe/Riga' = '(GMT+02:00) Riga',
  'Europe/Sofia' = '(GMT+02:00) Sofia',
  'Europe/Tallinn' = '(GMT+02:00) Tallinn',
  'Europe/Vilnius' = '(GMT+02:00) Vilnius',
  'Asia/Baghdad' = '(GMT+03:00) Baghdad',
  'Asia/Kuwait' = '(GMT+03:00) Kuwait',
  'Africa/Nairobi' = '(GMT+03:00) Nairobi',
  'Asia/Riyadh' = '(GMT+03:00) Riyadh',
  'Europe/Moscow' = '(GMT+03:00) Moscow',
  'Asia/Tehran' = '(GMT+03:30) Tehran',
  'Asia/Baku' = '(GMT+04:00) Baku',
  'Europe/Volgograd' = '(GMT+04:00) Volgograd',
  'Asia/Muscat' = '(GMT+04:00) Muscat',
  'Asia/Tbilisi' = '(GMT+04:00) Tbilisi',
  'Asia/Yerevan' = '(GMT+04:00) Yerevan',
  'Asia/Kabul' = '(GMT+04:30) Kabul',
  'Asia/Karachi' = '(GMT+05:00) Karachi',
  'Asia/Tashkent' = '(GMT+05:00) Tashkent',
  'Asia/Kolkata' = '(GMT+05:30) Kolkata',
  'Asia/Kathmandu' = '(GMT+05:45) Kathmandu',
  'Asia/Yekaterinburg' = '(GMT+06:00) Ekaterinburg',
  'Asia/Almaty' = '(GMT+06:00) Almaty',
  'Asia/Dhaka' = '(GMT+06:00) Dhaka',
  'Asia/Novosibirsk' = '(GMT+07:00) Novosibirsk',
  'Asia/Bangkok' = '(GMT+07:00) Bangkok',
  'Asia/Jakarta' = '(GMT+07:00) Jakarta',
  'Asia/Pontianak' = '(GMT+07:00) Pontianak',
  'Asia/Krasnoyarsk' = '(GMT+08:00) Krasnoyarsk',
  'Asia/Hong_Kong' = '(GMT+08:00) Hong Kong',
  'Asia/Kuala_Lumpur' = '(GMT+08:00) Kuala Lumpur',
  'Australia/Perth' = '(GMT+08:00) Perth',
  'Asia/Singapore' = '(GMT+08:00) Singapore',
  'Asia/Taipei' = '(GMT+08:00) Taipei',
  'Asia/Ulaanbaatar' = '(GMT+08:00) Ulaan Bataar',
  'Asia/Urumqi' = '(GMT+08:00) Urumqi',
  'Asia/Irkutsk' = '(GMT+09:00) Irkutsk',
  'Asia/Seoul' = '(GMT+09:00) Seoul',
  'Asia/Tokyo' = '(GMT+09:00) Tokyo',
  'Australia/Adelaide' = '(GMT+09:30) Adelaide',
  'Australia/Darwin' = '(GMT+09:30) Darwin',
  'Asia/Yakutsk' = '(GMT+10:00) Yakutsk',
  'Australia/Brisbane' = '(GMT+10:00) Brisbane',
  'Pacific/Guam' = '(GMT+10:00) Guam',
  'Australia/Hobart' = '(GMT+10:00) Hobart',
  'Australia/Melbourne' = '(GMT+10:00) Melbourne',
  'Pacific/Port_Moresby' = '(GMT+10:00) Port Moresby',
  'Australia/Sydney' = '(GMT+10:00) Sydney',
  'Asia/Vladivostok' = '(GMT+11:00) Vladivostok',
  'Asia/Magadan' = '(GMT+12:00) Magadan',
  'Pacific/Auckland' = '(GMT+12:00) Auckland',
  'Pacific/Fiji' = '(GMT+12:00) Fiji',
}

export const stringBooleans = {
  true: true,
  false: false,
};

export const adminRoles = {
  de: 'admin-delegate',
  cm: 'admin-content-manager',
  sp: 'admin-speaker',
  ex: 'admin-exhibitor',
};

export const adminRolesMapping = {
  de: 'filter-attendee-type-1',
  ex: 'filter-attendee-type-2',
  sp: 'filter-attendee-type-3',
  cm: 'filter-attendee-type-5',
};

export const deploymentLink = (link: string | undefined) => {
  switch (link) {
    case 'https://api-staging.hubs101.com':
      return 'https://develop.hubs101.com';
    case 'https://api.hubs101.com':
      return 'https://app.hubs101.com';
    default:
      return 'https://app.hubs101.com/';
  }
};
export const deploymentMediaLink = (link: string | undefined) => {
  switch (link) {
    case 'https://api-staging.hubs101.com':
      return 'https://staging-media.hubs101.com/';
    case 'https://api.hubs101.com':
      return 'https://media.hubs101.com/';
    default:
      return 'https://media.hubs101.com/';
  }
};
