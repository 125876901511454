import { H6 } from '@components/Typography';
import styled from 'styled-components';

export const Container = styled.div`
  z-index: 10000;
  position: absolute;
  background-color: ${props => props.theme.color.white};
  border-radius: ${props => props.theme.borderRadius.radius10};
  border: solid ${props => props.theme.color.grey1};
  box-shadow: 0 0 0.7rem 0 rgba(0, 0, 0, 0.15);
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px 0rem 0rem 3rem;
  transition: opacity 2s ease-in-out;
`;

export const Title = styled(H6)`
  color: ${props => props.theme.color.primaryText};
  font-weight: ${props => props.theme.fontWeight.weight500};
  font-size: ${props => props.theme.fontSize.size16};
  line-height: ${props => props.theme.fontSize.size20};
  margin: 1rem;
  max-width: 40vw;
  width: max-content;
`;
