import {
  type DiscountType,
  type TicketOptionsInput,
} from '@hubs101/booking-api-skd-client/lib/types';

import intersection from 'lodash/intersection';

export const checkIfDiscountAppliesMinMax = (
  selectedTicketsWithOptions: TicketOptionsInput[],
  discount: DiscountType | undefined
) => {
  const quantitySelected = selectedTicketsWithOptions?.filter(e =>
    discount?.tickets?.includes(e.ticket)
  )?.length;

  return (
    quantitySelected >= Number(discount?.min_ticket) &&
    quantitySelected <= Number(discount?.max_ticket)
  );
};

export const checkIfDiscountAppliesToSelectedTickets = (
  selectedTicketsWithOptions: TicketOptionsInput[],
  discount: DiscountType | undefined
) => {
  if (discount) {
    const selectedTicketsIds = selectedTicketsWithOptions?.map(
      item => item.ticket
    );
    const commonDiscountedTickets = intersection(
      discount?.tickets,
      selectedTicketsIds
    );

    return Boolean(commonDiscountedTickets?.length);
  }
  return false;
};
