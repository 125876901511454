import logoVisa from '@assets/images/payment/visa.png';
import BoxDecoration from '@components/BoxDecoration';
import Checkbox from '@components/Checkbox';
import Icon from '@components/Icon';
import { Column, RowAlignedItems } from '@components/LinearLayout';
import PageTitle from '@components/PageTitle';
import PriceSection from '@components/PriceSection';
import { P, PSmall, Small } from '@components/Typography';
import EditBox from '@composites/EditBox';
import TicketSummary from '@composites/TicketSummary';
import { extractSearchParams } from '@utils/fn';
import { type Dispatch, type SetStateAction, useMemo } from 'react';
import { type UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { LogoImg } from '../PaymentDetailsStep/styles';
import { BoxTicketPriceContainer, EditButton, VatText } from './styles';
import { type BookingsFormInput } from '@hubs101/booking-api-skd-client/lib/types';

interface SummaryDetailsStepProps {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  watch: UseFormWatch<BookingsFormInput>;
  acceptanceDeclaration: boolean;
  setAcceptanceDeclaration: Dispatch<SetStateAction<boolean>>;
}

const SummaryDetailsStep = ({
  step,
  setStep,
  watch,
  acceptanceDeclaration,
  setAcceptanceDeclaration,
}: SummaryDetailsStepProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const cardNumberSuffix = watch('card_number').substring(
    watch('card_number')?.length - 4,
    watch('card_number')?.length
  );

  const contentInvoiceAddress = useMemo(
    () => (
      <Column>
        <PSmall> {watch('invoice_full_name')} </PSmall>
        <PSmall> {watch('contact_email')} </PSmall>
        <PSmall> {watch('address_street')} </PSmall>
        <PSmall> {watch('address_country')} </PSmall>

        <VatText>
          <PSmall>{t('vat')}:</PSmall> <PSmall> {watch('vat_id')} </PSmall>
        </VatText>
      </Column>
    ),
    [t, watch]
  );

  const contentPaymentMethod = useMemo(
    () => (
      <RowAlignedItems gap="1rem">
        <LogoImg src={logoVisa} alt="visa" />
        <RowAlignedItems gap="0.5rem">
          <PSmall>{t('ending-with')} </PSmall>
          <PSmall> {cardNumberSuffix}</PSmall>
        </RowAlignedItems>
      </RowAlignedItems>
    ),
    [cardNumberSuffix, t]
  );

  const contentTickets = useMemo(
    () => (
      <Column gap="2rem">
        {/* <Banner text="payment-failed" type="error" /> */}
        <TicketSummary title="Standard Event Pass" price="€ 2.549,00">
          <BoxDecoration
            padding="1rem"
            bgColor={theme.color.white}
            borderRadius={theme.borderRadius.radius5}
            width="100%"
            border={`0.1rem solid ${theme.color.divider}`}
          >
            <BoxTicketPriceContainer>
              <Icon
                name="edit"
                size="2.8rem"
                color={theme.color.primaryText}
                onClick={() => {
                  setSearchParams({
                    ...extractSearchParams(searchParams),
                    tag: 'standard-event-pass',
                  });
                  setStep(0);
                }}
              />
              <Column>
                <P>{watch('first_name')}</P>
                <P>{watch('contact_email')}</P>
              </Column>
            </BoxTicketPriceContainer>
          </BoxDecoration>
        </TicketSummary>

        <TicketSummary title="Standard Event Pass" price="€ 2.549,00">
          <BoxDecoration
            padding="1rem"
            bgColor={theme.color.white}
            borderRadius={theme.borderRadius.radius5}
            width="100%"
            border={`0.1rem solid ${theme.color.divider}`}
          >
            <BoxTicketPriceContainer>
              <Icon
                name="edit"
                size="2.8rem"
                color={theme.color.primaryText}
                onClick={() => {
                  setSearchParams({
                    ...extractSearchParams(searchParams),
                    tag: 'standard-event-pass',
                  });
                  setStep(0);
                }}
              />
              <Column>
                <P>{watch('first_name')}</P>
                <P>{watch('contact_email')}</P>
              </Column>
            </BoxTicketPriceContainer>
          </BoxDecoration>
        </TicketSummary>
      </Column>
    ),
    [
      searchParams,
      setSearchParams,
      setStep,
      theme.borderRadius.radius5,
      theme.color.divider,
      theme.color.primaryText,
      theme.color.white,
      watch,
    ]
  );

  return (
    <Column gap="2rem">
      <PageTitle title={t('Summary')} />
      <EditBox
        title={t('invoice-address')}
        rightSideContent={
          <EditButton
            gap="0.8rem"
            onClick={() => {
              setStep(2);
            }}
          >
            <Small>{t('edit')}</Small>
            <Icon
              name="edit"
              size="2rem"
              color={theme.color.greyDark}
              onClick={() => {
                setStep(2);
              }}
            />
          </EditButton>
        }
      >
        {contentInvoiceAddress}
      </EditBox>
      <EditBox
        title={t('payment-method')}
        rightSideContent={
          <EditButton
            gap="0.8rem"
            onClick={() => {
              setSearchParams({
                ...extractSearchParams(searchParams),
                tag: 'payment-options',
              });
              setStep(2);
            }}
          >
            <Small>{t('edit')}</Small>
            <Icon
              name="edit"
              size="2rem"
              color={theme.color.greyDark}
              onClick={() => {
                setSearchParams({
                  ...extractSearchParams(searchParams),
                  tag: 'payment-options',
                });
                setStep(2);
              }}
            />
          </EditButton>
        }
      >
        {contentPaymentMethod}
      </EditBox>

      <EditBox
        title={t('tickets')}
        rightSideContent={
          <EditButton
            gap="0.8rem"
            onClick={() => {
              setStep(1);
            }}
          >
            <Small>{t('edit')}</Small>
            <Icon
              name="edit"
              size="2rem"
              color={theme.color.greyDark}
              onClick={() => {
                setStep(1);
              }}
            />
          </EditButton>
        }
      >
        {contentTickets}
      </EditBox>

      <PriceSection />

      <BoxDecoration
        padding="1rem"
        bgColor={theme.color.white}
        borderRadius={theme.borderRadius.radius5}
        width="100%"
        border={`0.1rem solid ${theme.color.grey1}`}
        onClick={() => {
          setAcceptanceDeclaration(!acceptanceDeclaration);
        }}
      >
        <RowAlignedItems>
          <Checkbox
            label={
              <RowAlignedItems>
                <PSmall>{t('acceptance-declaration')}</PSmall>
              </RowAlignedItems>
            }
            spacing={theme.spacing.size5}
            checked={acceptanceDeclaration}
            type="square"
            size="1.8rem"
            onChange={() => {
              setAcceptanceDeclaration(!acceptanceDeclaration);
            }}
          />
        </RowAlignedItems>
      </BoxDecoration>
    </Column>
  );
};

export default SummaryDetailsStep;
