import { Column } from '@components/LinearLayout';
import { PSmall } from '@components/Typography';
import styled from 'styled-components';

export const EventDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    display: flex;
    flex-direction: column;
  }
`;
export const LogoTitleWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`;
export const LogoContainer = styled.div`
  display: grid;
  place-items: center;
  border-right: 0.1rem solid ${({ theme }) => theme.color.divider};
  width: 14rem;
  padding: 1rem;
`;

export const TitleWrapper = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
`;
export const LogoImg = styled.img`
  height: auto;
  width: 100%;
`;

export const DatesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  border-left: 0.1rem solid ${({ theme }) => theme.color.divider};
  padding: ${({ theme }) => theme.spacing.size10};
  width: 32rem;
  gap: 1rem;
  place-items: center;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    width: 100%;
    border-top: 0.1rem solid ${({ theme }) => theme.color.divider};
    border-left: 0;
  }
`;

export const Divider = styled.div`
  width: 0.1rem;
  height: 100%;
  background-color: ${({ theme }) => theme.color.grey2};
`;

export const DateItem = styled(Column)`
  width: 100%;
  align-items: start;
  gap: 0.2rem;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    align-items: center;
  }
`;

export const DateSubtitle = styled(PSmall)`
  text-transform: uppercase;
`;

export const NameContainer = styled.div`
  display: flex;
  gap: 0.4rem;
`;
