import { memo, type ReactNode } from 'react';
import styled from 'styled-components';
import { type BaseThemeType } from '@theme/base';
import Spinner from '@components/Spinner';

export interface ButtonProps {
  buttonStyle: ButtonStyle;
  buttonSize: ButtonSizes;
  content?: ReactNode | string;
  disabled?: boolean;
  backgroundColor?: string;
  onClick?: () => void;
  border?: string;
  boxShadow?: string;
  color?: string;
  showSpinner?: boolean;
  dataTestId?: string;
}

export enum ButtonSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum ButtonStyle {
  default = 'default',
  outline = 'outline',
  white = 'white',
}

type ButtonStyledProps = Pick<
  ButtonProps,
  | 'buttonSize'
  | 'buttonStyle'
  | 'disabled'
  | 'backgroundColor'
  | 'border'
  | 'boxShadow'
  | 'color'
> & {
  theme: BaseThemeType;
};
const ButtonComponent = styled.button`
  font-family: ${(props: ButtonStyledProps) => props.theme.font.body};
  font-size: ${(props: ButtonStyledProps) => props.theme.fontSize.size12};
  font-weight: ${(props: ButtonStyledProps) =>
    props.theme.fontWeight.weight500};
  line-height: ${(props: ButtonStyledProps) => props.theme.fontSize.size20};
  display: flex;
  justify-content: space-between;

  align-items: center;
  ${(props: ButtonStyledProps) => {
    if (props.buttonSize === ButtonSizes.small) {
      return `
        padding: ${props.theme.spacing.size4} ${props.theme.spacing.size20};
      `;
    }
    if (props.buttonSize === ButtonSizes.medium) {
      return `
        font-size: ${props.theme.fontSize.size15};
        padding: ${props.theme.spacing.size10} ${props.theme.spacing.size30};
      `;
    }
    if (props.buttonSize === ButtonSizes.large) {
      return `
        font-size: ${props.theme.fontSize.size15};
        font-weight: ${props.theme.fontWeight.weight600};
        padding: ${props.theme.spacing.size17} ${props.theme.spacing.size40};
      `;
    }
  }};
  ${(props: ButtonStyledProps) => {
    if (props.backgroundColor != null) {
      return `
        color: ${props.theme.color.white};
        background-color: ${props.backgroundColor};
        border: none;
      `;
    }
    if (props.buttonStyle === ButtonStyle.default) {
      return `
        color: ${props.theme.color.white};
        background-color: ${props.theme.color.primary};
        border: none;
      `;
    }
    if (props.buttonStyle === ButtonStyle.outline) {
      return `
        color: ${props.theme.color.primary};
        background-color: ${props.theme.color.white};
        border: ${props.theme.fontSize.size1} solid ${props.theme.color.primary};
      `;
    }
    if (props.buttonStyle === ButtonStyle.white) {
      return `
        color: ${props.theme.color.greyDark};
        background-color: ${props.theme.color.white};
        border: none;
      `;
    }
  }}
  cursor: pointer;
  &:disabled {
    color: ${(props: ButtonStyledProps) => props.theme.color.greyLight};
    background: ${(props: ButtonStyledProps) => props.theme.color.greyMedium};
    border: none;
    cursor: not-allowed;
  }
  border-radius: ${(props: ButtonStyledProps) =>
    props.theme.borderRadius.radius30};

  ${props => props.border != null && `border: ${props.border};`}
  ${props => props.boxShadow != null && `box-shadow: ${props.boxShadow};`}
  ${props => props.color != null && `color: ${props.color};`}
  &:active {
    opacity: 0.6;
  }
`;

const Button = ({
  buttonSize = ButtonSizes.medium,
  buttonStyle = ButtonStyle.default,
  content,
  disabled,
  backgroundColor,
  onClick,
  color,
  border,
  boxShadow,
  showSpinner,
  dataTestId,
}: ButtonProps) => {
  return (
    <ButtonComponent
      data-testid={dataTestId ?? 'components:button'}
      disabled={disabled}
      buttonStyle={buttonStyle}
      buttonSize={buttonSize}
      onClick={onClick}
      backgroundColor={backgroundColor}
      border={border}
      boxShadow={boxShadow}
      color={color}
    >
      {content}
      {showSpinner && <Spinner size="2rem" />}
    </ButtonComponent>
  );
};

export default memo(Button);
