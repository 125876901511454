import { Row, RowAlignedItems } from '@components/LinearLayout';
import { H4 } from '@components/Typography';
import styled from 'styled-components';

interface PageTitleProps {
  title: string;
  leftSideElement?: JSX.Element;
  rightSideElement?: JSX.Element;
  id?: string;
}

const PageTitleRoot = styled(RowAlignedItems)`
  justify-content: space-between;
  flex-wrap: wrap;
`;
const PageTitle = ({
  title,
  rightSideElement,
  leftSideElement,
  id,
}: PageTitleProps) => {
  return (
    <PageTitleRoot id={id}>
      <Row gap="1rem">
        <H4>{title}</H4>
        {leftSideElement}
      </Row>
      {rightSideElement}
    </PageTitleRoot>
  );
};

export default PageTitle;
