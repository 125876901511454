import styled, { keyframes } from 'styled-components';

const waveSquares = keyframes`
0% {    background-position: -468px 0; }
100% {   background-position: 468px 0;}
 `;

const Square = styled.div<{ width: string; height: string; margin?: string }>`
  height: ${props => props.height};
  width: ${props => props.width};
  margin: ${props => props.margin};
  border-radius: ${props => props.theme.borderRadius.radius5};
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 80rem 10rem;
  animation: ${waveSquares} 2s infinite ease-out;
`;

const Line = styled.div<{ width: string; height: string; margin?: string }>`
  height: ${props => props.height};
  width: ${props => props.width};
  margin: ${props => props.margin};
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 80rem 10rem;
  animation: ${waveSquares} 2s infinite ease-out;
`;

interface SquareSkeletonProps {
  width: string;
  height: string;
  margin?: string;
}
export const SquareSkeleton = ({
  width,
  height,
  margin,
}: SquareSkeletonProps) => (
  <Square width={width} height={height} margin={margin} />
);
export const LineSkeleton = ({
  width,
  height,
  margin,
}: SquareSkeletonProps) => (
  <Line width={width} height={height} margin={margin} />
);

const SkeletonsLoaders = () => {
  return <div>SkeletonsLoaders</div>;
};

export default SkeletonsLoaders;
