import Button, { ButtonSizes, ButtonStyle } from '@components/Button';
import { HEADER_HEIGHT, LANGUAGE } from '@constants/generic';
import { extractSearchParams, isDarkTheme } from '@utils/fn';
import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import englishLogo from '@assets/images/english.png';
import germanLogo from '@assets/images/german.png';
import hubs101Logo from '@assets/images/hubs101Logo.png';
import hubs101LogoD from '@assets/images/hubs101LogoD.png';
import { RowAlignedItems } from '@components/LinearLayout';
import { LinkExternalSmall } from '@components/Typography';
import { ROOT_URL } from '@constants/api';
import ScrollToTop from '@utils/ScrollToTop';
import { deploymentLink } from '@utils/mappings';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import FooterLayout from './components/FooterLayout';

const localStorageLanguage = localStorage.getItem('language');

const Layout = styled.div`
  width: 100vw;
  height: 100%;
`;

const HeaderLayout = styled.div`
  height: ${HEADER_HEIGHT};
  background-color: ${props => props.theme.color.headerBackgroundColor};
  position: fixed;
  top: 0;
  padding: 1rem 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 6;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    padding: 1rem;
  }
`;

const ContentLayout = styled.div<{ isIframe: boolean }>`
  height: fit-content;
  min-height: 100vh;
  width: 100%;
  margin: auto;
  padding-top: ${props => (props.isIframe ? 0 : HEADER_HEIGHT)};
  padding-bottom: ${props => (props.isIframe ? HEADER_HEIGHT : 0)};
  background-color: ${props => props.theme.color.white};
  display: grid;
  grid-template-rows: 1fr auto;
`;

const LogoImg = styled.img`
  height: 4rem;
  width: auto;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    height: 3rem;
  }
`;

const RightSideHeader = styled.div`
  display: flex;
  gap: 1rem;
`;

export const LogoContainer = styled.div<{ isSelected?: boolean }>`
  width: ${props => (props.isSelected ? '2.5rem' : '2.8rem')};
  height: ${props => (props.isSelected ? '2.5rem' : '2.8rem')};
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  outline: ${props =>
    props.isSelected && '0.4rem solid rgb(20, 138, 187, 0.6)'};
`;

export const LogoBorder = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
export const LanguageLogo = styled.img`
  height: 3rem;
  width: 3rem;
  object-fit: contain;
  zoom: 1.1;
`;

const LinkText = styled(LinkExternalSmall)`
  color: ${props => props.theme.color.white};
  font-weight: ${props => props.theme.fontWeight.weight400};
  cursor: pointer;
  text-decoration: none;
`;
require('dayjs/locale/de');
require('dayjs/locale/en');
interface HomeLayoutProps {
  themeToggler: () => void;
  children: ReactNode;
}
const HomeLayout = ({ themeToggler, children }: HomeLayoutProps) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('ty');
  const logoSrc = isDarkTheme(theme) ? hubs101LogoD : hubs101Logo;
  const [lng, setLng] = useState(i18n.language ?? localStorageLanguage ?? 'en');
  const isIframe = useMemo(() => type === 'iframe', [type]);

  useEffect(() => {
    dayjs.locale(lng);
    if (lng && !searchParams.get('la')) {
      searchParams.append('la', lng);
      setSearchParams(searchParams);
    }
    if (i18n.language !== lng) setLng(i18n.language);
  }, [i18n.language, lng, searchParams, setSearchParams]);

  const onChangeLanguage = useCallback(
    (lng: string) => {
      void i18n.changeLanguage(lng);
      if (localStorageLanguage !== lng) localStorage.setItem('language', lng);
      setLng(lng);
      setSearchParams({
        ...extractSearchParams(searchParams),
        la: lng,
      });
    },
    [i18n, searchParams, setSearchParams]
  );

  return (
    <Layout>
      {!isIframe && (
        <HeaderLayout>
          <Link to="/" replace>
            <LogoImg src={logoSrc} alt="hubs101 logo" />
          </Link>
          <RightSideHeader>
            <RowAlignedItems gap="1rem">
              <LogoContainer
                isSelected={lng === LANGUAGE.GERMAN}
                onClick={() => {
                  onChangeLanguage(LANGUAGE.GERMAN);
                }}
              >
                <LogoBorder>
                  <LanguageLogo src={germanLogo} />
                </LogoBorder>
              </LogoContainer>
              <LogoContainer
                isSelected={lng === LANGUAGE.ENGLISH}
                onClick={() => {
                  onChangeLanguage(LANGUAGE.ENGLISH);
                }}
              >
                <LogoBorder>
                  <LanguageLogo src={englishLogo} />
                </LogoBorder>
              </LogoContainer>
            </RowAlignedItems>
            <LinkText
              href={deploymentLink(String(ROOT_URL))}
              rel="noopener noreferrer"
            >
              <Button
                buttonSize={ButtonSizes.small}
                buttonStyle={ButtonStyle.outline}
                content={t('sign-in')}
              />
            </LinkText>
          </RightSideHeader>
        </HeaderLayout>
      )}
      <ContentLayout isIframe={isIframe}>
        <div>{children}</div>
        {!isIframe && <FooterLayout />}
      </ContentLayout>

      <ScrollToTop />
    </Layout>
  );
};

export default HomeLayout;
