import { PSmall, PSmallBold } from '@components/Typography';
import {
  type DiscountType,
  type OrderedTicket,
} from '@hubs101/booking-api-skd-client/lib/types';
import {
  DiscountPrice,
  StandardElement,
} from '@pages/Purchase/components/AttendeeDetailsStep/styles';

import { Column } from '@components/LinearLayout';
import { type CURRENCY } from '@constants/generic';
import { type TicketOptionsInput } from '@utils/apiHooks';
import { checkIfDiscountAppliesMinMax } from '@utils/checkIfDiscountAppliesMinMax';
import { formatPrice } from '@utils/fn';
import { useTranslation } from 'react-i18next';

const BookingDetailsItem = ({
  ticket,
  currency,

  discount,
  selectedTicketsWithOptions,
}: {
  ticket: OrderedTicket;
  currency: string;

  discount?: DiscountType;
  selectedTicketsWithOptions: TicketOptionsInput[];
}) => {
  const { t } = useTranslation();
  const symbol = currency as keyof typeof CURRENCY;
  return (
    <Column gap="0.3rem" key={ticket.reference}>
      <StandardElement>
        <PSmallBold>{ticket?.name}</PSmallBold>
        <PSmallBold>{formatPrice(ticket?.price, symbol)}</PSmallBold>
      </StandardElement>

      {Number(ticket?.discountValue) > 0 &&
        checkIfDiscountAppliesMinMax(selectedTicketsWithOptions, discount) && (
          <StandardElement>
            <DiscountPrice>
              {discount?.value}% {t('discount')} {discount?.code}
            </DiscountPrice>
            <DiscountPrice>
              -{formatPrice(Number(ticket?.discountValue), symbol)}
            </DiscountPrice>
          </StandardElement>
        )}

      {ticket?.extraOptions?.map(extraOption => (
        <StandardElement key={`${ticket.reference}-${extraOption.id}`}>
          <PSmall>{extraOption?.name}</PSmall>
          <PSmall>{formatPrice(extraOption?.price, symbol)}</PSmall>
        </StandardElement>
      ))}
    </Column>
  );
};

export default BookingDetailsItem;
