import { Column, RowAlignedItems } from '@components/LinearLayout';
import { H6, PSmall } from '@components/Typography';
import TicketContainer from '@composites/TicketContainer';
import styled from 'styled-components';

export const ExtraContentContainer = styled(RowAlignedItems)`
  gap: 1rem;
  height: 100%;
`;

export const RowAlignedItemsColumn = styled(RowAlignedItems)`
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    flex-direction: column;
  }
`;

export const ColumnWithBottomMargin = styled(Column)`
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    margin: 1rem 0 1rem 0;
  }
`;

export const LogoImg = styled.img`
  height: 100%;
  width: auto;
`;

export const CreditCardContainer = styled.div`
  padding: 2rem;
  width: 50%;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    width: 100%;
  }
`;

export const PurchaseSuccessfulCard = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 2rem;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const AlignedText = styled.div`
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    text-align: center;
  }
`;

export const AssignableTicket = styled(TicketContainer)`
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    flex-direction: column;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

export const HighlightedText = styled(PSmall)`
  padding: 0.5rem 1rem;
  background-color: ${props => props.theme.color.redSoft};
  color: ${props => props.theme.color.orange};
  border-radius: ${props => props.theme.borderRadius.radius20};
  border: 0.1rem solid ${props => props.theme.color.orange};
`;

export const HighlightedGreenText = styled(HighlightedText)`
  background-color: ${props => props.theme.color.greenSoft};
  color: ${props => props.theme.color.green};
  border: 0.1rem solid ${props => props.theme.color.green};
`;

export const HighlightedOrangeText = styled(HighlightedText)`
  background-color: ${props => props.theme.color.orangeSoft};
  color: ${props => props.theme.color.darkOrange};
  border: 0.1rem solid ${props => props.theme.color.darkOrange};
`;

export const AssignTicketText = styled.a<{
  color?: string;
  disabled?: boolean;
}>`
  font-family: ${props => props.theme.font.body};
  font-size: ${props => props.theme.fontSize.size15};
  font-weight: ${props => props.theme.fontWeight.weight400};
  line-height: ${props => props.theme.lineHeight.height150};
  color: ${props =>
    props.disabled
      ? props.theme.color.greyMedium
      : props.color
        ? props.color
        : props.theme.color.primaryText};
  text-decoration: underline;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const PurchaseLinkWrapper = styled.div`
  padding: 1rem 2rem;
  background-color: ${props => props.theme.color.lightest};
  border: 0.1rem solid ${props => props.theme.color.grey1};
  border-radius: ${props => props.theme.borderRadius.radius5};
  display: grid;
  grid-template-columns: 1fr auto;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    word-break: break-all;
  }
`;

export const CopyLink = styled.div`
  cursor: pointer;
`;

export const MoreTickets = styled(H6)`
  margin-left: auto;
  color: ${props => props.theme.color.green};
  cursor: pointer;
`;
