import styled from 'styled-components';

export const Column = styled.div<{
  margin?: string;
  gap?: string;
}>`
  display: flex;
  flex-direction: column;
  margin: ${props => props.margin};
  gap: ${props => props.gap};
`;

export const Row = styled.div<{
  margin?: string;
  gap?: string;
  alignItems?: string;
}>`
  display: flex;
  flex-direction: row;
  margin: ${props => props.margin};
  gap: ${props => props.gap};
`;

export const RowAlignedItems = styled(Row)`
  align-items: ${props => props.alignItems ?? 'center'};
`;

export const RowSpace = styled.div<{ height?: string }>`
  height: ${props => props.height ?? '1rem'};
`;

export const CenterItem = styled.div`
  display: grid;
  place-items: center;
`;
