import { RowAlignedItems } from '@components/LinearLayout';
import styled from 'styled-components';

export const DiscountAdded = styled.div`
  background-color: ${props => props.theme.color.white};
  margin-top: 2rem;
  height: 8rem;
  display: grid;
  place-items: center;
  border-radius: ${props => props.theme.borderRadius.radius10};
`;
export const DiscountSectionRoot = styled(RowAlignedItems)`
  background-color: ${props => props.theme.color.white};
  margin-top: 2rem;
  flex-wrap: wrap;
  justify-content: end;
  gap: 2rem;
  border-radius: ${props => props.theme.borderRadius.radius10};
  padding: 2rem 0;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    padding: 2rem 1rem 2rem 0;
    justify-content: center;
  }
`;

export const StretchItem = styled.div``;

export const CodeReferenceInput = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 2rem;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    display: flex;
    flex-direction: column;
  }
`;

export const InputWrapper = styled.div`
  height: 4rem;
  min-width: 22rem;
`;
