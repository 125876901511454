import { P, PBold, PSmall } from '@components/Typography';
import {
  type ExtraOption,
  type Ticket,
} from '@hubs101/booking-api-skd-client/lib/types';
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useMemo,
} from 'react';
import {
  AddOptionWrapper,
  AssignToContainer,
  OptionItem,
  PopoverWrapper,
  TicketOptionsItemRoot,
  TicketTitle,
} from './styles';

import Checkbox from '@components/Checkbox';
import { RowAlignedItems } from '@components/LinearLayout';
import TextPopover from '@components/TextPopover';
import { CURRENCY } from '@constants/generic';
import { type TicketOptionsInput } from '@utils/apiHooks';
import { formatPrice } from '@utils/fn';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

export interface IndexSpecificTicketOption {
  index: number;
  options: ExtraOption[];
}

interface TicketOptionsItemProps {
  selectedTicket: TicketOptionsInput;
  tickets: Ticket[];
  order: number;
  selectedTicketsWithOptions: TicketOptionsInput[];
  setSelectedTicketsWithOptions: Dispatch<SetStateAction<TicketOptionsInput[]>>;
}

const TicketOptionsItem = ({
  tickets,
  selectedTicket,
  selectedTicketsWithOptions,
  setSelectedTicketsWithOptions,
  order,
}: TicketOptionsItemProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  const currency =
    (searchParams.get('cu') as keyof typeof CURRENCY) ?? CURRENCY.EUR;

  const ticket = useMemo(
    () => tickets?.find(ticket => ticket.id === selectedTicket.ticket),
    [selectedTicket.ticket, tickets]
  );
  const isAssigned = selectedTicket?.isAssignedToMe;

  const handleAssignSwitcher = useCallback(() => {
    const newSelectedTicketsWithOptions = selectedTicketsWithOptions?.map(
      (el, index) => {
        if (index === order) {
          return {
            ...el,
            isAssignedToMe: !el.isAssignedToMe,
          };
        }
        return el;
      }
    );
    setSelectedTicketsWithOptions(newSelectedTicketsWithOptions);
  }, [order, selectedTicketsWithOptions, setSelectedTicketsWithOptions]);

  const handleOptionToggle = useCallback(
    (option: ExtraOption) => {
      const isOptionSelected = Boolean(
        selectedTicketsWithOptions[order].options
          ?.map(e => e.option)
          .includes(option.id)
      );
      if (!isOptionSelected) {
        const newSelectedTicketsWithOptions = selectedTicketsWithOptions?.map(
          (el, index) => {
            if (index === order) {
              return {
                ...el,
                options: [...(el.options ?? []), { option: option.id }],
              };
            }
            return el;
          }
        );
        setSelectedTicketsWithOptions(newSelectedTicketsWithOptions);
      } else {
        const newSelectedTicketsWithOptions = selectedTicketsWithOptions?.map(
          (el, index) => {
            if (index === order) {
              return {
                ...el,
                options: el.options?.filter(e => e.option !== option.id),
              };
            }
            return el;
          }
        );
        setSelectedTicketsWithOptions(newSelectedTicketsWithOptions);
      }
    },
    [order, selectedTicketsWithOptions, setSelectedTicketsWithOptions]
  );

  return (
    <>
      <TicketOptionsItemRoot
        borderBottomRightRadius={theme.borderRadius.radius5}
        boxShadow={`0 0.2rem 0 rgba(0, 0, 0, 0.1)`}
        borderRight={`0.1rem solid ${theme.color.grey1}`}
        borderLeft={`0.3rem solid ${theme.color.greyDark}`}
      >
        <TicketTitle>
          <PBold>{ticket?.name}</PBold>
          <AssignToContainer>
            <Checkbox
              label={
                <RowAlignedItems>
                  <PSmall>{t('ticket-is-for-me')}</PSmall>
                </RowAlignedItems>
              }
              spacing={theme.spacing.size5}
              checked={isAssigned}
              type="round"
              size="1.8rem"
              backgroundColor={
                isAssigned ? theme.color.greyDark : theme.color.white
              }
              color={isAssigned ? theme.color.white : theme.color.transparent}
              border={
                isAssigned
                  ? `0.2rem solid ${theme.color.greyDark}`
                  : `0.2rem solid ${theme.color.greyMediumDark}`
              }
              onChange={() => {
                if (!isAssigned) {
                  handleAssignSwitcher();
                }
              }}
            />
            <Checkbox
              label={
                <RowAlignedItems>
                  <PSmall>{t('ticket-for-someone-else')}</PSmall>
                </RowAlignedItems>
              }
              spacing={theme.spacing.size5}
              checked={!isAssigned}
              type="round"
              size="1.8rem"
              backgroundColor={
                !isAssigned ? theme.color.greyDark : theme.color.white
              }
              color={!isAssigned ? theme.color.white : theme.color.transparent}
              border={
                !isAssigned
                  ? `0.2rem solid ${theme.color.greyDark}`
                  : `0.2rem solid ${theme.color.greyMediumDark}`
              }
              onChange={() => {
                if (isAssigned) {
                  handleAssignSwitcher();
                }
              }}
            />
          </AssignToContainer>
        </TicketTitle>

        {ticket?.extra_options?.map((option, index) => {
          const fullPrice = option?.price;
          const isOptionSelected = Boolean(
            selectedTicketsWithOptions[order].options
              ?.map(e => e.option)
              .includes(option.id)
          );
          return (
            <OptionItem
              key={`${option.id}-${index}`}
              isLastItem={index === ticket?.extra_options?.length - 1}
            >
              <AddOptionWrapper gap="1.5rem">
                <P>{option?.name}</P>
                <PopoverWrapper>
                  <TextPopover text={option?.description} />
                </PopoverWrapper>
              </AddOptionWrapper>
              <AddOptionWrapper gap="1.5rem">
                <P>
                  {fullPrice !== 0
                    ? `${formatPrice(fullPrice, currency)}`
                    : t('free').toUpperCase()}
                </P>
                <RowAlignedItems>
                  <Checkbox
                    label={
                      <RowAlignedItems>
                        <PSmall>
                          {isOptionSelected ? t('added') : t('add')}
                        </PSmall>
                      </RowAlignedItems>
                    }
                    spacing={theme.spacing.size5}
                    checked={isOptionSelected}
                    type="square"
                    size="1.8rem"
                    backgroundColor={
                      isOptionSelected ? theme.color.green : theme.color.white
                    }
                    color={
                      isOptionSelected
                        ? theme.color.white
                        : theme.color.transparent
                    }
                    border={
                      isOptionSelected
                        ? `0.2rem solid ${theme.color.green}`
                        : `0.2rem solid ${theme.color.greyDark}`
                    }
                    onChange={() => {
                      handleOptionToggle(option);
                    }}
                  />
                </RowAlignedItems>
              </AddOptionWrapper>
            </OptionItem>
          );
        })}
      </TicketOptionsItemRoot>
    </>
  );
};

export default TicketOptionsItem;
