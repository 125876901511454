import { REGEX } from '@constants/regex';
import i18n from '../i18n/index';
export const postalCodeValidationRule = {
  pattern: {
    value: REGEX.POSTAL_CODE,
    message: 'invalid-postal-code',
  },
};

export const emailValidationRule = {
  pattern: {
    value: REGEX.EMAIL,
    message: 'admin-form-errors.provide-correct-email',
  },
};
export const instagramValidationRule = {
  pattern: {
    value: REGEX.INSTAGRAM,
    message: 'admin-form-errors.provide-correct-instagram',
  },
};
export const facebookValidationRule = {
  pattern: {
    value: REGEX.FACEBOOK,
    message: 'admin-form-errors.provide-correct-facebook',
  },
};
export const phoneValidationRule = {
  pattern: {
    value: REGEX.PHONE,
    message: 'admin-form-errors.provide-correct-phone',
  },
};

export const requiredValidationRule = {
  required: {
    value: true,
    message: 'admin-form-errors.required-field',
  },
  validate: (value: string | number) => {
    if (typeof value !== 'string') return true;
    else {
      return value?.trim()?.length > 0
        ? true
        : 'admin-form-errors.required-field';
    }
  },
} as const;

export const httpsValidationRule = {
  pattern: {
    value: REGEX.HTTPS,
    message: 'admin-form-errors.incorrect-https',
  },
};

export const websiteValidationRule = {
  pattern: {
    value: REGEX.WEBPAGE,
    message: 'admin-form-errors.incorrect-website-address',
  },
};

export const alphaNumericValidationRule = {
  pattern: {
    value: REGEX.ALPHANUMERIC,
    message: 'admin-form-errors.alphanumeric',
  },
};
export const numericValidationRule = {
  pattern: {
    value: REGEX.NUMBER,
    message: 'admin-form-errors.numeric',
  },
};
export const getMinLengthRule = (minLength: number, fieldName?: string) => {
  return {
    minLength: {
      value: minLength,
      message: i18n.t(
        fieldName !== null
          ? 'admin-form-errors.min-named-input-length'
          : 'admin-form-errors.min-input-length',
        { minLength, fieldName }
      ),
    },
  };
};

export const getMinPhoneLengthRule = (
  minLength: number,
  fieldName?: string
) => ({
  minLength: {
    value: minLength,
    message: i18n.t('admin-form-errors.min-phone-number-input-length', {
      minLength,
      fieldName,
    }),
  },
});

export const getMaxValueRule = (max: number, fieldName?: string) => ({
  max: {
    value: max,
    message: i18n.t(
      fieldName !== null
        ? 'admin-form-errors.max-named-value'
        : 'admin-form-errors.max-value',
      { max, fieldName }
    ),
  },
});

export const getMinValueRule = (min: number, fieldName?: string) => ({
  min: {
    value: min,
    message: i18n.t(
      fieldName !== null
        ? 'admin-form-errors.min-named-value'
        : 'admin-form-errors.min-value',
      { min, fieldName }
    ),
  },
});

export const getMinMaxValueRules = (
  min: number,
  max: number,
  fieldName?: string
) => {
  return {
    ...getMinValueRule(min, fieldName),
    ...getMaxValueRule(max, fieldName),
  };
};

export const getMaxLengthRule = (maxLength: number, fieldName?: string) => ({
  maxLength: {
    value: maxLength,
    message: i18n.t(
      fieldName !== null
        ? 'admin-form-errors.max-named-input-length'
        : 'admin-form-errors.max-input-length',
      { maxLength, fieldName }
    ),
  },
});

export const getMinMaxLengthRules = (
  minLength: number,
  maxLength: number,
  fieldName?: string
) => {
  return {
    ...getMinLengthRule(minLength, fieldName),
    ...getMaxLengthRule(maxLength, fieldName),
  };
};

export const getChangedValues = <T>(
  defaultValues: Record<string, T>,
  currentValues: Record<string, T>
): Record<string, T> =>
  Object.keys(currentValues).reduce(
    (acc, cur) =>
      defaultValues?.[cur] === currentValues[cur]
        ? acc
        : { ...acc, [cur]: currentValues[cur] },
    {}
  );
