import TicketSkeleton from '@pages/Purchase/components/TicketDetailsStep/components/TicketSkeleton';

interface TicketSkeletonContainerProps {
  numberOfSkeletons: number;
}

const TicketSkeletonContainer = ({
  numberOfSkeletons,
}: TicketSkeletonContainerProps) => {
  return (
    <>
      {Array(numberOfSkeletons)
        .fill(0)
        .map((_, index) => (
          <TicketSkeleton key={index} />
        ))}
    </>
  );
};

export default TicketSkeletonContainer;
