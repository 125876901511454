import { RowAlignedItems } from '@components/LinearLayout';
import { H5, P } from '@components/Typography';
import styled from 'styled-components';

export const BodyContainer = styled.div``;

export const HeaderContainer = styled(RowAlignedItems)<{
  isSelected: boolean;
  hasChildren: boolean;
}>`
  ${props =>
    props.isSelected &&
    props.hasChildren &&
    `border-bottom: 0.1rem solid
 ${props.theme.color.greyMedium}`};
  gap: 1rem;
  padding: 1.2rem 1rem;
`;

export const IconWrapper = styled(RowAlignedItems)`
  border-right: 0.1rem solid ${props => props.theme.color.grey1};
  padding: 0.6rem 1.5rem 0.4rem 1rem;
`;

export const ItemText = styled(H5)`
  margin: 0 1rem;
`;

export const ItemTextP = styled(P)`
  margin: 0 1rem;
`;
