import {
  OrderDetailsPageTitleWrapper,
  SubtitleWrapper,
} from '@pages/OrderDetails/components/OrderDetailsPageTitle/styles';

import { LineSkeleton } from '@components/SkeletonsLoaders';
import { TitleWrapper } from '@pages/Purchase/styles';
import { usePublicTicketDetails } from '@utils/apiHooks';
import { useHookParams } from '@utils/hooks';
import { useTranslation } from 'react-i18next';

const OrderDetailsPageTitle = () => {
  const { t } = useTranslation();
  const [id, access] = useHookParams(['ref', 'token']);
  const { data: bookingDetails, isLoading } = usePublicTicketDetails({
    id,
    access,
  });

  return (
    <OrderDetailsPageTitleWrapper>
      <TitleWrapper>
        {bookingDetails?.payment_status === 'paid'
          ? t(' booking-details')
          : t('your-order-details')}
      </TitleWrapper>
      <SubtitleWrapper data-testid="booking:title">
        {isLoading ? (
          <LineSkeleton width="50%" height="2rem" />
        ) : bookingDetails?.payment_status === 'paid' ? (
          t('booking-number', {
            booking_number: bookingDetails?.code,
            booking_date: bookingDetails?.booking_date,
          })
        ) : null}
      </SubtitleWrapper>
    </OrderDetailsPageTitleWrapper>
  );
};

export default OrderDetailsPageTitle;
