import { BoxDecoration } from '@components/BoxDecoration';
import PublicBookingDetailsSection from '@components/PublicBookingDetailsSection';
import { H5 } from '@components/Typography';
import BookingDetailsSkeleton from '@pages/OrderDetails/components/PublicBookingDetails/components/BookingDetailsSkeleton';
import { PriceBody } from '@pages/Purchase/components/AttendeeDetailsStep/styles';
import { usePublicTicketDetails } from '@utils/apiHooks';
import { useHookParams } from '@utils/hooks';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

const PublicBookingDetails = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [id, access] = useHookParams(['ref', 'token']);
  const { data: bookingDetails, isLoading } = usePublicTicketDetails({
    id,
    access,
  });

  if (isLoading) return <BookingDetailsSkeleton numberOfSkeletons={3} />;

  if (!bookingDetails?.total) return null;

  return (
    <BoxDecoration
      padding="2rem"
      bgColor={theme.color.white}
      borderRadius={theme.borderRadius.radius10}
      width="100%"
      boxShadow="0 0.2rem 0 rgba(0, 0, 0, 0.1)"
      border={`0.1rem solid ${theme.color.grey1}`}
    >
      <PriceBody gap="0.5rem">
        <H5>
          {bookingDetails?.payment_status === 'paid'
            ? t(' booking-details')
            : t('your-order-details')}
        </H5>
        {bookingDetails && (
          <PublicBookingDetailsSection bookingDetails={bookingDetails} />
        )}
      </PriceBody>
    </BoxDecoration>
  );
};

export default PublicBookingDetails;
