import { BoxDecoration } from '@components/BoxDecoration';
import { PCapitalized } from '@components/CardContainerWithIcon/styles';
import { Column } from '@components/LinearLayout';
import { H5, P } from '@components/Typography';
import CustomerDetailsSkeleton from '@pages/OrderDetails/components/CustomerDetails/components/CustomerDetailsSkeleton';
import { usePublicTicketDetails } from '@utils/apiHooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

const CustomerDetails = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const search = window.location.search;
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const ref = useMemo(() => params.get('ref') ?? '', [params]);
  const access = useMemo(() => params.get('token') ?? '', [params]);

  const { data: bookingDetails, isLoading } = usePublicTicketDetails({
    id: ref,
    access,
  });

  return (
    <BoxDecoration
      padding="2rem"
      bgColor={theme.color.white}
      borderRadius={theme.borderRadius.radius10}
      width="100%"
      boxShadow="0 0.2rem 0 rgba(0, 0, 0, 0.1)"
      border={`0.1rem solid ${theme.color.grey1}`}
    >
      {isLoading ? (
        <CustomerDetailsSkeleton />
      ) : (
        <Column gap="2rem">
          <H5>{t('customer-details')}</H5>
          <Column gap="0.3rem">
            <PCapitalized data-testid={`customer-details-name`}>{`${
              bookingDetails?.invoice?.firstName ?? ''
            } ${bookingDetails?.invoice?.lastName ?? ''}`}</PCapitalized>
            <PCapitalized data-testid={`customer-details-company-name`}>{`${
              bookingDetails?.invoice?.companyName ?? ''
            } 
          `}</PCapitalized>
            <PCapitalized data-testid={`customer-details-street`}>{`${
              bookingDetails?.invoice?.street ?? ''
            }
          `}</PCapitalized>
            <PCapitalized
              data-testid={`customer-details-additional-information`}
            >{`${bookingDetails?.invoice?.additionalDetails ?? ''}
          `}</PCapitalized>
            <PCapitalized data-testid={`customer-details-postcode`}>{`${
              bookingDetails?.invoice?.postalCode ?? ''
            } ${bookingDetails?.invoice?.state ?? ''} 
          ${bookingDetails?.invoice?.city ?? ''} 
          `}</PCapitalized>
            <PCapitalized data-testid={`customer-details-country`}>{`${
              bookingDetails?.invoice?.country ?? ''
            } 
          `}</PCapitalized>
          </Column>
          <Column gap="0.3rem">
            <P data-testid={`customer-details-email`}>{`${
              bookingDetails?.invoice?.email ?? ''
            } 
          `}</P>
            <P data-testid={`customer-details-mobile`}>{`${
              bookingDetails?.invoice?.mobile ?? ''
            } 
          `}</P>
            <P data-testid={`customer-details-phone`}>{`${
              bookingDetails?.invoice?.phone ?? ''
            } 
          `}</P>
          </Column>
          <Column gap="0.3rem">
            <P data-testid={`customer-details-vat-number`}>{`${t('vat_id')} ${
              bookingDetails?.invoice?.vatId ?? ''
            }`}</P>
            <P data-testid={`customer-details-po-number`}>{`${t('po_number')} ${
              bookingDetails?.invoice?.poNumber ?? ''
            }`}</P>
          </Column>
        </Column>
      )}
    </BoxDecoration>
  );
};

export default CustomerDetails;
