import { LANGUAGE, type LANGUAGE_TYPE } from '@constants/generic';
import { Home, NotFound, ROUTES } from '@constants/routes';
import { Suspense, useEffect } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';

import OrderDetails from '@pages/OrderDetails';
import PublicOrderDetails from '@pages/OrderDetails/Public';
import Purchase from '@pages/Purchase';
import HomeLayout from '@routes/layouts/HomeLayout';
import { useTranslation } from 'react-i18next';
import LoadingFallBack from './layouts/components/LoadingFallback';
import { HomeContent } from './styles';

interface RoutesRootProps {
  themeToggler: () => void;
}

const RoutesRoot = ({ themeToggler }: RoutesRootProps) => {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const language = searchParams.get('la') as (typeof LANGUAGE_TYPE)[number];

  useEffect(() => {
    if (language) {
      if (Object.values(LANGUAGE)?.includes(language)) {
        void i18n.changeLanguage(language);
        localStorage.setItem('language', language);
      }
    }
  }, [i18n, language]);

  useEffect(() => {
    window.addEventListener('load', function () {
      setInterval(() => {
        const message = {
          height: document.body.scrollHeight,
          width: document.body.scrollWidth,
        };

        window.top?.postMessage(message, '*');
      }, 2000);
    });
  }, []);

  return (
    <HomeLayout themeToggler={themeToggler}>
      <HomeContent>
        <Suspense fallback={<LoadingFallBack />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path={String(t(ROUTES.PURCHASE))} element={<Purchase />}>
              <Route path=":eventSlug" element={<Purchase />} />
            </Route>
            <Route
              path={String(t(ROUTES.ORDER_DETAILS))}
              element={<OrderDetails />}
            />
            <Route
              path={`${t(ROUTES.PUBLIC_ORDER_DETAILS, {
                eventSlug: ':eventSlug',
              })}`}
              element={<PublicOrderDetails />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </HomeContent>
    </HomeLayout>
  );
};

export default RoutesRoot;
