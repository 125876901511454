import styled from 'styled-components';

export const Root = styled.div<{ margin: string }>`
  display: flex;
  align-items: flex-start;
  font-size: ${props => props.theme.fontSize.size12};
  margin: ${props => props.margin};
`;

export const HintComponent = styled.div`
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.fontSize.size12};
  padding-left: 0.5rem;
  margin-top: 0.25rem;
`;

export const Message = styled.span<{ type: 'hint' | 'error' }>`
  color: ${props => {
    if (props.type === 'hint') {
      return props.theme.color.grey2;
    }
    return props.theme.color.error;
  }};
  line-height: ${props => props.theme.fontSize.size16};
  text-align: left;
`;

export const HintIconWrapper = styled.div`
  border-radius: 50%;
  i {
    height: 2rem;
    width: 2rem;
  }
`;

export const ErrorIconWrapper = styled.div`
  margin-right: 0.5rem;
  i {
    height: 1rem;
    width: 1rem;
  }
`;
