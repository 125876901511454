import Icon from '@components/Icon';
import { Column, RowAlignedItems } from '@components/LinearLayout';
import { P } from '@components/Typography';
import { type OrderedTicket } from '@hubs101/booking-api-skd-client/lib/types';
import {
  AlignedText,
  AssignTicketText,
  AssignableTicket,
  ColumnWithBottomMargin,
  HighlightedGreenText,
  HighlightedOrangeText,
  HighlightedText,
  RowAlignedItemsColumn,
} from '@pages/Purchase/components/PaymentDetailsStep/styles';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { PBoldWithBottomMargin } from './style';
import { BOOKING_STATUS } from '@constants/generic';

interface AssignTicketProps {
  ticket: OrderedTicket;
  setSelectedTicket: (ticket: OrderedTicket) => void;
  testId?: string;
  bookingStatus: string;
}

const AssignTicket = ({
  ticket,
  testId,
  setSelectedTicket,
  bookingStatus,
}: AssignTicketProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <AssignableTicket
      id={ticket?.id}
      boxShadow={`0 0.2rem 0 rgba(0, 0, 0, 0.1)`}
      padding="1rem 2rem"
      borderTopRightRadius={theme.borderRadius.radius5}
      borderBottomRightRadius={theme.borderRadius.radius5}
      borderRight={`0.1rem solid ${theme.color.grey1}`}
      borderTop={`0.1rem solid ${theme.color.grey1}`}
      borderBottom={`0.1rem solid ${theme.color.grey1}`}
      borderLeft={`0.3rem solid ${theme.color.greyDark}`}
    >
      <RowAlignedItemsColumn gap="2rem">
        <Icon name="ticket" size="3rem" color={theme.color.greyDark} />

        <Column>
          <PBoldWithBottomMargin
            withBottomMargin={!!ticket?.extraOptions}
            data-testid={`${testId ?? ''}-name`}
          >
            {ticket?.name}
          </PBoldWithBottomMargin>

          <P data-testid={`${testId ?? ''}-includedOptions`}>
            {ticket?.includedOptions?.length > 0 && `Incl. `}
            {ticket?.includedOptions
              ?.map(option => `${option?.name ?? ''}`)
              ?.join(', ')}
          </P>

          <P data-testid={`${testId ?? ''}-extraOptions`}>
            {ticket?.extraOptions?.length > 0 && `${t('extras')}: `}
            {ticket?.extraOptions?.map(option => `${option?.name ?? ''} `)}
          </P>
          <P />
        </Column>
        <ColumnWithBottomMargin>
          {(ticket?.assigned?.confirmed_at && (
            <HighlightedGreenText data-testid={`${testId ?? ''}-assigned-to`}>
              {t('assigned-to', {
                name: `${String(ticket?.assigned?.first_name)} ${String(
                  ticket?.assigned?.last_name
                )}`,
              })}
            </HighlightedGreenText>
          )) ??
            (ticket?.assigned && (
              <HighlightedOrangeText data-testid={`${testId ?? ''}-sent-to`}>
                {t('sent-to', {
                  name: `${String(ticket?.assigned?.first_name)} ${String(
                    ticket?.assigned?.last_name
                  )}`,
                })}
              </HighlightedOrangeText>
            )) ?? (
              <HighlightedText data-testid={`${testId ?? ''}-not-assigned`}>
                {t('not-assigned')}
              </HighlightedText>
            )}
        </ColumnWithBottomMargin>
      </RowAlignedItemsColumn>
      <RowAlignedItems>
        {!ticket?.assigned && (
          <AlignedText>
            <AssignTicketText
              data-testid={'booking-details:assign-ticket'}
              disabled={bookingStatus !== BOOKING_STATUS.confirmed}
              onClick={() => {
                if (bookingStatus === BOOKING_STATUS.confirmed) {
                  setSelectedTicket(ticket);
                }
              }}
            >
              {t('assign-ticket-to-attendee')}
            </AssignTicketText>
          </AlignedText>
        )}
        {ticket?.assigned?.confirmed_at && (
          <AlignedText>
            <AssignTicketText
              onClick={() => {
                // TODO: add download ticket functionality
              }}
              data-testid={`${testId ?? ''}-download`}
            >
              {t('ticket-download')}
            </AssignTicketText>
          </AlignedText>
        )}
      </RowAlignedItems>
    </AssignableTicket>
  );
};

export default AssignTicket;
