import Button, { ButtonSizes, ButtonStyle } from '@components/Button';
import {
  type BookingsFormInput,
  type PublicEvent,
} from '@hubs101/booking-api-skd-client/lib/types';
import { usePublicEvents, usePublicTicketDetails } from '@utils/apiHooks';
import { useEffect, useMemo } from 'react';

import CardContainerWithIcon from '@components/CardContainerWithIcon';
import { Column } from '@components/LinearLayout';
import AssignTicketsToAttendees from '@pages/OrderDetails/components/AssignTicketsToAttendees';
import CustomerDetails from '@pages/OrderDetails/components/CustomerDetails';
import OrderDetailsPageTitle from '@pages/OrderDetails/components/OrderDetailsPageTitle';
import BookingDetails from '@pages/OrderDetails/components/PublicBookingDetails';
import ShareEvent from '@pages/OrderDetails/components/ShareEvent';
import { MoreTickets } from '@pages/Purchase/components/PaymentDetailsStep/styles';
import { useHookParams } from '@utils/hooks';
import { formatDateRange } from '@utils/time';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

const OrderDetails = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [id, access] = useHookParams(['ref', 'token']);

  const { data: bookingDetails, isLoading: isBookingDetailsLoading } =
    usePublicTicketDetails({ id, access });

  const eventSlug =
    window?.location.pathname?.replace?.('/bookings/', '') ?? '';

  const isBookingPaid = useMemo(
    () => bookingDetails?.total === 0 || !!bookingDetails?.last_payment_date,
    [bookingDetails?.last_payment_date, bookingDetails?.total]
  );

  const { data, isLoading: isEventLoading } = usePublicEvents({
    page: 0,
    slug: eventSlug,
  });

  const bookingEventDetails = data?.events?.result?.data?.[0] as PublicEvent;

  const fullName = useMemo(
    () =>
      [
        bookingDetails?.invoice?.salutation,
        bookingDetails?.invoice?.firstName,
        bookingDetails?.invoice?.lastName,
      ]
        ?.filter(e => Number(e?.length) > 0)
        .join(' ') ?? '',

    [
      bookingDetails?.invoice?.firstName,
      bookingDetails?.invoice?.lastName,
      bookingDetails?.invoice?.salutation,
    ]
  );
  const subtitle2 = useMemo(() => {
    return (
      `${t('venue')} ` +
      [
        `${bookingEventDetails?.location?.name ?? ''}`,
        `${bookingEventDetails?.location?.marker?.street ?? ''}`,
        `${bookingEventDetails?.location?.marker?.city ?? ''}`,
        bookingEventDetails?.location?.marker?.country
          ? t(
              `countries.${
                bookingEventDetails?.location?.marker?.country ?? ''
              }`
            )
          : '',
      ]
        ?.filter(e => e.length > 0)
        .join(', ')
    );
  }, [
    t,
    bookingEventDetails?.location?.name,
    bookingEventDetails?.location?.marker?.street,
    bookingEventDetails?.location?.marker?.city,
    bookingEventDetails?.location?.marker?.country,
  ]);

  const defaultValues: BookingsFormInput = useMemo(
    () => ({
      salutation: bookingDetails?.attendees?.[0]?.salutation ?? '',
      first_name: bookingDetails?.attendees?.[0]?.firstName ?? '',
      last_name: bookingDetails?.attendees?.[0]?.lastName ?? '',
      job_title: bookingDetails?.attendees?.[0]?.company?.jobTitle ?? '',
      company_name: bookingDetails?.attendees?.[0]?.company?.name ?? '',
      job_level: bookingDetails?.attendees?.[0]?.company?.jobLevel ?? '',
      job_responsibility:
        bookingDetails?.attendees?.[0]?.company?.jobResponsibility ?? '',
      company_size: bookingDetails?.attendees?.[0]?.company?.size ?? '',
      company_industry: bookingDetails?.attendees?.[0]?.company?.industry ?? '',
      address_country: bookingDetails?.invoice?.country ?? '',
      contact_email: '',
      type_of_business: bookingDetails?.attendees?.[0]?.company?.business ?? '',
      vat_id: bookingDetails?.invoice?.vatId ?? '',
      po_number: bookingDetails?.invoice?.poNumber ?? '',
      invoice_full_name: fullName,
      invoice_email: bookingDetails?.invoice?.email ?? '',
      address_street: bookingDetails?.invoice?.street ?? '',
      additional_details: bookingDetails?.invoice?.additionalDetails ?? '',
      address_city: bookingDetails?.invoice?.city ?? '',
      address_postal_code: bookingDetails?.invoice?.postalCode ?? '',
      contact_phone:
        bookingDetails?.invoice?.phone ?? bookingDetails?.invoice?.mobile ?? '',
      card_number: '',
      card_holder: '',
      expiry_date: '',
      cvc: '',
    }),
    [
      bookingDetails?.attendees,
      bookingDetails?.invoice?.additionalDetails,
      bookingDetails?.invoice?.city,
      bookingDetails?.invoice?.country,
      bookingDetails?.invoice?.email,
      bookingDetails?.invoice?.mobile,
      bookingDetails?.invoice?.phone,
      bookingDetails?.invoice?.poNumber,
      bookingDetails?.invoice?.postalCode,
      bookingDetails?.invoice?.street,
      bookingDetails?.invoice?.vatId,
      fullName,
    ]
  );

  const {
    reset,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    return () => {
      reset(defaultValues);
    };
  }, [defaultValues, reset]);

  return (
    <Column gap="2.5rem">
      <OrderDetailsPageTitle />
      <CardContainerWithIcon
        iconName="shopping-cart"
        title={`${t('event')} ${bookingEventDetails?.name ?? ''}`}
        subtitle1={`${t('date')} ${formatDateRange(
          bookingEventDetails?.start_date ?? '',
          bookingEventDetails?.end_date ?? ''
        )}`}
        subtitle2={subtitle2}
        color={theme.color.greyDark}
        backgroundColor={theme.color.white}
        boxShadowIcon="0px 2px 0px rgba(0, 0, 0, 0.1)"
        isLoading={isEventLoading}
        dataTestId="event-details"
      />
      {bookingDetails?.last_payment_date && (
        <CardContainerWithIcon
          color={
            bookingDetails?.last_payment_date
              ? theme.color.green
              : theme.color.error
          }
          backgroundColor={
            bookingDetails?.last_payment_date
              ? theme.color.greenSoft
              : theme.color.redSoft
          }
          iconName={bookingDetails?.last_payment_date ? 'check' : 'attention'}
          title={
            bookingDetails?.last_payment_date ? t('paid') : t('pending-payment')
          }
          subtitle1={`${t('payment-method')}: ${
            bookingDetails?.payment_method
              ? t(`payment.${String(bookingDetails?.payment_method) ?? ''}`)
              : ''
          }`}
          subtitle2={
            bookingDetails?.last_payment_date
              ? `${t('payment-date')}: ${dayjs(
                  bookingDetails?.last_payment_date
                ).format('YYYY-MM-DD')}`
              : (t('due-date', {
                  date: dayjs(bookingDetails?.payment_due_date).format(
                    'YYYY-MM-DD'
                  ),
                }) ?? '')
          }
          boxShadowIcon="0 0.2rem 0 rgba(0, 0, 0, 0.1)"
          isLoading={isBookingDetailsLoading}
          dataTestId="event-payment-details"
        />
      )}
      <AssignTicketsToAttendees
        control={control}
        errors={errors}
        reset={reset}
        defaultValues={defaultValues}
        getValues={getValues}
      />
      <BookingDetails />
      <CustomerDetails />
      {isBookingPaid ? (
        <ShareEvent />
      ) : (
        <CardContainerWithIcon
          color={
            bookingDetails?.last_payment_date
              ? theme.color.green
              : theme.color.error
          }
          backgroundColor={
            bookingDetails?.last_payment_date
              ? theme.color.greenSoft
              : theme.color.redSoft
          }
          iconName="attention"
          title={t('booking-not-completed-yet')}
          centeredTitle
          boxShadowIcon="0 .02rem 0 rgba(0, 0, 0, 0.1)"
          isLoading={isBookingDetailsLoading}
          rightSideContent={
            <Button
              buttonSize={ButtonSizes.large}
              buttonStyle={ButtonStyle.default}
              content={t('pay-now')}
              onClick={() => {
                if (bookingDetails?.payment_url) {
                  window.open(bookingDetails?.payment_url, '_self');
                }
              }}
            />
          }
        />
      )}

      <MoreTickets
        onClick={() => {
          window.location.href = `${
            window?.location?.origin
          }/purchase/${eventSlug}?cu=${
            String(bookingDetails?.currency)?.toUpperCase() ?? 'EUR'
          }&la=en`;
        }}
      >
        {t('buy-more-tickets')}
      </MoreTickets>
    </Column>
  );
};

export default OrderDetails;
