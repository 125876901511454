import hubby_m_01 from '@assets/avatars/hubby_m_01.png';
import Button, { ButtonSizes, ButtonStyle } from '@components/Button';
import { Column } from '@components/LinearLayout';
import { H5, H6 } from '@components/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ErrorImg, Root, RootWrapper } from './styles';

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Root>
      <RootWrapper>
        <ErrorImg src={hubby_m_01} alt="error image" />
        <Column gap="1rem">
          <H5>{t('page-not-found')}</H5>
          <H6>{t('page-not-found-subtitle')}</H6>
        </Column>

        <Button
          buttonSize={ButtonSizes.large}
          buttonStyle={ButtonStyle.default}
          content={t('go-back-to-home-page')}
          onClick={() => {
            navigate('/');
          }}
        />
      </RootWrapper>
    </Root>
  );
};

export default NotFound;
