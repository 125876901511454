import { type DefaultTheme } from 'styled-components';

import { common } from './common';

const base: DefaultTheme = {
  ...common,
  color: {
    primary: '#148ABB',
    white: '#FFFFFF',
    black: '#000000',
    grey1: 'rgba(221, 223, 226)', // #dddfe2
    grey2: '#e5e6e8',
    grey3: '#ffdddd',
    divider: '#F5F5F7',
    success: '#00e52e',
    success2: '#7FBA7A',
    warning: '#f5d924',
    error: '#F16C4E',
    toastDefault: 'rgba(201, 221, 238, 0.8)',
    pending: '#FFCE73',
    textActive: '#4C5667',
    transparent: 'transparent',
    inputGrey: 'rgba(118, 118, 128, 0.12)',
    backgroundColor: 'rgba(20, 138, 187, 0.1)',
    headerBackgroundColor: '#FFFFFF',
    titleColor: '#4C5667',
    lightest: '#FAFAFB',
    lightDarkest: '#364154',
    darkest: ' #192232',
    hoverScrollBar: '#148ABB',
    scrollBar: 'rgba(20, 138, 187, 0.1)',
    scrollBarBackground: '#FFFFFF',
    componentBackground: '#FFFFFF',
    primaryText: '#4C5667',
    greyDark: 'rgb(76,86,103)', // '#4C5667'
    redSoft: 'rgba(241, 108, 78, 0.1)',
    redHard: 'rgba(241, 108, 78, 0.5)',
    orange: '#F16C4E',
    orangeSoft: 'rgba(255, 206, 115, 0.1)',
    green: '#7FBA7A',
    greenLight: '#BCEEB7',
    greenSoft: 'rgba(20, 138, 187, 0.1)',
    greyLight: 'rgb(245,245,247)', // '#F5F5F7'
    greyMedium: 'rgb(209, 213, 219)', // #D1D5DB
    greyMediumDark: 'rgba(178, 179, 189)', // #B2B3BD
    greyMediumDarkOp20: 'rgba(178, 179, 189, 0.2)',
    blackSoft: 'rgba(0, 0, 0, 0.07)',
    charcoal: '#333333',
    lightLush: 'rgba(253, 253, 253)', // #fdfdfd
    greenOp10: 'rgb(127,186,122, 0.1)',
    gradientGreen:
      'linear-gradient(0deg, rgba(127, 186, 122, 0.1), rgba(127, 186, 122, 0.1))',
    darkOrange: 'rgba(255, 206, 115, 1)',
  },
  type: 'light',
} as const;

export type BaseThemeType = typeof base;

export default base;
