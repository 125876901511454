import { Column, RowAlignedItems } from '@components/LinearLayout';
import { H6, P, PSmall } from '@components/Typography';
import styled from 'styled-components';

export const PriceSectionRoot = styled(Column)`
  background-color: ${props => props.theme.color.lightest};
  gap: 2rem;
  border-radius: ${props => props.theme.borderRadius.radius10};
  padding: 2rem 4rem;
`;

export const PriceItem = styled(RowAlignedItems)`
  justify-content: space-between;
`;
export const DiscountP = styled(P)`
  color: ${props => props.theme.color.error};
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: ${props => props.theme.color.grey1};
`;

export const StrikethroughText = styled(PSmall)`
  text-decoration-line: line-through;
`;

export const DiscountPrice = styled(P)`
  color: ${props => props.theme.color.success2};
`;

export const DiscountedTotalPrice = styled(H6)`
  color: ${props => props.theme.color.success2};
`;

export const FreeTicket = styled(H6)`
  color: ${props => props.theme.color.success2};
  text-transform: uppercase;
`;
