import styled from 'styled-components';
import { type BaseThemeType } from '../../theme/base';

export interface NotificationProps {
  notificationStyle: NotificationStyles;
  notificationSize: NotificationSizes;
  notificationPosition: NotificationPositions;
  prefixPosition?: NotificationPositions;
  prefix?: string;
  notification?: number;
  className?: string;
}

export enum NotificationSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}
export enum NotificationPositions {
  relative = 'relative',
  absolute = 'absolute',
}
export enum NotificationStyles {
  orange = 'orange',
  green = 'green',
}
type NotificationStyledProps = Pick<
  NotificationProps,
  'notificationPosition' | 'notificationSize' | 'notificationStyle'
> & {
  theme: BaseThemeType;
};
type NotificationPrefixStyledProps = Pick<
  NotificationProps,
  'notificationStyle' | 'prefixPosition'
> & {
  theme: BaseThemeType;
};
const NotificationStyled = styled.span`
  position: ${(props: NotificationStyledProps) => props.notificationPosition};
  ${(props: NotificationStyledProps) => {
    if (props.notificationSize === NotificationSizes.small) {
      return `
        height: ${props.theme.spacing.size8};
        width: ${props.theme.spacing.size8};
      `;
    }
    if (props.notificationSize === NotificationSizes.large) {
      return `
        min-height: ${props.theme.spacing.size24};
        min-width: ${props.theme.spacing.size24};
        padding: ${props.theme.spacing.size4} ${props.theme.spacing.size7};
      
      `;
    }
    if (props.notificationSize === NotificationSizes.medium) {
      return `
        min-height: ${props.theme.spacing.size16};
        min-width: ${props.theme.spacing.size16};
        padding: ${props.theme.spacing.size0c5} ${props.theme.spacing.size4};
      `;
    }
  }}
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: ${(props: NotificationStyledProps) => props.theme.font.inter};
  font-size: ${(props: NotificationStyledProps) => props.theme.fontSize.size12};
  font-weight: ${(props: NotificationStyledProps) =>
    props.theme.fontWeight.weight500};
  line-height: ${(props: NotificationStyledProps) =>
    props.theme.lineHeight.height125};
  color: ${(props: NotificationStyledProps) => props.theme.color.white};
  background: ${(props: NotificationStyledProps) =>
    props.notificationStyle === NotificationStyles.green
      ? props.theme.color.green
      : props.theme.color.orange};
  box-sizing: border-box;
  ${(props: NotificationStyledProps) => {
    if (
      props.notificationStyle === NotificationStyles.green &&
      props.notificationSize === NotificationSizes.large
    ) {
      return `border: ${props.theme.spacing.size3} solid ${props.theme.color.greenLight};`;
    }
  }}
  border-radius: ${(props: NotificationStyledProps) =>
    props.theme.borderRadius.radius50};
`;
const NotificationPrefixStyled = styled.span`
  font-family: ${(props: NotificationPrefixStyledProps) =>
    props.theme.font.inter};
  font-size: ${(props: NotificationPrefixStyledProps) =>
    props.theme.fontSize.size12};
  font-weight: ${(props: NotificationPrefixStyledProps) =>
    props.theme.fontWeight.weight500};
  line-height: ${(props: NotificationPrefixStyledProps) =>
    props.theme.lineHeight.height125};

  position: ${(props: NotificationPrefixStyledProps) => props.prefixPosition};
  color: ${(props: NotificationPrefixStyledProps) =>
    props.notificationStyle === NotificationStyles.green
      ? props.theme.color.green
      : props.theme.color.orange};
  right: calc(
    100% +
      ${(props: NotificationPrefixStyledProps) =>
        props.notificationStyle === NotificationStyles.green
          ? props.theme.fontSize.size8
          : props.theme.fontSize.size5}
  );
`;
const Notification = ({
  notificationSize = NotificationSizes.medium,
  notificationStyle,
  notificationPosition,
  notification = 0,
  prefix,
  prefixPosition,
  className,
}: NotificationProps) => {
  const notificationAmount = notification > 99 ? `99+` : notification;
  return (
    <NotificationStyled
      notificationSize={notificationSize}
      notificationStyle={notificationStyle}
      notificationPosition={notificationPosition}
      className={className}
    >
      {prefix != null && (
        <NotificationPrefixStyled
          notificationStyle={notificationStyle}
          prefixPosition={prefixPosition}
        >
          {prefix}
        </NotificationPrefixStyled>
      )}
      {notificationSize !== NotificationSizes.small && notificationAmount}
    </NotificationStyled>
  );
};

export default Notification;
