import { BoxDecoration } from '@components/BoxDecoration';
import Button, { ButtonSizes, ButtonStyle } from '@components/Button';
import Checkbox from '@components/Checkbox';
import Icon from '@components/Icon';
import { Column, RowAlignedItems } from '@components/LinearLayout';
import {
  H6,
  LinkExternalSmall,
  PSmall,
  SpanSmall,
} from '@components/Typography';
import { CenterItem } from '@pages/Purchase/components/AttendeeDetailsStep/styles';
import { useOutsideClickAlerter } from '@utils/useClickOutsideAlerter';
import {
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import {
  ModalCenterSection,
  ModalContent,
  ModalSection,
  ModalWrapper,
} from './styles';

export interface ModalProps {
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
  title: string;
  cancelButtonLabel?: string;
  primaryButtonLabel: string;
  disablePrimaryButton?: boolean;
  content: ReactNode;
  closeWhenClickOutside?: boolean;
  onPrimaryClick?: () => void;
}

const Modal = ({
  isOpen,
  setIsOpen,
  title,
  content,
  cancelButtonLabel,
  primaryButtonLabel,
  closeWhenClickOutside,
  disablePrimaryButton,
  onPrimaryClick,
}: ModalProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const wrapperRef = useRef(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);
  const [isCheckboxError, setIsCheckboxError] = useState<boolean>(false);

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleClickOutside = useCallback(() => {
    if (closeWhenClickOutside) {
      handleCloseModal();
    }
  }, [closeWhenClickOutside, handleCloseModal]);

  const handleCheckboxClick = useCallback(() => {
    setIsCheckboxChecked(!isCheckboxChecked);
    setIsCheckboxError(false);
  }, [isCheckboxChecked]);

  const handlePrimaryClick = useCallback(() => {
    if (disablePrimaryButton) return;
    if (!isCheckboxChecked) {
      setIsCheckboxError(true);
    } else {
      onPrimaryClick?.();
    }
  }, [disablePrimaryButton, isCheckboxChecked, onPrimaryClick]);

  useOutsideClickAlerter(wrapperRef, handleClickOutside);

  useEffect(() => {
    if (isOpen) {
      if (document) {
        document.body.style.overflow = 'hidden';
      }
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <ModalWrapper>
      <ModalContent ref={wrapperRef}>
        <ModalSection>
          <H6>{title}</H6>
          <Icon
            name="close"
            color={theme.color.titleColor}
            size="2.5rem"
            onClick={handleCloseModal}
          />
        </ModalSection>
        <ModalCenterSection>
          <Column gap="2rem">
            {content}
            <Column gap="2rem">
              <BoxDecoration
                padding="1.5rem"
                bgColor={theme.color.white}
                borderRadius={theme.borderRadius.radius5}
                width="100%"
                border={`0.1rem solid ${theme.color.grey1}`}
              >
                <Column gap="1rem">
                  <Checkbox
                    label={
                      <RowAlignedItems>
                        <PSmall>
                          I declare that I have read and agree with the consent
                          to the processing of advertising data by we.CONECT
                          Global Leaders GmbH and the
                          <LinkExternalSmall>
                            <SpanSmall color={theme.color.primary}>
                              {' '}
                              partners
                            </SpanSmall>
                          </LinkExternalSmall>{' '}
                          of the event. I can revoke my declaration of consent
                          at any time with effect for the future via
                          <LinkExternalSmall>
                            <SpanSmall color={theme.color.primary}>
                              {' '}
                              info@we-conect.com
                            </SpanSmall>
                          </LinkExternalSmall>
                          . The use of the data collected and processed by
                          we.CONECT and the partners of the event is subject to
                          the{' '}
                          <LinkExternalSmall>
                            <SpanSmall color={theme.color.primary}>
                              {' '}
                              privacy policy
                            </SpanSmall>
                          </LinkExternalSmall>{' '}
                          of the respective partner after further processing (by
                          we.conect). The following partners, contact details
                          and opt-out possibilities can be found{' '}
                          <LinkExternalSmall>
                            <SpanSmall color={theme.color.primary}>
                              {' '}
                              here
                            </SpanSmall>
                          </LinkExternalSmall>
                          .
                        </PSmall>
                      </RowAlignedItems>
                    }
                    spacing={theme.spacing.size5}
                    checked={isCheckboxChecked}
                    type="square"
                    size="1.8rem"
                    onChange={handleCheckboxClick}
                    alignItems="flex-start"
                    color={theme.color.primary}
                  />
                  {isCheckboxError && (
                    <PSmall color={theme.color.error}>
                      *{t('admin-form-errors.required-field')}
                    </PSmall>
                  )}
                </Column>
              </BoxDecoration>
              <PSmall>*{t('required')}</PSmall>
            </Column>
          </Column>
        </ModalCenterSection>
        <ModalSection>
          <Button
            buttonSize={ButtonSizes.medium}
            buttonStyle={ButtonStyle.default}
            backgroundColor={theme.color.grey1}
            color={theme.color.primaryText}
            content={
              <CenterItem>{cancelButtonLabel ?? t('cancel')}</CenterItem>
            }
            onClick={handleCloseModal}
          />
          <Button
            buttonSize={ButtonSizes.medium}
            buttonStyle={ButtonStyle.default}
            backgroundColor={theme.color.green}
            content={<CenterItem>{primaryButtonLabel}</CenterItem>}
            disabled={disablePrimaryButton}
            onClick={handlePrimaryClick}
          />
        </ModalSection>
      </ModalContent>
    </ModalWrapper>
  );
};

export default Modal;
