import AssignTicketSkeleton from '@pages/OrderDetails/components/AssignTicketsToAttendees/components/AssignTicketSkeleton';

interface AssignTicketContainerSkeletonProps {
  numberOfSkeletons: number;
}

const AssignTicketContainerSkeleton = ({
  numberOfSkeletons,
}: AssignTicketContainerSkeletonProps) => {
  return (
    <>
      {Array(numberOfSkeletons)
        .fill(0)
        .map((_, index) => (
          <AssignTicketSkeleton key={index} />
        ))}
    </>
  );
};

export default AssignTicketContainerSkeleton;
