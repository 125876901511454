import BoxDecoration from '@components/BoxDecoration';
import Checkbox from '@components/Checkbox';
import { type ReactNode } from 'react';
import { useTheme } from 'styled-components';

import {
  BodyContainer,
  HeaderContainer,
  IconWrapper,
  ItemText,
  ItemTextP,
} from './styles';

interface ExpandableInputProps {
  isSelected?: boolean;
  title?: string | JSX.Element | null;
  extraContent?: JSX.Element;
  children?: ReactNode;
  onClick?: () => void;
  type?: string;
}

const ExpandableInput = ({
  isSelected = false,
  title,
  extraContent,
  children,
  onClick,
  type,
}: ExpandableInputProps) => {
  const theme = useTheme();
  const hasChildren = Boolean(children);

  return (
    <BoxDecoration
      padding="0"
      bgColor={theme.color.white}
      borderRadius={theme.borderRadius.radius10}
      width="100%"
      boxShadow="0 0.2rem 0 rgba(0, 0, 0, 0.1)"
      border={`0.1rem solid ${theme.color.grey1}`}
      onClick={onClick}
    >
      <HeaderContainer isSelected={isSelected} hasChildren={hasChildren}>
        <IconWrapper>
          <Checkbox
            checked={isSelected}
            type="round"
            backgroundColor={isSelected ? theme.color.green : theme.color.white}
            color={isSelected ? theme.color.white : theme.color.transparent}
            border={
              isSelected
                ? `0.2rem solid ${theme.color.green}`
                : `0.2rem solid ${theme.color.greyDark}`
            }
            onChange={() => {
              /** */
            }}
          />
        </IconWrapper>

        {type?.toUpperCase() === 'P' ? (
          <ItemTextP>{title}</ItemTextP>
        ) : (
          <ItemText>{title}</ItemText>
        )}
        {extraContent}
      </HeaderContainer>
      {children && (
        <BodyContainer>{isSelected && <>{children}</>}</BodyContainer>
      )}
    </BoxDecoration>
  );
};

export default ExpandableInput;
