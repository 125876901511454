import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import base from './base';
// const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)').matches;

const GlobalStyle = createGlobalStyle`
  ${reset}
  
  html {
    box-sizing: border-box;
    font-size: 10px;
    width: 100%;
    height: 100%;
  }

  body {
    font-family: ${base.font.body};
    font-size: ${base.fontSize.size16};
    line-height: ${base.fontSize.size18};
    height: 100%;
    line-height: ${base.lineHeight.height125};
    color: ${base.color.grey1};
    overflow-y: auto;
    overflow-x: hidden;
      @media only screen and (max-width: ${props => base.breakpoint.width992}) {
     article {
        margin: 0 7rem;
      }
  }
  }
  
 

  input, select, textarea, button {
    font-family: inherit;
  }
#map {
  height: 100%;
}
  #root {
    height: 100%;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  *:focus {
    outline: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  [role="button"],
  button {
    cursor: pointer;
  }

  .rec-carousel-item {
    display: flex;
  }

  /* Needed for loading inline HTML content for description */
  h1 {
    font-family: ${props => base.font.header};
    font-size: ${props => base.fontSize.size35};
    color: ${props => base.color.grey1};
    font-weight: ${props => base.fontWeight.weight600};
    line-height: ${props => base.lineHeight.height100};
  }

  h2 {
    font-family: ${props => base.font.header};
    font-size: ${props => base.fontSize.size20};
    color: ${props => base.color.grey1};
    font-weight: ${props => base.fontWeight.weight600};
  }

  h3 {
    font-family: ${props => base.font.header};
    font-size: ${props => base.fontSize.size16};
    color: ${props => base.color.grey1};
    font-weight: ${props => base.fontWeight.weight600};
  }

  strong, b {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }
  ul { 
   list-style-type: disc; 
   list-style-position: inside; 
  }
  ol { 
    list-style-type: decimal; 
    list-style-position: inside; 
  }
  ul ul, ol ul { 
    list-style-type: circle; 
    list-style-position: inside; 
    margin-left: 1.5rem; 
  }
  ol ol, ul ol { 
    list-style-type: lower-latin; 
    list-style-position: inside; 
    margin-left: 1.5rem; 
  }
  .root-portal{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10001;
    font-family: ${props => base.font.header};
  }


  ::-webkit-scrollbar {
    width:0.8rem;
}
::-webkit-scrollbar-track {
  background:'transparent';
}
::-webkit-scrollbar-track:hover {
  background:'transparent';
}
::-webkit-scrollbar-thumb {    
  background: ${props => base.color.scrollBar};
  box-shadow: inset 0 0 0.4rem ${props => base.color.grey2};
  border-radius: ${props => base.borderRadius.radius5};
}
::-webkit-scrollbar-thumb:hover {
  background: ${props => base.color.hoverScrollBar};
  box-shadow: inset 0 0 0.4rem ${props => base.color.grey2};
  border-radius: ${props => base.borderRadius.radius5};
}




`;

export default GlobalStyle;
