import { RowAlignedItems } from '@components/LinearLayout';
import TicketContainer from '@composites/TicketContainer';
import styled from 'styled-components';

export const TicketOptionsItemRoot = styled(TicketContainer)`
  background-color: ${props => props.theme.color.white};
  display: flex;
  flex-direction: column;
`;

export const TicketTitle = styled.div`
  padding: 1rem 2rem;
  background-color: ${props => props.theme.color.lightest};
  border-bottom: 0.1rem solid ${props => props.theme.color.grey1};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
`;

export const OptionItem = styled.div<{ isLastItem?: boolean }>`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  gap: 0.5rem;
  background-color: ${props => props.theme.color.white};
  ${props =>
    props.isLastItem
      ? `border-bottom-right-radius: ${props.theme.borderRadius.radius5};`
      : `border-bottom: 0.1rem solid ${props.theme.color.grey1};`}
`;

export const InformationLogo = styled.img`
  height: 2rem;
  width: 2rem;
`;

export const AddOptionWrapper = styled(RowAlignedItems)`
  justify-content: space-between;
  position: relative;
`;

export const PopoverWrapper = styled(RowAlignedItems)`
  justify-content: space-between;
  position: relative;
`;

export const AssignToContainer = styled.div`
  display: flex;
  gap: 1.5rem;
`;
