import Providers from '@context/Providers';
import GlobalStyle from '@theme/GlobalStyle';
import ErrorBoundary, { FallBackUI } from '@utils/ErrorBoundary';
import { useDarkMode } from '@utils/hooks';
import { BrowserRouter } from 'react-router-dom';
import './i18n';

import Routes from './routes';

interface AppProps {
  themeToggler: () => void;
}

function App({ themeToggler }: AppProps) {
  return (
    <ErrorBoundary fallBackUIComponent={FallBackUI()}>
      <GlobalStyle />
      <Routes themeToggler={themeToggler} />
    </ErrorBoundary>
  );
}

export default function WithProviders() {
  const { theme, themeToggler } = useDarkMode();

  return (
    <BrowserRouter>
      <Providers theme={theme}>
        <App themeToggler={themeToggler} />
      </Providers>
    </BrowserRouter>
  );
}
