import { memo, type ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';

export interface BoxDecorationProps extends StyledBoxDecorationProps {
  children: ReactNode | string;
}

export interface StyledBoxDecorationProps {
  padding?: string;
  bgColor?: string;
  borderRadius?: string;
  width?: string;
  margin?: string;
  boxShadow?: string;
  border?: string;
  display?: string;
  minHeight?: string;
  alignItems?: string;
  onClick?: () => void;
}

const StyledBoxDecoration = styled.div<StyledBoxDecorationProps>`
  background-color: ${props => props.bgColor};
  border-radius: ${props => props.borderRadius};
  padding: ${props => props.padding ?? ''};
  width: ${props => props.width ?? ''};
  margin: ${props => props.margin ?? ''};
  box-shadow: ${props => props.boxShadow ?? ''};
  border: ${props => props.border ?? ''};
  cursor: ${props => props.onClick && 'pointer'};
  display: ${props => props.display ?? ''};
  min-height: ${props => props.minHeight ?? ''};
  align-items: ${props => props.alignItems ?? ''};
`;

export function BoxDecoration({ ...props }: BoxDecorationProps) {
  const theme = useTheme();
  return (
    <StyledBoxDecoration
      bgColor={theme.color.componentBackground}
      borderRadius={theme.borderRadius.radius20}
      {...props}
    >
      {props.children}
    </StyledBoxDecoration>
  );
}

export default memo(BoxDecoration);
