/* eslint-disable no-console */
import hubby_m_01 from '@assets/avatars/hubby_m_01.png';
import Button, { ButtonSizes, ButtonStyle } from '@components/Button';
import { Column } from '@components/LinearLayout';
import { H5, H6 } from '@components/Typography';
import { ErrorImg, RootWrapper } from '@pages/NotFound/styles';
import GlobalStyle from '@theme/GlobalStyle';
import { Component, type ErrorInfo, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Root = styled.div`
  height: 100%;
  display: grid;
  place-items: center;
  text-align: center;
`;

export default class ErrorBoundary extends Component<
  { children: ReactNode; fallBackUIComponent: JSX.Element },
  { hasError: boolean }
> {
  constructor(
    props:
      | { children: ReactNode; fallBackUIComponent: JSX.Element }
      | Readonly<{ children: ReactNode; fallBackUIComponent: JSX.Element }>
  ) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    console.log({ error });

    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallBackUIComponent;
    }
    return this.props.children;
  }
}

export const FallBackUI = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Root>
      <GlobalStyle />
      <RootWrapper>
        <ErrorImg src={hubby_m_01} alt="error image" />
        <Column gap="1rem">
          <H5>{t('something-went-wrong')}</H5>
          <H6>{` `}</H6>
        </Column>

        <Button
          buttonSize={ButtonSizes.large}
          buttonStyle={ButtonStyle.default}
          content={t('go-back-to-home-page')}
          onClick={() => {
            navigate('/');
            window.location.reload();
          }}
        />
      </RootWrapper>
    </Root>
  );
};
