import Icon from '@components/Icon';
import InputMetaInformation from '@components/InputMetaInformation';
import { Row } from '@components/LinearLayout';

import {
  FormItemChildren,
  FormItemLabel,
  FormItemRoot,
  MetaInformationWrapper,
} from './styles';

interface FormItemProps {
  children?: JSX.Element | JSX.Element[];
  label?: string | null | JSX.Element;
  hint?: string | JSX.Element;
  error?: string;
  width?: string;
  isLocked?: boolean;
  isEditable?: boolean;
  isCentered?: boolean;
  required?: boolean;
  paddingLabel?: string;
  spacingLabel?: string;
  dataTestId?: string;
}
const FormItem = ({
  children,
  label = '',
  hint,
  error,
  width = '100%',
  isLocked = false,
  isEditable = false,
  isCentered = false,
  required = false,
  paddingLabel = '0 0 0 2rem',
  spacingLabel = '0.3rem',
  dataTestId,
}: FormItemProps) => {
  return (
    <FormItemRoot
      data-testid={dataTestId}
      width={width}
      isLocked={isLocked}
      spacingLabel={spacingLabel}
      onClick={e => {
        if (isLocked) {
          e.preventDefault();
          e.stopPropagation();
          return false;
        }
      }}
    >
      {label && (
        <Row gap="1rem">
          <FormItemLabel
            isCentered={isCentered}
            required={required}
            paddingLabel={paddingLabel}
          >
            {label}
          </FormItemLabel>
          {isLocked && <Icon name="lock" size="1.6rem" />}
        </Row>
      )}
      {children && (
        <FormItemChildren isLocked={isLocked}>
          {children}
          <MetaInformationWrapper>
            <InputMetaInformation hint={hint} error={error} />
          </MetaInformationWrapper>
        </FormItemChildren>
      )}
    </FormItemRoot>
  );
};

export default FormItem;
