import { Column, RowAlignedItems } from '@components/LinearLayout';
import { H6, PSmall } from '@components/Typography';
import TicketContainer from '@composites/TicketContainer';
import styled from 'styled-components';

export const TicketCardItemRoot = styled(TicketContainer)`
  background-color: ${props => props.theme.color.white};
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    display: flex;
    flex-direction: column;
  }
`;

export const TicketTitle = styled(H6)`
  display: grid;
  place-items: center;
  width: 16rem;
  padding: 3rem 2rem;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    padding: 3rem 0 3rem 3rem;
    width: 100%;
    display: flex;
  }
`;
export const Price = styled(H6)`
  display: grid;
  place-items: center;
  width: 17rem;
  padding: 3rem 2rem;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    padding: 1rem 3rem 1rem 0;
    width: auto;
    display: flex;
    justify-content: end;
  }
`;

export const TicketQuantity = styled.div`
  display: grid;
  place-items: center;
  width: 14rem;
  height: 100%;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    display: flex;
    padding: 1rem 0 1rem 3rem;
    width: auto;
  }
`;

export const QuantityPriceWrapper = styled(RowAlignedItems)`
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

export const Counter = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  height: 3rem;
  border-radius: ${props => props.theme.borderRadius.radius10};
  border: 0.1rem solid ${props => props.theme.color.greyMedium};
`;

export const CounterDispatchRight = styled.div`
  display: grid;
  place-items: center;
  background-color: #f5f5f7;
  width: 2.4rem;
  border-top-right-radius: ${props => props.theme.borderRadius.radius10};
  border-bottom-right-radius: ${props => props.theme.borderRadius.radius10};
  color: ${props => props.theme.color.greyDark};
  border-left: 0.1rem solid ${props => props.theme.color.greyMedium};
  cursor: pointer;
`;

export const CounterDispatchLeft = styled.div`
  display: grid;
  place-items: center;
  background-color: #f5f5f7;
  width: 2.4rem;
  border-top-left-radius: ${props => props.theme.borderRadius.radius10};
  border-bottom-left-radius: ${props => props.theme.borderRadius.radius10};
  border-right: 0.1rem solid ${props => props.theme.color.greyMedium};
  color: ${props => props.theme.color.greyDark};
  cursor: pointer;
`;

export const TicketNumber = styled.div`
  display: grid;
  place-items: center;
  background-color: white;
  padding: 0 1rem;
  color: ${props => props.theme.color.greyDark};
`;

export const TicketOptions = styled(Column)`
  gap: 1rem;
  border-left: 1rem dashed #f5f5f7;
  padding: 1rem 3rem;
  justify-content: center;
  align-items: start;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    border-top: 1rem dashed #f5f5f7;
    border-left: none;
    padding: 2rem 3rem 1rem 3rem;
  }
`;

export const OptionItem = styled(RowAlignedItems)`
  gap: 0.3rem;
`;

export const IconImg = styled.img`
  height: 1.8rem;
  height: 1.8rem;
`;

export const OptionTitle = styled(PSmall)`
  font-weight: ${props => props.theme.fontWeight.weight400};
`;

export const LineThroughText = styled.span`
  text-decoration: line-through;
`;

export const DiscountedPriceH6 = styled.span`
  color: ${props => props.theme.color.success2};
`;
