import { LineSkeleton } from '@components/SkeletonsLoaders';
import { AssignableTicket } from '@pages/Purchase/components/PaymentDetailsStep/styles';
import { useTheme } from 'styled-components';

const AssignTicketSkeleton = () => {
  const theme = useTheme();

  return (
    <AssignableTicket
      boxShadow={`0 0.2rem 0 rgba(0, 0, 0, 0.1)`}
      padding="1rem 2rem"
      borderTopRightRadius={theme.borderRadius.radius5}
      borderBottomRightRadius={theme.borderRadius.radius5}
      borderRight={`0.1rem solid ${theme.color.grey1}`}
      borderTop={`0.1rem solid ${theme.color.grey1}`}
      borderBottom={`0.1rem solid ${theme.color.grey1}`}
      borderLeft={`0.3rem solid ${theme.color.greyDark}`}
    >
      <LineSkeleton width="100%" height="2rem" margin="0 0 1rem" />
      <LineSkeleton width="100%" height="1rem" />
    </AssignableTicket>
  );
};

export default AssignTicketSkeleton;
