export const LANGUAGE = {
  ENGLISH: 'en',
  GERMAN: 'de',
} as const;
export const LANGUAGE_TYPE = [LANGUAGE.ENGLISH, LANGUAGE.GERMAN] as const;
export const LANGUAGE_INTL = {
  en: 'en-GB',
  de: 'de-DE',
} as const;
export const LANGUAGE_INTL_TYPE = ['en-GB', 'de-DE'] as const;
export const CURRENCY = {
  EUR: 'EUR',
  USD: 'USD',
} as const;
export const CURRENCY_TYPE = [CURRENCY.EUR, CURRENCY.USD] as const;
export const HEADER_HEIGHT = '6rem';
export const CURRENCY_SYMBOLS = {
  EUR: '€',
  USD: '$',
};
export const BOOKING_STATUS = {
  pending: 'pending',
  confirmed: 'confirmed',
  reserved: 'reserved',
  cancelled: 'cancelled',
} as const;

export const DISPLAY_MODE = {
  TICKET: 'ticket',
  TICKET_GROUP: 'ticketGroup',
  TICKET_GROUPS: 'ticketGroups',
  ALL_TICKETS: 'allTickets',
} as const;

export const DISPLAY_MODE_TYPE = [
  DISPLAY_MODE.TICKET,
  DISPLAY_MODE.TICKET_GROUP,
  DISPLAY_MODE.TICKET_GROUPS,
  DISPLAY_MODE.ALL_TICKETS,
];
