import { Column, RowAlignedItems } from '@components/LinearLayout';
import { H5, P } from '@components/Typography';
import {
  type BookingsFormInput,
  type OrderedTicket,
} from '@hubs101/booking-api-skd-client/lib/types';
import { assignTicket, usePublicTicketDetails } from '@utils/apiHooks';
import {
  emailValidationRule,
  getMinMaxLengthRules,
  requiredValidationRule,
} from '@utils/form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  type Control,
  Controller,
  type FieldErrorsImpl,
  type UseFormGetValues,
  type UseFormReset,
} from 'react-hook-form';
import { AlignedRightText, AlignedText, AssignTicketText } from './style';

import { BoxDecoration } from '@components/BoxDecoration';
import Icon from '@components/Icon';
import Input from '@components/Input';
import Modal from '@components/Modal';
import FormItem from '@composites/FormItem';
import AssignTicketContainerSkeleton from '@pages/OrderDetails/components/AssignTicketsToAttendees/components/AssignTicketContainerSkeleton';
import { FormRow } from '@pages/Purchase/components/AttendeeDetailsStep/styles';
import { FormWrapper } from '@pages/Purchase/components/CustomerDetailsForm/styles';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import AssignTicket from './item';

interface AssignTicketsToAttendeesProps {
  control: Control<BookingsFormInput>;
  errors: Partial<FieldErrorsImpl<BookingsFormInput>>;
  defaultValues: BookingsFormInput;
  reset: UseFormReset<BookingsFormInput>;
  getValues: UseFormGetValues<BookingsFormInput>;
}

const AssignTicketsToAttendees = ({
  control,
  errors,
  defaultValues,
  reset,
  getValues,
}: AssignTicketsToAttendeesProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [selectedTicket, setSelectedTicket] = useState<OrderedTicket | null>(
    null
  );

  const search = window.location.search;
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const ref = useMemo(() => params.get('ref') ?? '', [params]);
  const access = useMemo(() => params.get('token') ?? '', [params]);

  const {
    data: bookingDetails,
    refetch,
    isLoading: isBookingDetailsLoading,
  } = usePublicTicketDetails({
    id: ref,
    access,
  });

  const title = useMemo(
    () =>
      `${selectedTicket?.name ?? ''} 
      ${selectedTicket?.extraOptions?.length ? `(${t('extras')}:` : ''}
      ${
        selectedTicket?.extraOptions
          ? selectedTicket?.extraOptions?.map(option => option?.name).join(', ')
          : ''
      } ${
        selectedTicket?.includedOptions?.length &&
        selectedTicket?.extraOptions?.length
          ? '+ '
          : ''
      }${
        selectedTicket?.includedOptions?.length
          ? selectedTicket?.includedOptions
              ?.map(option => `${option?.name ?? ''}`)
              ?.join(', ')
          : ''
      }
      ${selectedTicket?.extraOptions?.length ? ')' : ''}`,
    [
      selectedTicket?.extraOptions,
      selectedTicket?.includedOptions,
      selectedTicket?.name,
      t,
    ]
  );

  const onPrimaryAssignClick = useCallback(() => {
    const fn = async () => {
      if (bookingDetails?.id && selectedTicket?.reference && access) {
        const values = getValues();
        const result = await assignTicket({
          id: bookingDetails?.id,
          access,
          reference: selectedTicket?.reference,
          assignee: {
            email: values.contact_email,
            first_name: values.first_name,
            last_name: values.last_name,
            company_name: values.company_name,
          },
        });
        if (result) {
          setSelectedTicket(null);
        }
        void refetch();
      }
    };
    void fn();
  }, [
    access,
    bookingDetails?.id,
    getValues,
    refetch,
    selectedTicket?.reference,
  ]);

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTicket]);

  const setIsOpen = useCallback((open: boolean) => {
    if (open) {
      setSelectedTicket(null);
    } else {
      setSelectedTicket(null);
    }
  }, []);

  return (
    <BoxDecoration
      padding="2rem"
      bgColor={theme.color.white}
      borderRadius={theme.borderRadius.radius10}
      width="100%"
      boxShadow="0 0.2rem 0 rgba(0, 0, 0, 0.1)"
      border={`0.1rem solid ${theme.color.grey1}`}
    >
      <Column gap="2rem">
        <AlignedText>
          <H5>{t('my-tickets')}</H5>
        </AlignedText>
        <AlignedText>
          <P>{t('assign-ticket-text')}</P>
        </AlignedText>
        {isBookingDetailsLoading ? (
          <AssignTicketContainerSkeleton numberOfSkeletons={3} />
        ) : (
          <>
            {bookingDetails?.order?.map(order => (
              <AssignTicket
                ticket={order}
                key={order?.id}
                bookingStatus={bookingDetails?.booking_status}
                setSelectedTicket={setSelectedTicket}
                testId={`assign-ticket-${order?.id}`}
              />
            ))}
          </>
        )}
      </Column>

      <Modal
        isOpen={selectedTicket !== null}
        setIsOpen={setIsOpen}
        title={t('assign-ticket-to-attendee')}
        primaryButtonLabel={t('register')}
        disablePrimaryButton={selectedTicket?.assigned !== null}
        onPrimaryClick={onPrimaryAssignClick}
        content={
          <Column>
            <RowAlignedItems gap="2rem">
              <Icon name="ticket" size="3rem" color={theme.color.greyDark} />
              <P>{title}</P>
            </RowAlignedItems>

            <AlignedRightText>
              <AssignTicketText
                onClick={() => {
                  reset({
                    first_name: bookingDetails?.invoice?.firstName,
                    last_name: bookingDetails?.invoice?.lastName,
                    contact_email: bookingDetails?.invoice?.email,
                    company_name: bookingDetails?.invoice?.companyName,
                  });
                }}
              >
                {t('assign-ticket-to-me')}
              </AssignTicketText>
            </AlignedRightText>

            <FormWrapper>
              <FormRow columnsNb={2}>
                <FormItem
                  label={t('first_name')}
                  error={errors?.first_name?.message}
                  required
                >
                  <Controller
                    control={control}
                    name="first_name"
                    rules={{
                      ...requiredValidationRule,
                      ...getMinMaxLengthRules(3, 255),
                    }}
                    render={({ field }) => (
                      <Input
                        iconAlign="left"
                        {...field}
                        value={
                          field?.value && field?.value?.length > 0
                            ? field?.value
                            : selectedTicket?.assigned?.first_name
                        }
                        backgroundColor={theme.color.lightLush}
                        borderRadius={theme.borderRadius.radius50}
                        border={`0.1rem solid ${theme.color.grey1}`}
                        padding="0 1rem"
                        height="4rem"
                      />
                    )}
                  />
                </FormItem>

                <FormItem
                  label={t('last_name')}
                  error={errors?.last_name?.message}
                  required
                >
                  <Controller
                    control={control}
                    name="last_name"
                    rules={{
                      ...requiredValidationRule,
                      ...getMinMaxLengthRules(3, 255),
                    }}
                    render={({ field }) => (
                      <Input
                        iconAlign="left"
                        {...field}
                        value={
                          field?.value && field?.value?.length > 0
                            ? field?.value
                            : selectedTicket?.assigned?.last_name
                        }
                        backgroundColor={theme.color.lightLush}
                        borderRadius={theme.borderRadius.radius50}
                        border={`0.1rem solid ${theme.color.grey1}`}
                        padding="0 1rem"
                        height="4rem"
                      />
                    )}
                  />
                </FormItem>
              </FormRow>

              <FormRow columnsNb={2}>
                <FormItem
                  label={t('email')}
                  error={errors?.contact_email?.message}
                  required
                >
                  <Controller
                    control={control}
                    name="contact_email"
                    rules={{
                      ...requiredValidationRule,
                      ...emailValidationRule,
                    }}
                    render={({ field }) => (
                      <Input
                        iconAlign="left"
                        {...field}
                        value={
                          field?.value && field?.value?.length > 0
                            ? field?.value
                            : selectedTicket?.assigned?.email
                        }
                        backgroundColor={theme.color.lightLush}
                        borderRadius={theme.borderRadius.radius50}
                        border={`0.1rem solid ${theme.color.grey1}`}
                        padding="0 1rem"
                        height="4rem"
                      />
                    )}
                  />
                </FormItem>
                <FormItem
                  label={t('company_name')}
                  error={errors?.company_name?.message}
                  required
                >
                  <Controller
                    control={control}
                    name="company_name"
                    rules={{
                      ...requiredValidationRule,
                      ...getMinMaxLengthRules(3, 255),
                    }}
                    render={({ field }) => (
                      <Input
                        iconAlign="left"
                        {...field}
                        value={
                          field?.value && field?.value?.length > 0
                            ? field?.value
                            : selectedTicket?.assigned?.company_name
                        }
                        backgroundColor={theme.color.lightLush}
                        borderRadius={theme.borderRadius.radius50}
                        border={`0.1rem solid ${theme.color.grey1}`}
                        padding="0 1rem"
                        height="4rem"
                      />
                    )}
                  />
                </FormItem>
              </FormRow>
            </FormWrapper>
          </Column>
        }
      />
    </BoxDecoration>
  );
};

export default AssignTicketsToAttendees;
