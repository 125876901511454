import Icon from '@components/Icon';
import { RowAlignedItems } from '@components/LinearLayout';
import React, { useMemo } from 'react';
import styled, { useTheme } from 'styled-components';

const Container = styled.label`
  margin: 0.5rem 0.5rem;
  cursor: pointer;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<{
  checked: boolean;
  type: 'square' | 'round';
  size: string;
  backgroundColor?: string;
  border?: string;
  borderColorCheckedSquare?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.size};
  height: ${props => props.size};
  background: ${props => {
    if (props.checked && props.type === 'round') {
      if (props.backgroundColor) return props.backgroundColor;
      return props.theme.color.primary;
    }
  }};
  ${props => {
    if (props.border) return `border:${props.border};`;
    else if (!props.checked && props.type === 'round') {
      return `border:0.2rem solid ${props.theme.color.greyMediumDark};`;
    }
    if (props.type === 'square') {
      return `border:0.2rem solid ${
        props.checked && props.borderColorCheckedSquare
          ? props.borderColorCheckedSquare
          : props.theme.color.greyDark
      };`;
    }
  }};
  color: ${props => {
    if (props.checked && props.type === 'round') {
      return props.theme.color.primary;
    }
    return props.theme.color.greyDark;
  }};

  border-radius: ${props => props.type === 'round' && '3rem'};
  transition: all 150ms;
  background-color: ${props => props.backgroundColor};
  border: ${props => props.border};
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 1px
      ${props =>
        props.checked && props.type === 'round'
          ? props.theme.color.primary
          : props.theme.color.transparent};
  }
`;

export interface CheckboxProps {
  label?: JSX.Element;
  className?: string;
  checked: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  type: 'square' | 'round';
  size?: string;
  spacing?: string;
  backgroundColor?: string;
  border?: string;
  color?: string;
  alignItems?: string;
}

const Checkbox = ({
  label,
  className,
  checked,
  onChange,
  disabled,
  type,
  size = '2rem',
  spacing = '1rem',
  backgroundColor,
  border,
  color,
  alignItems,
}: CheckboxProps) => {
  const theme = useTheme();

  const colorIcon = useMemo(() => {
    if (color) return color;
    else {
      if (type === 'round') {
        return theme.color.white;
      } else return theme.color.greyDark;
    }
  }, [color, theme.color.greyDark, theme.color.white, type]);

  return (
    <RowAlignedItems gap={spacing} alignItems={alignItems}>
      <Container>
        <CheckboxContainer className={className}>
          <HiddenCheckbox
            disabled={disabled}
            checked={checked}
            onChange={onChange}
          />
          <StyledCheckbox
            checked={checked}
            type={type}
            size={size}
            backgroundColor={backgroundColor}
            border={border}
            borderColorCheckedSquare={theme.color.primary}
          >
            {checked && <Icon name="check" color={colorIcon} />}
          </StyledCheckbox>
        </CheckboxContainer>
      </Container>
      {label}
    </RowAlignedItems>
  );
};

export default Checkbox;
