import { Row } from '@components/LinearLayout';
import { LinkExternalSmall, PBold } from '@components/Typography';
import { HEADER_HEIGHT } from '@constants/generic';
import styled from 'styled-components';

export const RootFooterLayout = styled.div`
  background-color: ${props => props.theme.color.darkest};
  padding: 1rem 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  gap: 4rem;
  height: fit-content;
  min-height: ${HEADER_HEIGHT};
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    padding: 2rem;
    flex-direction: column;
    align-items: start;
  }
`;

export const LeftSideContent = styled(PBold)`
  color: ${props => props.theme.color.white};
`;

export const RightSideContent = styled(Row)`
  color: ${props => props.theme.color.white};
  gap: 2rem;
  flex-flow: row wrap;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    flex-direction: column;
  }
`;

export const LinkText = styled(LinkExternalSmall)`
  color: ${props => props.theme.color.white};
  font-weight: ${props => props.theme.fontWeight.weight400};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.color.primary};
  }
`;
