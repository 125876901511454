import { BoxDecoration } from '@components/BoxDecoration';
import { RowAlignedItems } from '@components/LinearLayout';
import { SmallBold } from '@components/Typography';
import { type ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';

const HeaderBox = styled(RowAlignedItems)`
  justify-content: space-between;
  padding: 2rem;
  border-bottom: 0.1rem solid ${props => props.theme.color.greyMedium};
`;

const BodyContainer = styled.div`
  padding: 2rem;
`;

interface EditBoxProps {
  title?: string | null;
  rightSideContent?: JSX.Element;
  children?: ReactNode;
}
const EditBox = ({ title, rightSideContent, children }: EditBoxProps) => {
  const theme = useTheme();
  return (
    <BoxDecoration
      padding="0"
      bgColor={theme.color.lightest}
      borderRadius={theme.borderRadius.radius10}
      width="100%"
      boxShadow="0 0.2rem 0 rgba(0, 0, 0, 0.1)"
      border={`0.1rem solid ${theme.color.grey1}`}
    >
      <HeaderBox>
        <SmallBold> {title}</SmallBold>
        {rightSideContent && <>{rightSideContent}</>}
      </HeaderBox>

      <BodyContainer>{children}</BodyContainer>
    </BoxDecoration>
  );
};

export default EditBox;
