import { useState } from 'react';

import { Badge, Button, Wrapper } from './styles';

interface Item {
  label: string;
  onItemClick?: () => void;
  badgeCount?: number;
}

export interface TabSwitcherProps {
  items?: Item[];
  selected?: number;
}

const TabSwitcher = ({ items, selected: selectedProp }: TabSwitcherProps) => {
  const [selected, setSelected] = useState(selectedProp ?? 0);

  return (
    <Wrapper>
      {items?.map(({ label, onItemClick, badgeCount }, i) => (
        <Button
          key={`${label} + ${i}`}
          name={label}
          onClick={() => {
            setSelected(i);
            onItemClick?.();
          }}
          isSelected={(selectedProp ?? selected) === i}
        >
          {label}
          {badgeCount && badgeCount > 0 && <Badge>{badgeCount}</Badge>}
        </Button>
      ))}
    </Wrapper>
  );
};

export default TabSwitcher;
