import { THEME, THEME_TYPE } from '@constants/theme';
import {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { useSearchParams } from 'react-router-dom';

export const useSetState = <T extends Record<string, unknown>>(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  initialState: T = {} as T
): [T, (patch: Partial<T> | ((prevState: T) => Partial<T>)) => void] => {
  const [state, set] = useState<T>(initialState);

  const setState = useCallback(
    (patch: unknown) => {
      set(prevState =>
        Object.assign(
          {},
          prevState,
          patch instanceof Function ? patch(prevState) : patch
        )
      );
    },
    [set]
  );
  return [state, setState];
};

export const useDarkMode: () => {
  theme: string;
  themeToggler: () => void;
} = () => {
  const [theme, setTheme] = useState<(typeof THEME_TYPE)[number]>(THEME.LIGHT);
  // const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');

  // useEffect(() => {
  //   darkModeQuery.addEventListener('change', event => {
  //     setTheme(event.matches ? THEME.DARK : THEME.LIGHT);
  //     window.localStorage.setItem(
  //       'theme',
  //       event.matches ? THEME.DARK : THEME.LIGHT
  //     );
  //   });
  // });
  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme');
    if (localTheme === THEME.LIGHT || localTheme === THEME.DARK)
      setTheme(localTheme);
  }, []);

  const setMode: (mode: (typeof THEME_TYPE)[number]) => void = (
    mode: (typeof THEME_TYPE)[number]
  ) => {
    window.localStorage.setItem('theme', mode);
    setTheme(mode);
  };

  const themeToggler = () => {
    theme === THEME.LIGHT ? setMode(THEME.DARK) : setMode(THEME.LIGHT);
  };

  return { theme, themeToggler };
};

export const useOnClickOutside: (
  ref: MutableRefObject<HTMLDivElement>,
  handler: () => void
) => void = (ref: MutableRefObject<HTMLDivElement>, handler: () => void) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const listener: (event: any) => void = (event: any) => {
      if (!ref.current || ref.current.contains(event?.target)) {
        return;
      }
      handler();
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export function usePrevious<T>(value: T): T {
  const ref: any = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function useHover<T>(): [MutableRefObject<T>, boolean] {
  const [value, setValue] = useState<boolean>(false);

  const ref: any = useRef<T | null>(null);
  const handleMouseOver = (): void => {
    setValue(true);
  };
  const handleMouseOut = (): void => {
    setValue(false);
  };
  useEffect(
    () => {
      const node: any = ref.current;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);
        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current] // Recall only if ref changes
  );
  return [ref, value];
}

export const useMedia: <T>(
  queries: string[],
  values: T[],
  defaultValue: T
) => T = <T>(queries: string[], values: T[], defaultValue: T) => {
  const mediaQueryLists = queries.map(q => window.matchMedia(q));
  const getValue: () => T = () => {
    const index = mediaQueryLists.findIndex(mql => mql.matches);
    return values?.[index] ?? defaultValue;
  };
  const [value, setValue] = useState<T>(getValue);
  useEffect(
    () => {
      const handler: () => void = () => {
        setValue(getValue);
      };
      mediaQueryLists.forEach(mql => {
        mql.addListener(handler);
      });
      return () => {
        mediaQueryLists.forEach(mql => {
          mql.removeListener(handler);
        });
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [] // Empty array ensures effect is only run on mount and unmount
  );
  return value;
};

export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export function useEventListener<K extends keyof MediaQueryListEventMap>(
  eventName: K,
  handler: (event: MediaQueryListEventMap[K]) => void,
  element: RefObject<MediaQueryList>,
  options?: boolean | AddEventListenerOptions
): void;

// Window Event based useEventListener interface
export function useEventListener<K extends keyof WindowEventMap>(
  eventName: K,
  handler: (event: WindowEventMap[K]) => void,
  element?: undefined,
  options?: boolean | AddEventListenerOptions
): void;

// Element Event based useEventListener interface
export function useEventListener<
  K extends keyof HTMLElementEventMap,
  T extends HTMLElement = HTMLDivElement,
>(
  eventName: K,
  handler: (event: HTMLElementEventMap[K]) => void,
  element: RefObject<T>,
  options?: boolean | AddEventListenerOptions
): void;

// Document Event based useEventListener interface
export function useEventListener<K extends keyof DocumentEventMap>(
  eventName: K,
  handler: (event: DocumentEventMap[K]) => void,
  element: RefObject<Document>,
  options?: boolean | AddEventListenerOptions
): void;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useEventListener<
  KW extends keyof WindowEventMap,
  KH extends keyof HTMLElementEventMap,
  KM extends keyof MediaQueryListEventMap,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  T extends HTMLElement | MediaQueryList | void = void,
>(
  eventName: KW | KH | KM,
  handler: (
    event:
      | WindowEventMap[KW]
      | HTMLElementEventMap[KH]
      | MediaQueryListEventMap[KM]
      | Event
  ) => void,
  element?: RefObject<T>,
  options?: boolean | AddEventListenerOptions
) {
  const savedHandler = useRef(handler);

  useIsomorphicLayoutEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const targetElement: T | Window = element?.current ?? window;
    if (targetElement?.addEventListener == null) return;
    const listener: typeof handler = event => {
      savedHandler.current(event);
    };

    targetElement.addEventListener(eventName, listener, options);
    return () => {
      targetElement.removeEventListener(eventName, listener, options);
    };
  }, [eventName, element, options]);
}

interface Size {
  width: number;
  height: number;
}

export function useElementSize<T extends HTMLElement = HTMLDivElement>(): [
  (node: T | null) => void,
  Size,
] {
  // Mutable values like 'ref.current' aren't valid dependencies
  // because mutating them doesn't re-render the component.
  // Instead, we use a state as a ref to be reactive.
  const [ref, setRef] = useState<T | null>(null);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  // Prevent too many rendering using useCallback
  const handleSize = useCallback(() => {
    setSize({
      width: ref?.offsetWidth ?? 0,
      height: ref?.offsetHeight ?? 0,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  useEventListener('resize', handleSize);

  useIsomorphicLayoutEffect(() => {
    handleSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  return [setRef, size];
}

export function useMediaQuery(query: string): boolean {
  const getMatches = (query: string): boolean => {
    if (typeof window !== 'undefined') {
      return window.matchMedia(query).matches;
    }
    return false;
  };
  const [matches, setMatches] = useState<boolean>(getMatches(query));
  function handleChange() {
    setMatches(getMatches(query));
  }
  useEffect(() => {
    const matchMedia = window.matchMedia(query);
    handleChange();
    matchMedia.addEventListener('change', handleChange);
    return () => {
      matchMedia.removeEventListener('change', handleChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return matches;
}

export const useHookParams = (input: string[]) => {
  const [searchParams] = useSearchParams();
  return input?.map(item => searchParams.get(item) ?? '');
};

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideAlerter = (
  refs: Array<MutableRefObject<any>>,
  onClickOutside?: () => void
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (refs?.every(ref => !ref.current?.contains(event.target))) {
        // alert("You clicked outside of me!");
        onClickOutside?.();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside, refs]);
};
