import styled from 'styled-components';
import { H6 } from '@components/Typography';

export const SubtitleWrapper = styled(H6)`
  text-align: center;
  padding-top: 1.25rem;
  display: flex;
  justify-content: center;
`;

export const OrderDetailsPageTitleWrapper = styled.div`
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
`;
