import { Column } from '@components/LinearLayout';
import { H6, P, PSmall } from '@components/Typography';
import { CURRENCY } from '@constants/generic';
import { formatPrice } from '@utils/fn';
import {
  type Option,
  type Ticket,
  type TicketItem,
} from '@hubs101/booking-api-skd-client/lib/types';
import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DiscountedTotalPrice,
  DiscountP,
  DiscountPrice,
  Divider,
  FreeTicket,
  PriceItem,
  PriceSectionRoot,
  StrikethroughText,
} from './styles';

interface PriceSectionProps {
  selectedTickets?: TicketItem[];
  publicEventTickets?: Ticket[];
}
const PriceSection = ({
  selectedTickets,
  publicEventTickets,
}: PriceSectionProps) => {
  const { t } = useTranslation();
  const ticketPrices = useMemo(() => {
    return selectedTickets?.map((item, index) => {
      const ticket = item.id
        ? publicEventTickets?.find(e => e.id === item.code)!
        : publicEventTickets?.find(e => e.code === item.code)!;
      const selectedExtraOptions: Option[] = Object.values(
        ticket?.extra_options ?? {}
      ).filter(e => selectedTickets[index].extraOptions?.includes(e.id));
      const selectedExtraOptionsPrice = sum(
        selectedExtraOptions.map(option => option?.price)
      );

      return {
        ticketName: ticket?.name,
        ticketPrice: ticket?.price,
        extraOptions: selectedExtraOptions,
        extraOptionsPrice: selectedExtraOptionsPrice,
        discount: item.discountValue,
        discountPrice:
          ((Number(item.discountValue) > 0 ? Number(item.discountValue) : 0) /
            100) *
          Number(ticket?.price),
      };
    });
  }, [publicEventTickets, selectedTickets]);

  return (
    <PriceSectionRoot>
      <Column gap="1.5rem">
        {selectedTickets?.map((item, index) => {
          const ticket = item.id
            ? publicEventTickets?.find(e => e.id === item.code)
            : publicEventTickets?.find(e => e.code === item.code);

          return (
            <Column key={`${item.code ?? item.id!}-${index}`}>
              <PriceItem>
                <P>1x {ticket?.name}</P>
                {Number(item?.discountValue) > 0 ? (
                  <StrikethroughText>
                    {formatPrice(Number(ticket?.price), CURRENCY.EUR)}
                  </StrikethroughText>
                ) : Number(ticket?.price) > 0 ? (
                  <P>{formatPrice(Number(ticket?.price), CURRENCY.EUR)}</P>
                ) : (
                  <FreeTicket>{t('free')}</FreeTicket>
                )}
              </PriceItem>
              {Boolean(ticketPrices?.[index]?.extraOptions?.length) && (
                <>
                  {ticketPrices?.[index]?.extraOptions?.map(element => (
                    <PriceItem key={element.id}>
                      <PSmall>1x {element?.name}</PSmall>

                      <PSmall>
                        {formatPrice(Number(element?.price), CURRENCY.EUR)}
                      </PSmall>
                    </PriceItem>
                  ))}
                </>
              )}
              {Number(item?.discountValue) > 0 && (
                <PriceItem>
                  <DiscountP> -%{item.discountValue}</DiscountP>
                  {Number(ticket?.price) *
                    ((100 - Number(item.discountValue)) / 100) >
                  0 ? (
                    <DiscountPrice>
                      {formatPrice(
                        Number(ticket?.price) *
                          ((100 - Number(item.discountValue)) / 100),
                        CURRENCY.EUR
                      )}
                    </DiscountPrice>
                  ) : (
                    <FreeTicket>{t('free')}</FreeTicket>
                  )}
                </PriceItem>
              )}
            </Column>
          );
        })}

        <Divider />
      </Column>

      <Column>
        <PriceItem>
          <P>{t('subtotal')}</P>
          {sumBy(ticketPrices, 'discountPrice') > 0 ? (
            <StrikethroughText>
              {formatPrice(
                sumBy(ticketPrices, 'ticketPrice') +
                  sumBy(ticketPrices, 'extraOptionsPrice'),
                CURRENCY.EUR
              )}
            </StrikethroughText>
          ) : sumBy(ticketPrices, 'ticketPrice') +
              sumBy(ticketPrices, 'extraOptionsPrice') >
            0 ? (
            <P>
              {formatPrice(
                sumBy(ticketPrices, 'ticketPrice') +
                  sumBy(ticketPrices, 'extraOptionsPrice'),
                CURRENCY.EUR
              )}
            </P>
          ) : (
            <FreeTicket>{t('free')}</FreeTicket>
          )}
        </PriceItem>
        {sumBy(ticketPrices, 'discountPrice') > 0 && (
          <PriceItem>
            <DiscountP>{t('discount')}</DiscountP>
            <DiscountP>
              -{formatPrice(sumBy(ticketPrices, 'discountPrice'), CURRENCY.EUR)}
            </DiscountP>
          </PriceItem>
        )}
      </Column>
      <PriceItem>
        <H6>{t('total')}</H6>
        {sumBy(ticketPrices, 'discountPrice') > 0 ? (
          <DiscountedTotalPrice>
            {formatPrice(
              sumBy(ticketPrices, 'ticketPrice') +
                sumBy(ticketPrices, 'extraOptionsPrice') -
                sumBy(ticketPrices, 'discountPrice'),
              CURRENCY.EUR
            )}
          </DiscountedTotalPrice>
        ) : sumBy(ticketPrices, 'ticketPrice') +
            sumBy(ticketPrices, 'extraOptionsPrice') -
            sumBy(ticketPrices, 'discountPrice') >
          0 ? (
          <H6>
            {formatPrice(
              sumBy(ticketPrices, 'ticketPrice') +
                sumBy(ticketPrices, 'extraOptionsPrice') -
                sumBy(ticketPrices, 'discountPrice'),
              CURRENCY.EUR
            )}
          </H6>
        ) : (
          <FreeTicket>{t('free')}</FreeTicket>
        )}
      </PriceItem>
    </PriceSectionRoot>
  );
};

export default PriceSection;
