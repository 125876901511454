import { LineSkeleton } from '@components/SkeletonsLoaders';

interface BookingDetailsSkeletonProps {
  numberOfSkeletons: number;
}

const BookingDetailsSkeleton = ({
  numberOfSkeletons,
}: BookingDetailsSkeletonProps) => {
  return (
    <>
      {Array(numberOfSkeletons)
        .fill(0)
        .map((_, index) => (
          <>
            <LineSkeleton width="100%" height="2rem" margin="0 0 1rem" />
            <LineSkeleton
              width="100%"
              height="1rem"
              margin={
                index === numberOfSkeletons - 1 ? '0 0 2.5rem' : '0 0 1rem'
              }
            />
          </>
        ))}
      <LineSkeleton width="100%" height="2rem" />
    </>
  );
};

export default BookingDetailsSkeleton;
