import { PBold, PSmall } from '@components/Typography';
import {
  type BookingCheckDetails,
  type DiscountType,
  type QueryResponseType,
} from '@hubs101/booking-api-skd-client/lib/types';
import {
  PricesWrapper,
  StandardElement,
  TicketListWrapper,
} from '@pages/Purchase/components/AttendeeDetailsStep/styles';

import { LineSkeleton } from '@components/SkeletonsLoaders';
import { type CURRENCY } from '@constants/generic';
import { type TicketOptionsInput } from '@utils/apiHooks';
import { formatPrice } from '@utils/fn';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import BookingDetailsItem from './item';

interface BookingDetailsSectionProps {
  bookingsCheck: BookingCheckDetails;
  discount?: DiscountType;
  isBookingsCheckLoading: boolean;
  selectedTicketsWithOptions: TicketOptionsInput[];
  bookingsCheckDetails: QueryResponseType<BookingCheckDetails>;
}

const BookingDetailsSection = ({
  bookingsCheck,
  discount,
  isBookingsCheckLoading,
  selectedTicketsWithOptions,
  bookingsCheckDetails,
}: BookingDetailsSectionProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const selectedCurrency =
    bookingsCheck?.currency ?? searchParams.get('cu') ?? 'EUR';
  const symbol = selectedCurrency as keyof typeof CURRENCY;
  const [prevBookingState, setPrevBookingState] =
    useState<BookingCheckDetails | null>();

  useEffect(() => {
    if (bookingsCheckDetails.isSuccess) {
      setPrevBookingState(bookingsCheckDetails.data);
    }
  }, [bookingsCheckDetails.data, bookingsCheckDetails.isSuccess]);

  return (
    <>
      {isBookingsCheckLoading ? (
        <TicketListWrapper gap="1.4rem">
          {prevBookingState?.order?.map((e, index: number) => (
            <LineSkeleton width="100" height="1.87rem" key={index} />
          ))}
        </TicketListWrapper>
      ) : (
        <TicketListWrapper gap="1.4rem">
          {bookingsCheck?.order?.map((ticket, index: number) => (
            <BookingDetailsItem
              key={`${ticket?.id}_${index}`}
              ticket={ticket}
              currency={symbol}
              discount={discount}
              selectedTicketsWithOptions={selectedTicketsWithOptions}
            />
          ))}
        </TicketListWrapper>
      )}
      <PricesWrapper>
        <StandardElement>
          <PSmall>{t('subtotal')}</PSmall>
          {isBookingsCheckLoading ? (
            <LineSkeleton width="6rem" height="1.87rem" />
          ) : (
            <PSmall>
              {formatPrice(Number(bookingsCheck?.sub_total), symbol)}
            </PSmall>
          )}
        </StandardElement>

        {isBookingsCheckLoading && Number(prevBookingState?.vat_value) > 0 ? (
          <StandardElement>
            <PSmall>
              {t('vat')} {Number(bookingsCheck?.vat)}%
            </PSmall>
            <LineSkeleton width="5rem" height="1.87rem" />
          </StandardElement>
        ) : (
          <>
            {Number(bookingsCheck?.vat_value) > 0 && (
              <StandardElement>
                <PSmall>
                  {t('vat')} {Number(bookingsCheck?.vat)}%
                </PSmall>

                <PSmall>
                  {formatPrice(Number(bookingsCheck?.vat_value), symbol)}
                </PSmall>
              </StandardElement>
            )}
          </>
        )}
      </PricesWrapper>
      <StandardElement>
        <PBold>{t('total')}</PBold>
        {isBookingsCheckLoading ? (
          <LineSkeleton width="7rem" height="1.87rem" />
        ) : (
          <PBold>{formatPrice(Number(bookingsCheck?.total), symbol)}</PBold>
        )}
      </StandardElement>
    </>
  );
};

export default BookingDetailsSection;
