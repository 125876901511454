import type React from 'react';
import { useEffect } from 'react';

export const useOutsideClickAlerter = (
  ref: React.RefObject<HTMLElement>,
  handleClickOutside: () => void
) => {
  useEffect(() => {
    const handleClickOutsideEvent = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        handleClickOutside();
      }
    };
    document.addEventListener('mousedown', handleClickOutsideEvent);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideEvent);
    };
  }, [ref, handleClickOutside]);
};
